<script>
  import { useForm } from '@inertiajs/svelte'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  let is_submitting = false

  const form = useForm({
    email: '',
    password: ''
  })

  function sign_in(evt) {
    evt.preventDefault()

    is_submitting = true

    $form.post(this.action, {
      onSuccess: () => dispatch('close'),
      onFinish: () => is_submitting = false
    })
  }
</script>

<p>Sign in to access all of your projects.</p>

<form class="space-y-3" action="/sessions" method="post" on:submit={sign_in}>
  <div>
    <label class="label pt-0 pb-0.5" for="email">
      <span class="label-text-alt">Email</span>
    </label>

    <input
      type="email"
      required
      name="email"
      bind:value={$form.email}
      placeholder="you@example.com"
      class="input input-bordered w-full"
      class:input-error={$form.errors?.email}
    />

    {#if $form.errors?.email}
      <ul class="text-sm text-error mt-2">
        {#each $form.errors.email as error}
          <li>{error}</li>
        {/each}
      </ul>
    {/if}
  </div>

  <div>
    <label class="label pt-0 pb-0.5" for="password">
      <span class="label-text">Password</span>
    </label>

    <input
      type="password"
      required
      name="password"
      bind:value={$form.password}
      class="input input-bordered w-full"
      class:input-error={$form.errors?.password}
    />

    {#if $form.errors?.password}
      <ul class="text-sm text-error mt-2">
        {#each $form.errors.password as error}
          <li>{error}</li>
        {/each}
      </ul>
    {/if}
  </div>

  <button type="submit" class="btn w-full btn-accent btn-outline font-normal">
    {#if is_submitting}<span class="loading loading-dots"></span>{/if}

    Sign in
  </button>
</form>

 <p class="text-center py-3">Need an account? <label class="link link-primary" for="is_signing_up">Sign up</label>.</p>