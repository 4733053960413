import { Entity } from '../Entity'
import { pick, entity_name_for } from '@pogzul/engine'

export class Text extends Entity {
  /** @type {string} */
  name

  /** @type {string} */
  value = ''

  /** @type {string} */
  font = 'RetroGaming'

  /** @type {number} - In pixels */
  size = 18

  /** @type {Uint8Array} */
  color

  /** @type {'left' | 'center' | 'right'} */
  justify = 'left'

  /** @type {'top' | 'middle' | 'bottom'} */
  align = 'top'

  /** @param {Partial<Text>} opts */
  constructor(opts = {}) {
    super(opts)

    Object.assign(
      this,
      pick(opts, 'name', 'value', 'font', 'size', 'color', 'justify', 'align')
    )

    if (opts.color) {
      this.color = new Uint8Array(Object.values(opts.color))
    }

    this.height ||= this.size
    this.name ??= entity_name_for('Text')
  }
}

export default Text
