<script>
  import { page, useForm } from '@inertiajs/svelte'
  import { copy_to_clipboard } from '$lib/utilities'

  $: project_url = `${$page.props.config.app_url}/projects/${$page.props.project.id}`

  const form = useForm({
    visibility: $page.props.project.visibility,
    public_access: $page.props.project.public_access
  })

  function handle_submit(evt) {
    evt.preventDefault()

    $form.patch(this.action)
  }
</script>

<form
  action="/projects/{$page.props.project.id}"
  method="post"
  class="space-y-3"
  on:change={handle_submit}
>
  <div class="flex justify-between">
    <div class="flex space-x-3">
      <input
        id="project_visibility_hidden"
        type="radio"
        name="project[visibility]"
        value="hidden"
        class="radio"
        bind:group={$form.visibility}
        checked={$form.visibility === 'hidden'}
      />

      <label for="project_visibility_hidden">
        <p class="font-semibold">Public</p>
        <p class="text-base-content/70 text-sm">Anyone with the link can {$form.public_access === 'write' ? 'edit' : 'view'}.</p>
      </label>
    </div>

    <div class="opacity-100 transition-opacity" class:!opacity-0={$form.visibility === 'private'}>
      <div class="dropdown dropdown-hover dropdown-end">
        <div tabindex="0" role="button" class="btn btn-sm btn-outline my-2">
          Can {$form.public_access === 'write' ? 'edit' : 'view'}
        </div>

        <ul class="dropdown-content z-[1] menu p-0 shadow bg-base-200 rounded-box whitespace-nowrap">
          <li class="relative">

            <input
              id="project_visibility_hidden"
              type="radio"
              name="project[public_access]"
              value="write"
              class="absolute inset-0 appearance-none"
              bind:group={$form.public_access}
            />
            <button>Can edit</button>
          </li>

          <li>
            <input
              id="project_visibility_hidden"
              type="radio"
              name="project[public_access]"
              value="read"
              class="absolute inset-0 appearance-none"
              bind:group={$form.public_access}
            />
            <button>Can view</button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="flex space-x-3">
    <input
      id="project_visibility_private"
      type="radio"
      name="project[visibility]"
      value="private"
      class="radio"
      bind:group={$form.visibility}
      checked={$form.visibility === 'private'}
    />

    <label for="project_visibility_private">
      <p class="font-semibold">Private</p>
      <p class="text-base-content/70 text-sm">Only you can edit this project.</p>
    </label>
  </div>
</form>

<button
  class="join w-full"
  on:click={() => copy_to_clipboard(project_url)}
>
  <input
    type="text"
    class="input input-bordered min-w-0 w-full join-item hover:z-10 focus:z-10"
    readonly
    value={project_url}
  />

  <div class="btn btn-success btn-outline join-item hover:z-10">
    Copy link
  </div>
</button>