import web_colors from './web_colors.json'

/**
 * @param {Uint8Array | undefined} a
 * @param {Uint8Array | undefined} b
 */
export const colors_are_equal = (a, b) => {
  if (a instanceof Uint8Array && b instanceof Uint8Array) {
    return (
      a.length === b.length && a.every((value, index) => value === b[index])
    )
  }

  return a === b
}

/** @param {string} hex */
export const hex_to_rgb_i = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  return new Uint8Array([r, g, b])
}

/**
 * @param {string} hex
 * @param {number} alpha
 */
export const hex_to_rgba_i = (hex, alpha = 1) =>
  new Uint8Array([...hex_to_rgb_i(hex), alpha * 255])

export default hex_to_rgb_i

/**
 * @param {string | Uint8Array | undefined} color – hex, rgb(a) or name string
 * @returns {Uint8Array} – an [r, g, b] array between 0-255.
 */
export const parse_color = (color) => {
  if (!color) return new Uint8Array([0, 0, 0])
  if (color instanceof Uint8Array) return color

  if (color in web_colors) return hex_to_rgb_i(web_colors[color])

  let hex = color.match(/^#?([0-9A-F]{3}|[0-9A-F]{6})$/i)?.[0]?.toString()

  return hex ? hex_to_rgb_i(hex) : parse_rgb_string(color)
}

/** @param {string} string */
export const parse_rgb_string = (string) => {
  const [r, g, b] = string.match(/\d+(\.\d+)?/g)?.map(Number) || []

  if (!isNaN(parseFloat(b))) return new Uint8Array([r, g, b])

  return new Uint8Array([0, 0, 0])
}

/** @param {Uint8Array | number[] | undefined} array */
export const rgb_array_to_hex = (array) => {
  if (!array) return ''

  const [r, g, b, alpha = 1] = array

  let hex = '#'

  for (let v of [r, g, b]) {
    let color = alpha_blend(v, alpha)

    hex += color.toString(16).padStart(2, '0')
  }

  return hex.toUpperCase()
}

/**
 * @param {number} color
 * @param {number} alpha
 */
const alpha_blend = (color, alpha) => {
  let val = (1 - alpha) * 1 + (alpha * color) / 255

  return Math.round(val * 255)
}

/** @param {Uint8Array | number[]} array */
export const rgb_array_to_string = (array) => {
  const [r, g, b, a = 255] = array

  return `rgba(${r}, ${g}, ${b}, ${a / 255})`
}
