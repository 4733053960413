import { Group, Entity } from '@pogzul/engine'

/**
 * @param {any[] | Group} array
 * @returns {any | Entity}
 * @this {import('@pogzul/engine').Runtime}
 */
export function sample(array) {
  if (array instanceof Group)
    return sample.call(
      this,
      array.layer_ids.map((id) => this.active_scene.entities[id])
    )

  return array[Math.floor(Math.random() * array.length)]
}
