/**
 * @param {number} scene_index
 * @this {import('@pogzul/engine').Runtime}
 */
export function go(scene_index) {
  const scene = this.scenes[scene_index]

  if (scene) {
    this.active_scene = scene
  } else {
    this.console.error(`Scene at index ${scene_index} doesn't exist.`)
  }
}
