import { pick, Entity, Shape, entity_name_for } from '@pogzul/engine'

export class Group extends Entity {
  /** @type {string} */
  name

  /** @type {number[]} */
  layer_ids = []

  /** @param {Partial<Group>} opts */
  constructor(opts = {}) {
    super(opts)

    Object.assign(this, pick(opts, 'name', 'layer_ids'))

    this.name ??= entity_name_for('Group')

    overide_dimension_accessors.call(this)
  }

  /** @returns {Shape[]} */
  get layers() {
    return this.layer_ids.reduce((layers, id) => {
      const entity = this.scene.entities[id]

      if (entity instanceof Group) return [...layers, ...entity.layers]

      return [...layers, entity]
    }, [])
  }
}

/** @this {Group} */
function overide_dimension_accessors() {
  Object.defineProperty(this, 'x', {
    get: () => {
      return Math.min(...this.layer_ids.map((id) => this.scene.entities[id].x))
    },

    set: (val) => {
      const dx = val - this.x

      for (let id of this.layer_ids) {
        this.scene.entities[id].x += dx
      }
    }
  })

  Object.defineProperty(this, 'y', {
    get: () => {
      return Math.min(...this.layer_ids.map((id) => this.scene.entities[id].y))
    },

    set: (val) => {
      const dy = val - this.y

      for (let id of this.layer_ids) {
        this.scene.entities[id].y += dy
      }
    }
  })

  Object.defineProperty(this, 'width', {
    get: () => {
      const x2 = Math.max(
        ...this.layer_ids.map(
          (id) => this.scene.entities[id].x + this.scene.entities[id].width
        )
      )

      return x2 - this.x
    }
  })

  Object.defineProperty(this, 'height', {
    get: () => {
      const y2 = Math.max(
        ...this.layer_ids.map(
          (id) => this.scene.entities[id].y + this.scene.entities[id].height
        )
      )

      return y2 - this.y
    }
  })
}
