import { renderLayer, OUTLINE_COLOR } from './renderLayer.js'

/**
 * Render a scene and all of its layers.
 *
 * @param {Object} opts
 * @param {import('../../webgl').Context} opts.ctx - Our WebGL rendering context
 * @param {import('@pogzul/engine').Scene} opts.scene
 * @param {ArtboardState} opts.state
 */
export const renderScene = ({ ctx, scene, state }) => {
  for (let id of scene.layer_ids) {
    const layer = scene.entities[id]

    const is_hovering = state.hovering_layer_ids.includes(layer.id),
      is_active = state.active_layer_ids.includes(layer.id)

    let alpha = 1

    if ('layer_ids' in layer) {
      if (layer.is_hidden) {
        if (!is_active) return

        alpha = 0.7
      }

      for (let id of layer.layer_ids) {
        renderLayer({
          layer: scene.entities[id],
          ctx,
          state,
          is_active,
          is_hovering,
          alpha
        })
      }

      continue
    }

    renderLayer({ layer, ctx, state, is_active, is_hovering, alpha })
  }

  if (state.active_layer_ids.length)
    renderGroupedLayerBoundary({ ctx, scene, state })
}

const BOUNDARY_WIDTH = 2,
  BOUNDARY_PADDING = 2
/**
 * @param {Object} opts
 * @param {import('../../webgl').Context} opts.ctx - Our WebGL rendering context
 * @param {import('@pogzul/engine').Scene} opts.scene
 * @param {State} opts.state
 */
const renderGroupedLayerBoundary = ({ ctx, scene, state }) => {
  const layers = state.active_layer_ids
    .map((id) => scene.entities[id])
    .filter(Boolean)

  if (!layers.length) return

  const temp_layer = compute_layers_boundary(layers)

  ctx.strokeRect({
    x: temp_layer.x - BOUNDARY_PADDING,
    y: temp_layer.y - BOUNDARY_PADDING,
    width: temp_layer.width + 2 * BOUNDARY_PADDING,
    height: temp_layer.height + 2 * BOUNDARY_PADDING,
    color: OUTLINE_COLOR,
    lineWidth: BOUNDARY_WIDTH
  })
}

/** @param {import('@pogzul/engine').Entity[]} layers */
export const compute_layers_boundary = ([first_layer, ...layers]) => {
  let { x, y, width, height } = first_layer

  const scale = 1

  let x2 = x + width * scale,
    y2 = y + height * scale

  for (let layer of layers) {
    if (layer.x < x) x = layer.x
    if (layer.y < y) y = layer.y

    let _x2 = layer.x + layer.width * scale,
      _y2 = layer.y + layer.height * scale

    if (_x2 > x2) x2 = _x2
    if (_y2 > y2) y2 = _y2
  }

  return { x, y, width: x2 - x, height: y2 - y }
}

/*
  Rendering functions for CanvasRenderingContext2D
*/

// export const renderTextCanvas2D = ({ ctx, layer }) => {
//   ctx.textBaseline = 'hanging'
//   ctx.font = `${layer.fontSize}px ${layer.fontFamily}`
//   ctx.fillStyle = rgbaArrayToString(layer.color)

//   ctx.fillText(layer.value, 0, 0)
// }
