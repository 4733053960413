import { normalize_mouse_coordinates, active } from '@pogzul/engine'
import { state } from '../stores'
import { get } from 'svelte/store'

export class ToolEvent {
  /**
   * @param {object} params
   * @param {MouseEvent | KeyboardEvent} params.evt
   * @param {boolean} params.is_targeting_editor
   * @param {boolean} params.is_targeting_canvas
   */
  constructor({ evt, is_targeting_editor, is_targeting_canvas }) {
    const {
      editor_offsets,
      active_color,
      active_layer_ids,
      hovering_layer_ids
    } = get(state)

    if (evt instanceof MouseEvent) {
      const { x, y } = normalize_mouse_coordinates({
        mouse_x: evt.pageX,
        mouse_y: evt.pageY,
        offsets: editor_offsets
      })

      this.x = x
      this.y = y
      this.mouse_x = evt.pageX - editor_offsets.x
      this.mouse_y = evt.pageY - editor_offsets.y

      this.is_mouse_down = evt.buttons === 1
    } else {
      this.key = evt.key
      this.code = evt.code
    }

    this.scene = active.scene

    this.target = evt.target
    this.shift_key = evt.shiftKey
    this.offsets = editor_offsets
    this.active_color = active_color
    this.active_layer_ids = active_layer_ids
    this.hovering_layer_ids = hovering_layer_ids
    this.is_targeting_editor = is_targeting_editor
    this.is_targeting_canvas = is_targeting_canvas
  }
}

export default ToolEvent
