<script>
  import { Icon } from '@pogzul/artboard'
  import DeleteProjectModal from './DeleteProjectModal.svelte'

  export let projects = []

  let delete_project_modal
</script>

<div class="flex justify-between">
  <div>
    <h3 class="font-bold text-xl leading-none">Projects</h3>

    <p class="flex items-center text-sm mt-2.5">
      <kbd class="kbd kbd-sm mr-2">Shift</kbd> + <kbd class="kbd kbd-sm mx-2">Click</kbd> to delete a project.
    </p>
  </div>

  <a href="/projects/new" class="btn btn-accent btn-outline flex justify-between font-normal">
    <p>Create</p>

    <Icon name="plus" class="h-4 w-4" />
  </a>
</div>

<ul class="space-y-3 max-h-80 overflow-auto">
  {#each projects as project}
    <li>
      <a
        class="w-full btn justify-between btn-outline font-sans" href="/projects/{project.id}"
        on:click={ (evt) => {
          if (evt.shiftKey) {
            evt.preventDefault()

            delete_project_modal.show(project)
          }
        }}
      >
        {project.name}
        <Icon name="chevron-right" class="h-4 w-4 text-base-content/50" />
      </a>
    </li>
  {/each}

  {#if !projects.length}
    <li class="text-center py-6">Get started by creating a new project.</li>
  {/if}
</ul>

<DeleteProjectModal bind:this={delete_project_modal} />