import { m3 } from './matrices'

export function MatrixStack() {
  /** @type {number[][]} */
  this.stack = []

  let current = m3.identity()

  Object.defineProperty(this, 'current', {
    get() {
      return current
    },
    set(matrix) {
      return (current = matrix)
    }
  })

  this.reset = () => {
    this.current = m3.identity()
  }

  this.save = () => {
    this.stack.push(this.current)
  }

  this.restore = () => {
    this.stack.pop()

    if (this.stack.length < 1) {
      this.stack[0] = m3.identity()
      // this.current = m3.identity()
    }
  }

  this.project = (width, height) => {
    this.current = m3.project(this.current, width, height)
  }

  this.translate = (x, y) => {
    this.current = m3.translate(this.current, x, y)
  }

  this.scale = (x, y) => {
    this.current = m3.scale(this.current, x, y)
  }

  this.rotate = (angleInRadians) => {
    this.current = m3.rotate(this.current, angleInRadians)
  }

  return this
}

export default MatrixStack
