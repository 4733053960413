<script lang="ts">
  import { Icon, create_svg, artboard_state } from '@pogzul/artboard'
  import { project as project_store } from '$lib/stores'
  import { page } from '@inertiajs/svelte'

  import ProjectSwitcher from './ProjectSwitcher.svelte'
  import ShareModal from './ShareModal.svelte'

  const { undo_manager } = $props<{ undo_manager: import('yjs').UndoManager }>()

  let can_undo = $state(false), can_redo = $state(false)

  const sync_undo_manager = (type) => (event) => {
    can_undo = undo_manager.canUndo()
    can_redo = undo_manager.canRedo()
  }

  undo_manager.on('stack-item-added', sync_undo_manager('added'))
  undo_manager.on('stack-item-popped', sync_undo_manager('popped'))


  /** @param {{ blob: Blob, title: string }} params */
  const create_download = ({ blob, title }) => {
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')

    link.style.display = 'none'
    link.href = url
    link.download = title.replace(/ /g, '_')

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)
  }

  const download_svg = () => {
    const scene = $project_store.scenes[$artboard_state.active_scene_ids[0] || $project_store.scene_ids[0]]

    const svg = create_svg({ scene })

    return create_download({
      title: `scene-${scene.id}.svg`,
      blob: new Blob([ svg ], { type: 'image/svg+xml;charset=utf-8' })
    })
  }
</script>

<div class="flex items-center justify-between w-full">
  <div class="flex items-center space-x-2">
    <ProjectSwitcher />

    <input
      type="text"
      class="input input-sm leading-none input-bordered min-w-0 hover:z-10 focus:z-10"
      bind:value={$project_store.name}
      readonly={!$page.props.is_project_owner}
    />

    <div class="join">
      <button on:click={() => undo_manager.undo()} class="btn btn-sm btn-square join-item" disabled={!can_undo}>
        <Icon name="undo" class="h-4 w-4" />
      </button>

      <button on:click={() => undo_manager.redo()} class="btn btn-sm btn-square join-item" disabled={!can_redo}>
        <Icon name="redo" class="h-4 w-4" />
      </button>
    </div>

    <div class="dropdown dropdown-hover dropdown-top">
      <button on:click={download_svg} class="flex justify-center">
        <div class="btn btn-sm btn-square">
          <Icon name="download" class="h-4 w-4" />
        </div>

        <div class="dropdown-content z-[1] shadow bg-base-100/90 p-2 mb-2">
          <p class="whitespace-nowrap text-xs !font-sans">Download SVG</p>
        </div>
      </button>
    </div>
  <!--
    <details class="dropdown dropdown-end">
      <summary class="btn btn-sm btn-square join-item hover:z-10">
        {@html icons['chevron-up']('h-4 w-4 rotate-180')}
      </summary>

      <ul class="p-0 mt-2 text-xs shadow menu dropdown-content z-[1] bg-base-100 rounded-box">
        <li>
          <button class="text-error hover:text-error-content">Delete</button>
        </li>
      </ul>
    </details> -->
  </div>

  {#if $page.props.is_project_owner}
    <ShareModal />
  {/if}
</div>