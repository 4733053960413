<script>
  import { page, useForm } from '@inertiajs/svelte'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  let is_submitting = false

  const form = useForm({
    email: '',
    password: '',
    password_confirmation: ''
  })

  function register_user (evt) {
    evt.preventDefault()

    is_submitting = true

    $form.post(this.action, {
      onSuccess: () => dispatch('close'),
      onFinish: () => is_submitting = false
    })
  }
</script>

{#if $page.props.current_user.email}
  <p>We've emailed you an activation link to save your projects.</p>

  <form action="/registrations" method="post" on:submit={register_user}>
    Didn't get it?

    <button class="link link-primary focus:outline-none">Resend</button>.
  </form>
{:else}
  <p>We automatically save your projects locally. An account gives you access from anywhere.</p>

  <form class="space-y-3" action="/registrations" method="post" on:submit={register_user}>
    <div>
      <label class="label pt-0 pb-0.5" for="email">
        <span class="label-text-alt">Email</span>
      </label>

      <input
        type="email"
        required
        name="email"
        bind:value={$form.email}
        placeholder="you@example.com"
        class="input input-bordered w-full"
        class:input-error={$form.errors?.email}
      />

      {#if $form.errors?.email}
        <ul class="text-sm text-error mt-2">
          {#each $form.errors.email as error}
            <li>{error}</li>
          {/each}
        </ul>
      {/if}
    </div>

    <div>
      <label class="label pt-0 pb-0.5" for="password">
        <span class="label-text">Password</span>
      </label>

      <input
        type="password"
        required
        name="password"
        bind:value={$form.password}
        class="input input-bordered w-full"
        class:input-error={$form.errors?.password}
      />

      {#if $form.errors?.password}
        <ul class="text-sm text-error mt-2">
          {#each $form.errors.password as error}
            <li>{error}</li>
          {/each}
        </ul>
      {/if}
    </div>

    <div>
      <label class="label pt-0 pb-0.5" for="password_confirmation">
        <span class="label-text">Confirm your password</span>
      </label>

      <input
        type="password"
        required
        name="password_confirmation"
        bind:value={$form.password_confirmation}
        class="input input-bordered w-full"
        class:input-error={$form.errors?.password_confirmation}
      />

      {#if $form.errors?.password_confirmation}
        <ul class="text-sm text-error mt-2">
          {#each $form.errors.password_confirmation as error}
            <li>{error}</li>
          {/each}
        </ul>
      {/if}
    </div>

    <button type="submit" class="btn w-full btn-accent btn-outline font-normal">
      {#if is_submitting}<span class="loading loading-dots"></span>{/if}

      Create your account
    </button>
  </form>

  <p class="text-center py-3">Already have an account? <label class="link link-primary" for="is_signing_up">Sign in</label>.</p>
{/if}