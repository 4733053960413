<script context="module">
  import { writable } from 'svelte/store'
  import { page } from '@inertiajs/svelte'
  import { random_uint_32 } from '@pogzul/engine';

  const flash_messages = writable([])

  export const notify = ({ type, message }) => {
    flash_messages.update(old_value => ([
      ...old_value,
      { type, message, id: random_uint_32() }
    ]))
  }
</script>

<script>
  import { onMount } from 'svelte'
  import { fly } from 'svelte/transition'
  import { flip } from 'svelte/animate';

  const append_messages = (flash) => {
    if (!is_mounted) return

    if (Array.isArray(flash)) {
      for (let [type, message] of flash) {
        notify({ type, message })
      }
    }
  }

  $: append_messages($page.props.flash)


  let is_mounted = false
  onMount(() => is_mounted = true)

  const MESSAGE_TIMEOUT_MS = 3000

  /** @type {import('svelte/action').Action}  */
  const remove_message = (node) => {
    setTimeout(() => {
      $flash_messages = $flash_messages.filter(message => message.id !== Number(node.id))
    }, MESSAGE_TIMEOUT_MS)
  }
</script>

<div class="toast toast-top toast-end z-[1000] !p-0 top-3 right-3" style="padding: 0px !important;">
  {#each $flash_messages as { type, message, id } (id) }
    <div {id} use:remove_message animate:flip out:fly={{ duration: 300, x: 50 }} role="alert" class="alert py-3 px-4 shadow-lg dark:bg-neutral">
      {#if type === 'success'}
        <svg class="stroke-success shrink-0 w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
      {:else if type === 'info'}
        <svg class="stroke-info shrink-0 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
      {:else if type === 'warning'}
        <svg class="stroke-warning shrink-0 w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
        </svg>
      {:else if type === 'error'}
        <svg class="stroke-error shrink-0 w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"></path>
        </svg>
      {/if}

      <span class="text-sm">{message}</span>
    </div>
  {/each}
</div>

