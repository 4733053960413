import { BatchRenderer } from './BatchRenderer'
import { Shader } from './Shader'
import { Texture } from './Texture'
import { quad as rect, vec2 } from './utils'

import { STRIDE, VERTEX_FORMAT } from './config'

export class Graphics {
  /** @type {WebGLRenderingContext} */
  gl

  /** @type {import('./MatrixStack').MatrixStack} */
  transform_stack

  /** @type {BatchRenderer} */
  renderer

  /** @type {Texture} */
  default_texture

  /** @type {Shader} */
  default_shader

  /** @param {Partial<Graphics>} opts */
  constructor(opts) {
    Object.assign(this, opts)

    this.renderer = new BatchRenderer({ gl: this.gl })

    this.default_texture = new Texture({ gl: this.gl })
    this.default_shader = new Shader({ gl: this.gl })

    enable_attribute_pointers(this.gl)
  }

  flush() {
    this.renderer.flush()
  }

  /**
   * @param {object} params
   * @param {import('./types').Vertex[]} params.vertices
   * @param {number[]} params.indices
   * @param {Texture} params.texture
   * @param {Shader} params.shader
   * @param {import('./types').Uniform} params.uniform
   */
  drawRaw({
    vertices: verts,
    indices,
    texture,
    shader = this.default_shader,
    uniform = {}
  }) {
    const vertices = []

    for (let v of verts) {
      vertices.push(
        v.pos[0],
        v.pos[1],
        v.uv[0],
        v.uv[1],
        v.color[0] / 255,
        v.color[1] / 255,
        v.color[2] / 255,
        v.color[3] / 255
      )
    }

    this.renderer.push({ vertices, indices, texture, shader, uniform })
  }

  drawQuad({
    pos = vec2(0, 0),
    width = 1,
    height = 1,
    quad = rect(
      0,
      0,
      1,
      1
    ) /* Texture coords go from 0.0 to 1.0 — i.e., this is the whole texture */,
    texture = this.default_texture,
    color = new Uint8Array([255, 255, 255, 255]),
    matrix = this.transform_stack.current,
    shader = this.default_shader,
    uniform = {}
  }) {
    let x2 = pos.x + width,
      y2 = pos.y + height

    const vertices = [
      {
        pos: position_matrix(pos.x, pos.y, matrix),
        uv: [quad.x, quad.y],
        color: color
      },
      {
        pos: position_matrix(x2, pos.y, matrix),
        uv: [quad.x + quad.w, quad.y],
        color: color
      },
      {
        pos: position_matrix(x2, y2, matrix),
        uv: [quad.x + quad.w, quad.y + quad.h],
        color: color
      },
      {
        pos: position_matrix(pos.x, y2, matrix),
        uv: [quad.x, quad.y + quad.h],
        color: color
      }
    ]

    const indices = [0, 1, 2, 0, 2, 3]

    this.drawRaw({ vertices, indices, texture, shader, uniform })
  }
}

/** @param {WebGLRenderingContext} gl */
const enable_attribute_pointers = (gl) => {
  VERTEX_FORMAT.reduce((offset, format, index) => {
    gl.vertexAttribPointer(
      index,
      format.size,
      gl.FLOAT,
      false,
      STRIDE * 4,
      offset
    )

    gl.enableVertexAttribArray(index)

    return offset + format.size * 4
  }, 0)
}

/**
 * @param {number} x
 * @param {number} y
 * @param {number[]} matrix
 */
const position_matrix = (x, y, matrix /* 3x3 */) => {
  const nx = x * matrix[0] + y * matrix[3] + matrix[6],
    ny = x * matrix[1] + y * matrix[4] + matrix[7]

  return [nx, ny]
}

export default Graphics
