import { random_uint_32, pick, delete_layers, active } from '@pogzul/engine'
import { Group, Shape, Text } from './layers'

export class Entity {
  /** @type {EntityId} */
  id = random_uint_32()

  /** @type {number} */
  x = 0

  /** @type {number} */
  y = 0

  /** @type {number} */
  width = 0

  /** @type {number} */
  height = 0

  /** @type {number} */
  opacity = 1

  /** @type {boolean} */
  is_hidden = false

  /** @type {import('./Scene').Scene} */
  #scene

  /** @param {Partial<Group | Text | Shape>} json */
  static parse(json) {
    switch (true) {
      case 'pixels' in json:
        return new Shape(json)
      case 'font' in json:
        return new Text(json)
      case 'layer_ids' in json:
        return new Group(json)
    }
  }

  /** @param {Partial<Entity>} opts */
  constructor(opts = {}) {
    Object.assign(
      this,
      pick(
        opts,
        'id',
        'x',
        'y',
        'width',
        'height',
        'opacity',
        'is_hidden',
        'scene',
        'tags'
      )
    )
  }

  // update(delta_s = 1) {
  //   if (this.velX) this.x += this.velX * delta_s
  //   if (this.velY) this.y += this.velY * delta_s
  // }

  get scene() {
    return this.#scene
  }

  set scene(scene) {
    this.#scene = scene
  }

  /** @returns {Tag[]} */
  get tags() {
    return Object.keys(active.scene.components.tags).filter((key) =>
      (active.scene.components.tags[key] || []).includes(this.id)
    )
  }

  /** @param {Tag[]} tags */
  tag(...tags) {
    for (const tag of tags) {
      const entities = (active.scene.components.tags[tag] ||= [])

      if (!entities.includes(this.id)) entities.push(this.id)
    }
  }

  get position() {
    return { x: this.x, y: this.y }
  }
  get pos() {
    return this.position
  }

  set position({ x, y }) {
    this.x = x
    this.y = y
  }
  set pos(pos) {
    this.position = pos
  }

  // get velocity() {
  //   return { x: this.velX, y: this.velY }
  // }
  // get vel() {
  //   return this.velocity
  // }

  // set velocity({ x, y }) {
  //   this.velX = x
  //   this.velY = y
  // }

  // set vel(vel) {
  //   this.velocity = vel
  // }

  destroy() {
    delete_layers({ scene: this.scene, layer_ids: [this.id] })
  }
}

export default Entity
