const KEYMAP = {
  ArrowLeft: 'left',
  ArrowRight: 'right',
  ArrowUp: 'up',
  ArrowDown: 'down',
  ' ': 'space'
}

export class RuntimeEvent {
  /** @param {MouseEvent | KeyboardEvent} evt */
  constructor(evt) {
    this.type = evt.type

    let properties = [],
      options = {}

    if (evt instanceof KeyboardEvent) {
      options = { key: KEYMAP[evt.key] || evt.key }
      properties = [
        'code',
        'keyCode',
        'shiftKey',
        'metaKey',
        'ctrlKey',
        'altKey'
      ]
    } else if (evt instanceof MouseEvent) {
      options = { x: evt.offsetX, y: evt.offsetY }
      properties = ['shiftKey', 'metaKey', 'ctrlKey', 'altKey', 'buttons']
    }

    for (let prop of properties) {
      this[prop] = evt[prop]
    }

    Object.assign(this, options)
  }
}

export default RuntimeEvent
