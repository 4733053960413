<script>
  import { project as project_store } from '$lib/stores'

  import CollaborationForm from './CollaborationForm.svelte'
  import PublishingForm from './PublishingForm.svelte'

  /** @type {HTMLDialogElement} */
  let modal

  let publishing_form

  const show_modal = () => {
    publishing_form.fetch_experience()

    modal.showModal()
  }
</script>

<button on:click={show_modal} class="btn btn-sm btn-primary btn-outline bg-base-100 hover:!text-white">
  Share
</button>

<dialog bind:this={modal} class="modal">
  <div class="modal-box space-y-3">
    <h3 class="text-xl font-bold">Share "{$project_store.name}"</h3>

    <div class="tabs tabs-lifted w-full grid-cols-2 !mt-5" role="tablist">
      <input type="radio" name="sharing-tabs" class="tab focus:outline-none !border-base-content/20" aria-label="Collaborate" checked />
      <div class="tab-content pt-6 space-y-3 ">
        <CollaborationForm />
      </div>

      <input type="radio" name="sharing-tabs" class="tab focus:outline-none !border-base-content/20" aria-label="Publish" />
      <div class="tab-content pt-6 space-y-3">
        <PublishingForm bind:this={publishing_form} />
      </div>
    </div>

    <form method="dialog" class="flex justify-end">
      <button class="btn">Close</button>
    </form>
  </div>

  <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
</dialog>