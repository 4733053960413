<script lang="ts">
  import '../app.css'

  import TextEditor from './TextEditor.svelte'
  import Terminal from './Terminal.svelte'

  type Props = {
    ytext: import('yjs').Text
    terminal: Terminal
    editable: boolean,
    extensions: any[]
  }

  let {
    ytext,
    terminal,
    editable = true,
    extensions
  } = $props<Props>()
</script>

<div class="h-full flex flex-col justify-between bg-base-100/75 z-10">
  <TextEditor
    text={ytext.toString()}
    {extensions}
    {editable}
  />

  <div class="relative">
    <slot name="actions" />
  </div>

  <Terminal bind:this={terminal} />
</div>