import Tool from './Tool.js'
import { config, Shape, Pixels, Group, Text, get_pixel } from '@pogzul/engine'
import { state } from '../stores'
import { get } from 'svelte/store'

const RESIZE_OUTER_BOUNDARY = 10,
  RESIZE_INNER_BOUNDARY = 5

class MoveTool extends Tool {
  /** @type {'Move'} */
  name = 'Move'

  shortcut = 'm'

  #is_selecting_box = false

  /**
   * @type {Record<
   *   number,
   *   { x: number; y: number; width?: number; height?: number }
   * >}
   */
  #starting_layer_positions = {}

  #were_layers_moved = false

  /** @type {Partial<Record<'north' | 'south' | 'east' | 'west', true>>} */
  #resizing = {}

  /** @param {import('../webgl').Context} ctx */
  render(ctx) {
    super.render(ctx)

    if (this.#is_selecting_box) {
      ctx.strokeRect({
        x: Math.min(this.screen_x1, this.screen_x2),
        y: Math.min(this.screen_y1, this.screen_y2),
        width: Math.abs(this.screen_x2 - this.screen_x1),
        height: Math.abs(this.screen_y2 - this.screen_y1),
        color: new Uint8Array([56, 189, 248, 255]),
        lineWidth: 2
      })
    }
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseDown(evt) {
    super.onMouseDown(evt)

    if (evt.is_targeting_editor) {
      if (this.#resize_cursor) {
        for (let id of evt.active_layer_ids) {
          const layer = evt.scene.entities[id]

          this.#starting_layer_positions[id] = {
            ...layer.position,
            width: layer.width,
            height: layer.height
          }
        }

        return
      }

      state.update((old_state) => {
        let active_layer_ids

        // Bug: hovering_layer_ids is wrong _if the mouse doesn't move_
        if (!old_state.hovering_layer_ids.length) this.#is_selecting_box = true

        const is_hovering_active_layer = old_state.active_layer_ids.includes(
          old_state.hovering_layer_ids[0]
        )

        /* If hovering an active layer, don't change anything */
        if (is_hovering_active_layer) {
          active_layer_ids = old_state.active_layer_ids
        } else if (evt.shift_key) {
          /* If holding shift, add hovering_layer_ids to active_layer_ids */
          active_layer_ids = Array.from(
            new Set([
              ...old_state.active_layer_ids,
              ...old_state.hovering_layer_ids
            ])
          )
        } else {
          active_layer_ids = old_state.hovering_layer_ids
        }

        for (const id of active_layer_ids) {
          this.#starting_layer_positions[id] = evt.scene.entities[id].position
        }

        return { ...old_state, active_layer_ids }
      })
    }
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseUp(evt) {
    if (
      (evt.is_targeting_canvas && !this.#were_layers_moved && !evt.shift_key) ||
      this.#is_selecting_box
    ) {
      state.update((old_state) => ({
        ...old_state,
        active_layer_ids: old_state.hovering_layer_ids
      }))
    }

    this.#is_selecting_box = false
    this.#starting_layer_positions = {}
    this.#were_layers_moved = false

    return super.onMouseUp(evt)
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseMove(evt) {
    super.onMouseMove(evt)

    if (evt.is_mouse_down) {
      if (this.#is_selecting_box) return this.#update_selection_box(evt)
      if (this.#resize_cursor) return this.#resize_layers(evt)

      this.#drag_layers(evt)
    } else if (evt.is_targeting_editor) {
      this.#handle_pointer(evt)
    } else if (evt.is_targeting_canvas) {
      state.update((old_state) => ({
        ...old_state,
        hovering_layer_ids: []
      }))
    }
  }

  /** @param {import('./ToolEvent').default} evt */
  #update_selection_box({ scene }) {
    const contained_layer_ids = scene.layer_ids.filter((id) => {
      const layer = scene.entities[id]

      const [x1, x2] = min_max(this.screen_x1, this.screen_x2),
        [y1, y2] = min_max(this.screen_y1, this.screen_y2)

      return (
        x1 <= layer.x + layer.width &&
        x2 >= layer.x &&
        y1 <= layer.y + layer.height &&
        y2 >= layer.y
      )
    })

    return state.update((old_state) => ({
      ...old_state,
      hovering_layer_ids: contained_layer_ids
    }))
  }

  /** @param {import('./ToolEvent').default} evt */
  #resize_layers({ scene, mouse_x, mouse_y, active_layer_ids }) {
    for (let id of active_layer_ids) {
      if (!this.#starting_layer_positions[id]) continue

      const { x, y, width, height } = this.#starting_layer_positions[id]

      const layer = scene.entities[id]

      if (layer instanceof Text) {
        if (this.#resizing.north) {
          const new_y = screen_position(mouse_y)

          layer.y = new_y
          layer.height = y + height - new_y
        } else if (this.#resizing.south) {
          layer.height = screen_position(mouse_y) - layer.y
        }

        if (this.#resizing.west) {
          const new_x = screen_position(mouse_x)

          layer.x = new_x
          layer.width = x + width - new_x
        } else if (this.#resizing.east) {
          layer.width = screen_position(mouse_x) - layer.x
        }
      }

      // let pixels = new Pixels()

      // // TODO: fix origin bug when scaling in negative directions...

      // let scale_x = 1,
      //   scale_y = 1,
      //   scale_x_sign = 1,
      //   scale_y_sign = 1

      // if (this.#resize_cursor !== 'ns-resize') {
      //   scale_x = Math.abs(mouse_x - x) / layer.width
      //   scale_x_sign = Math.sign(mouse_x - layer.x)
      // }

      // if (this.#resize_cursor !== 'ew-resize') {
      //   scale_y = Math.abs(mouse_y - layer.y) / layer.height
      //   scale_y_sign = Math.sign(mouse_y - layer.y)
      // }

      // const scaled_width = scale_x * (layer.width / config.pixel_size),
      //   scaled_height = scale_y * (layer.height / config.pixel_size)

      // for (let x = 0; x < scaled_width; x++) {
      //   for (let y = 0; y < scaled_height; y++) {
      //     const color =
      //       layer.pixels.get(
      //         Math.floor(x / scale_x),
      //         Math.floor(y / scale_y)
      //       ) || null

      //     // console.log(
      //     //   'setting x',
      //     //   x * scale_x_sign + Math.sign(scale_x_sign - 1)
      //     // )

      //     /* `+ Math.sign(scale_x_sign - 1)` is for reflection of negative values */
      //     pixels.set(
      //       x * scale_x_sign + Math.sign(scale_x_sign - 1),
      //       y * scale_y_sign + Math.sign(scale_y_sign - 1),
      //       color
      //     )
      //   }
      // }

      // scene.entities[id].pixels = pixels
      // scene.entities[id].update()
    }
  }

  /** @param {import('./ToolEvent').default} evt */
  #drag_layers({ scene, mouse_x, mouse_y }) {
    const { active_layer_ids, hovering_layer_ids } = get(state)

    const is_hovering_active_layer =
      active_layer_ids.filter((id) => hovering_layer_ids.includes(id)).length >
      0

    if (is_hovering_active_layer) {
      for (let id of active_layer_ids) {
        let layer = scene.entities[id]

        const { x, y } = this.#starting_layer_positions[id]

        layer.x = screen_position(x + mouse_x - this.screen_x1)
        layer.y = screen_position(y + mouse_y - this.screen_y1)
      }

      this.#were_layers_moved = true
    }
  }

  /** @param {import('./ToolEvent').default} evt */
  #handle_pointer({ scene, mouse_x, mouse_y }) {
    this.#resizing = {}

    const { active_layer_ids, hovering_layer_ids } = get(state)

    for (let id of [...scene.layer_ids].reverse()) {
      let layer = scene.entities[id]

      if (layer.is_hidden && !active_layer_ids.includes(id)) continue

      if ('pixels' in layer || layer instanceof Group) {
        /*
         * This should be the grouped layer boundary if there are multiple
         * active layers...
         */
        // if (active_layer_ids.includes(id)) {
        //   if (
        //     mouse_x > layer.x - RESIZE_OUTER_BOUNDARY &&
        //     mouse_x < layer.x + layer.width + RESIZE_OUTER_BOUNDARY &&
        //     mouse_y > layer.y - RESIZE_OUTER_BOUNDARY &&
        //     mouse_y < layer.y + layer.height + RESIZE_OUTER_BOUNDARY
        //   ) {
        //     let north, south, east, west
        //     if (mouse_x < layer.x + RESIZE_INNER_BOUNDARY) {
        //       west = true
        //     } else if (
        //       mouse_x >
        //       layer.x + layer.width - RESIZE_INNER_BOUNDARY
        //     ) {
        //       east = true
        //     }

        //     if (mouse_y < layer.y + RESIZE_INNER_BOUNDARY) {
        //       north = true
        //     } else if (
        //       mouse_y >
        //       layer.y + layer.height - RESIZE_INNER_BOUNDARY
        //     ) {
        //       south = true
        //     }

        //     if (east || west) {
        //       if (north) {
        //         this.#resize_cursor = east ? 'nesw-resize' : 'nwse-resize'
        //       } else if (south) {
        //         this.#resize_cursor = east ? 'nwse-resize' : 'nesw-resize'
        //       } else {
        //         this.#resize_cursor = 'ew-resize'
        //       }
        //     } else if (north || south) {
        //       this.#resize_cursor = 'ns-resize'
        //     }
        //   }
        // }

        if (get_pixel(layer, mouse_x, mouse_y)) {
          state.update((old_state) => ({
            ...old_state,
            cursor: this.#resize_cursor,
            hovering_layer_ids: [layer.id]
          }))

          return
        }
      } else if (layer instanceof Text) {
        if (active_layer_ids.includes(id)) {
          if (
            mouse_x > layer.x - RESIZE_OUTER_BOUNDARY &&
            mouse_x < layer.x + layer.width + RESIZE_OUTER_BOUNDARY &&
            mouse_y > layer.y - RESIZE_OUTER_BOUNDARY &&
            mouse_y < layer.y + layer.height + RESIZE_OUTER_BOUNDARY
          ) {
            if (mouse_x < layer.x + RESIZE_INNER_BOUNDARY) {
              this.#resizing.west = true
            } else if (
              mouse_x >
              layer.x + layer.width - RESIZE_INNER_BOUNDARY
            ) {
              this.#resizing.east = true
            }

            if (mouse_y < layer.y + RESIZE_INNER_BOUNDARY) {
              this.#resizing.north = true
            } else if (
              mouse_y >
              layer.y + layer.height - RESIZE_INNER_BOUNDARY
            ) {
              this.#resizing.south = true
            }
          }
        }

        if (
          mouse_x >= layer.x &&
          mouse_x <= layer.x + layer.width &&
          mouse_y >= layer.y &&
          mouse_y <= layer.y + layer.height
        ) {
          state.update((old_state) => ({
            ...old_state,
            cursor: this.#resize_cursor,
            hovering_layer_ids: [layer.id]
          }))

          return
        }
      }
    }

    if (hovering_layer_ids.length) {
      state.update((old_state) => ({
        ...old_state,
        cursor: this.#resize_cursor,
        hovering_layer_ids: []
      }))
    } else {
      state.update((old_state) => ({
        ...old_state,
        cursor: this.#resize_cursor
      }))
    }
  }

  get #resize_cursor() {
    const { north, east, south, west } = this.#resizing

    if (east || west) {
      if (north) {
        return east ? 'nesw-resize' : 'nwse-resize'
      } else if (south) {
        return east ? 'nwse-resize' : 'nesw-resize'
      } else {
        return 'ew-resize'
      }
    } else if (north || south) {
      return 'ns-resize'
    }

    return null
  }
}

const create_gradient_image = () => {
  const size = 10

  let canvas = document.createElement('canvas')
  let ctx = canvas.getContext('2d')

  canvas.width = size
  canvas.height = size

  // Draw border
  let gradient = ctx.createLinearGradient(0, 0, size, size)

  gradient.addColorStop(0, '#34d399')
  gradient.addColorStop(1, '#38bdf8')

  ctx.fillStyle = gradient
  ctx.fillRect(0, 0, size, size)

  return ctx.getImageData(0, 0, canvas.width, canvas.width)
}

/** @param {...number} args */
export const min_max = (...args) => {
  let arr = args.flat()

  return arr.reduce(
    (minMaxArr, item) => {
      let [min, max] = minMaxArr

      if (item < min) min = item
      if (item > max) max = item

      return [min, max]
    },
    [arr[0], arr[0]]
  )
}

/** @param {number} coordinate */
const screen_position = (coordinate) =>
  Math.round(coordinate / config.pixel_size) * config.pixel_size

export default MoveTool
