<script lang="ts">
  import { artboard_state } from '../../stores'
  import { Group, Text, rgb_array_to_hex, canvases, sort_layers } from '@pogzul/engine'
  import { SortableList, Icon }  from '../../components'

  let { scene, editable = true } = $props<{ scene: import('@pogzul/engine').Scene, editable: boolean }>()

  // Closest layer at the top
  const layer_ids =  $derived([...scene.layer_ids].reverse())

  const handle_sort = (evt) => {
    if (!editable) return

    const { oldIndex, newIndex } = evt.detail

    sort_layers({
      scene,
      layer_ids: [layer_ids[oldIndex]],
      delta: oldIndex - newIndex
    })

    scene = scene
  }

  const select_layer = (id) => (evt) => {
    if (!editable) return

    if (evt.shiftKey) {
      if ($artboard_state.active_layer_ids.includes(id)) {
        $artboard_state.active_layer_ids = $artboard_state.active_layer_ids.filter(layer_id => layer_id !== id)
      } else {
        $artboard_state.active_layer_ids = [...$artboard_state.active_layer_ids, id]
      }
    } else {
      $artboard_state.active_layer_ids = [id]
    }

    scene = scene
  }
</script>

<div
  class="select-none max-h-full h-full p-1.5 overflow-auto w-[3.8rem] bg-base-300/50 dark:bg-base-100/90"
>
  <SortableList
    class="space-y-1.5"
    list={layer_ids}
    onsort={handle_sort}
    let:item={id}
  >
    {@const layer = scene.entities[id] }
    {@const item = layer instanceof Group ? scene.entities[layer.layer_ids[0]] : scene.entities[id] }

    <div class="stack w-full" class:mb-1.5={layer instanceof Group}>
      {#if item && 'pixels' in item}
        <button
          on:mouseover={() => $artboard_state.hovering_layer_ids = [id]}
          on:mouseleave={() => $artboard_state.hovering_layer_ids = []}
          on:click={select_layer(id)}
          on:focus
          class="btn btn-square relative"
          class:!border-success={$artboard_state.active_layer_ids.includes(id)}
          class:!border-info={$artboard_state.hovering_layer_ids.includes(id)}
        >
          <div class="h-8 w-8 flex-shrink-0 flex items-center justify-center">
            <img
              src={canvases[item.id]?.image.src}
              class="max-h-8 w-8 object-contain crisp-edges"
              alt="Layer {id}"
            />
          </div>

          {#if item?.is_hidden}
            <div class="absolute top-1 right-1">
              <Icon name="eye-slash" class="h-3 w-3" />
            </div>
          {/if}
        </button>
      {:else if item instanceof Text}
        <button
          on:mouseover={() => $artboard_state.hovering_layer_ids = [id]}
          on:mouseleave={() => $artboard_state.hovering_layer_ids = []}
          on:click={select_layer(id)}
          on:focus
          class="btn btn-square relative"
          class:!border-success={$artboard_state.active_layer_ids.includes(id)}
          class:!border-info={$artboard_state.hovering_layer_ids.includes(id)}
        >
          <div class="h-8 w-8 flex-shrink-0 flex items-center justify-center overflow-hidden">
            <p class="whitespace-pre w-full h-full text-[10px]" style="color: {rgb_array_to_hex(item.color)};">{item.value}</p>
          </div>

          {#if item?.is_hidden}
            <div class="absolute top-1 right-1">
              <Icon name="eye-slash" class="h-3 w-3" />
            </div>
          {/if}
        </button>
      {/if}

      {#if layer instanceof Group}
        {#each layer.layer_ids.slice(0, 2) as _layer_id, index }
          <div
            class="btn btn-square pointer-events-none"
            class:!border-success={$artboard_state.active_layer_ids.includes(id)}
            class:!border-info={$artboard_state.hovering_layer_ids.includes(id)}
            style="transform: translateY({ 10 * (index + 1) }%) scale({ 1 - (index + 1) * .05 });"
          >
            <div class="h-7 w-7">
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </SortableList>
</div>