import { get } from 'svelte/store'
import { IndexeddbPersistence } from 'y-indexeddb'
import { HocuspocusProvider } from '@hocuspocus/provider'
import { yCollab } from 'y-codemirror.next'
import * as Y from 'yjs'
import { page } from '@inertiajs/svelte'
import { update_document } from './utilities'
import { is_running, Project } from '@pogzul/engine'

import { project, present_users, has_run } from '$lib/stores'

let is_local_synced = false,
  is_remote_synced = false

/** @type {Y.UndoManager} */
export let undo_manager

let is_yjs_update = false

/** @type {HocuspocusProvider} */
let provider

/** @param {Record<string, any>} fields */
export const update_presence = (fields = {}) => {
  if (!provider) return

  provider.setAwarenessField('user', {
    ...get(page).props.current_user,
    ...fields
  })
}

/**
 * @param {string} id
 * @param {Y.Doc} doc
 * @param {boolean} editable
 */
export const sync = (id, doc, editable) => {
  if (typeof window === 'undefined') return

  const { current_user, hp_token } = get(page).props

  const ymap = doc.getMap()

  undo_manager = new Y.UndoManager(ymap, {
    trackedOrigins: new Set([doc.clientID])
  })

  /** @param {Y.YMapEvent<any>} event */
  const observe_doc = ({ target, transaction }) => {
    if (transaction.origin === doc.clientID || get(has_run) || get(is_running))
      return

    is_yjs_update = true
    project.set(Project.from(target.doc || doc, id))
    is_yjs_update = false
  }

  /**
   * Run this too early at your own peril (overwriting data...)
   *
   * @param {Project} project
   */
  const persist_project = (project) => {
    if (!editable || is_yjs_update || !(is_local_synced || is_remote_synced))
      return

    doc.transact(() => update_document(ymap, project), doc.clientID)
  }

  provider = new HocuspocusProvider({
    url: get(page).props.config.sync_url,
    name: id,
    document: doc,
    token: hp_token,
    parameters: {
      token: hp_token
    },
    onAwarenessUpdate({ states }) {
      const users = states
        .filter((state) => state.user && state.user.id !== current_user.id)
        .map((state) => state.user)

      present_users.set(users)
    }
  })

  provider.on('synced', () => (is_remote_synced = true))

  ymap.observe(observe_doc)

  // This might get easier with Svelte 5's signals... otherwise... :/
  let previous_json = JSON.stringify(get(project))
  project.subscribe((new_project) => {
    if (!get(is_running)) {
      const new_json = JSON.stringify(new_project)

      has_run.set(false)

      if (previous_json !== new_json) persist_project(new_project)

      previous_json = new_json
    }
  })

  has_run.subscribe((current_has_run) => {
    if (!current_has_run) project.set(Project.from(doc, id))
  })

  let is_running_initiated = false
  is_running.subscribe((is_now_running) => {
    if (!is_running_initiated) return (is_running_initiated = true)

    if (!is_now_running) {
      has_run.set(JSON.stringify(get(project)) !== previous_json)
    }
  })

  update_presence()

  /** Store locally... */
  const persistence = new IndexeddbPersistence(`@pogzul/${id}`, doc)
  persistence.on('synced', () => (is_local_synced = true))

  // return () => ymap.unobserve(observe_doc)
  const ytext = ymap.get('ytexts').get(String(ymap.get('ytext_ids')[0]))

  return {
    codemirror_extension: yCollab(ytext, provider.awareness)
  }
}
