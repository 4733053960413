import {
  config,
  rgb_array_to_hex,
  colors_are_equal,
  Group,
  Text,
  Shape
} from '@pogzul/engine'

import SvgCanvas from 'canvas2svg'

/** @param {{ scene: import('@pogzul/engine').Scene }} params */
export const create_svg = ({ scene }) => {
  const ctx = new SvgCanvas(config.canvas_size, config.canvas_size)

  /** @type {(Text | Shape)[]} */
  // @ts-ignore
  const layers = scene.layer_ids.reduce((acc, id) => {
    const entity = scene.entities[id]

    if (entity instanceof Group) return [...acc, ...entity.layers]

    return [...acc, entity]
  }, [])

  for (let layer of layers) {
    if (layer instanceof Text || layer.is_hidden) continue

    const polygons = create_polygons({ layer })

    for (let polygon of polygons) {
      render({ ctx, polygon, layer })
    }
  }

  return ctx.getSerializedSvg()
}

/** @param {{ layer: import('@pogzul/engine').Shape }} params */
const create_polygons = ({ layer }) => {
  const groups = create_pixel_groups({ layer })

  return groups.map(create_pixel_group_polygon)
}

/** @param {{ layer: import('@pogzul/engine').Shape }} params */
const create_pixel_groups = ({ layer }) => {
  let groups = []

  /** @type {string[]} */
  let filled = []

  for (let [x, row] of layer.pixels) {
    for (let [y, _] of row) {
      if (filled.includes(coords(x, y))) continue

      const onScreen =
        layer.x + x * config.pixel_size >= 0 &&
        layer.x + x * config.pixel_size < config.canvas_size &&
        layer.y + y * config.pixel_size >= 0 &&
        layer.y + y * config.pixel_size < config.canvas_size

      if (!onScreen) continue

      const { filled_coords, color } = pixelFill({ layer, x, y })

      groups.push({ pixel_coords: filled_coords, color })
      filled.push(...filled_coords)
    }
  }

  return groups
}

/**
 * Recursively fills adjacent pixels starting at x, y
 *
 * @param {Object} params
 * @param {import('@pogzul/engine').Shape} params.layer
 * @param {number} params.x
 * @param {number} params.y
 */
const pixelFill = ({ layer, x, y }) => {
  /** @type {string[]} */
  let filled_coords = []

  let target_color = layer.pixels.get(x, y)

  /**
   * @param {number} x
   * @param {number} y
   */
  const _fill = (x, y) => {
    if (filled_coords.includes(coords(x, y))) return

    filled_coords.push(coords(x, y))

    const left =
      colors_are_equal(layer.pixels.get(x - 1, y), target_color) &&
      layer.x + x * config.pixel_size > 0

    const right =
      colors_are_equal(layer.pixels.get(x + 1, y), target_color) &&
      layer.x + x * config.pixel_size < config.canvas_size - 1

    const top =
      colors_are_equal(layer.pixels.get(x, y - 1), target_color) &&
      layer.y + y * config.pixel_size > 0

    const bottom =
      colors_are_equal(layer.pixels.get(x, y + 1), target_color) &&
      layer.y + y * config.pixel_size < config.canvas_size - 1

    if (left) _fill(x - 1, y)
    if (right) _fill(x + 1, y)
    if (top) _fill(x, y - 1)
    if (bottom) _fill(x, y + 1)
  }

  _fill(x, y)

  return { filled_coords, color: rgb_array_to_hex(target_color) }
}

/**
 * Transforms a group of pixels into an array of paths around its perimeter (or
 * inside)
 *
 * @param {Object} params
 * @param {string[]} params.pixel_coords
 * @param {string} params.color
 */
const create_pixel_group_polygon = ({ pixel_coords, color }) => {
  let edges = get_edges({ pixel_coords })

  /** @type {number[][]} */
  let paths = []

  const createPath = () => {
    let path_vertices = new Set([edges[0].start, edges[0].finish])

    while (edges.length > 0) {
      const last_vertex = Array.from(path_vertices).pop()

      const next_edge_index = edges.findIndex((edge) => {
        const overlapping =
          (last_vertex.x === edge.start.x && last_vertex.y === edge.start.y) ||
          (last_vertex.x === edge.finish.x && last_vertex.y === edge.finish.y)

        return overlapping
      })

      // Edges don't connect (e.g. a hollow polygon, like a doughnut)
      if (next_edge_index == -1) break

      path_vertices.add(edges[next_edge_index].start)
      path_vertices.add(edges[next_edge_index].finish)

      edges.splice(next_edge_index, 1)
    }

    let path = Array.from(path_vertices)

    // Add starting point again to complete path
    paths.push([...path, path[0]])

    if (edges.length > 0) createPath()
  }

  createPath()

  return { paths, color }
}

/** @param {{ pixel_coords: string[] }} params */
const get_edges = ({ pixel_coords }) => {
  const horizontal_edges = get_horizontal_edges({ pixel_coords })

  const vertical_edges = get_vertical_edges({ horizontal_edges })

  return [...horizontal_edges, ...vertical_edges]
}

/** @param {{ pixel_coords: string[] }} params */
const get_horizontal_edges = ({ pixel_coords }) => {
  /**
   * @param {number} x
   * @param {number} y
   */
  const includes = (x, y) => pixel_coords.includes(coords(x, y))

  const vertices = getPixelVertices({ pixel_coords }).sort(vertexSort('y', 'x'))

  let edges = []

  for (let vertex of vertices) {
    const { x, y } = vertex

    let bottomleftPixel = includes(x - 1, y),
      topLeftPixel = includes(x - 1, y - 1),
      bottomPixel = includes(x, y),
      topPixel = includes(x, y - 1)

    // Vertex is contained or on a vertical edge with the left side exposed to the fearful elements of Moria
    if (
      topPixel &&
      bottomPixel &&
      ((topLeftPixel && bottomleftPixel) || (!topLeftPixel && !bottomleftPixel))
    ) {
      continue
    }

    const lastEdge = edges[edges.length - 1]

    // Is the previous edge to the left of this one?
    const onCurrentPlane =
      lastEdge && lastEdge.finish.x === x - 1 && lastEdge.finish.y === y

    // One of the top/bottom pixels to the left exists, but not both
    const onePixelExists =
      (bottomleftPixel || topLeftPixel) && !(bottomleftPixel && topLeftPixel)

    if (onCurrentPlane && onePixelExists) {
      lastEdge.finish = new Vertex(x, y)
    }
    // Otherwise start fresh if there's a pixel above or below
    else if (topPixel || bottomPixel) {
      edges.push(new Edge(new Vertex(x, y), new Vertex(x, y)))
    }
  }

  return edges
}

/**
 * Transforms an array of coords (['x_y'], ...) to an array of vertices
 * ([Vertex(x, y), ...])
 *
 * @param {{ pixel_coords: string[] }} params
 */
const getPixelVertices = ({ pixel_coords }) => {
  /** @type {Set<string>} */
  let vertex_coords = new Set([])

  for (let [x, y] of pixel_coords.map(split_coord)) {
    // The 4 vertices of a pixel
    vertex_coords.add(coords(x, y))
    vertex_coords.add(coords(x, y + 1))
    vertex_coords.add(coords(x + 1, y))
    vertex_coords.add(coords(x + 1, y + 1))
  }

  return Array.from(vertex_coords).map(
    (coord) => new Vertex(...split_coord(coord))
  )
}

/**
 * Sorts the above array of vertices by their axes (x or y)
 *
 * @param {'x' | 'y'} first_axis
 * @param {'x' | 'y'} second_axis
 * @returns {function(Vertex, Vertex): number}
 */
const vertexSort = (first_axis, second_axis) => (vertex_a, vertex_b) => {
  let first_sort = vertex_a[first_axis] - vertex_b[first_axis]

  if (first_sort !== 0) return first_sort

  return vertex_a[second_axis] - vertex_b[second_axis]
}

/** @param {{ horizontal_edges: Edge[] }} params */
const get_vertical_edges = ({ horizontal_edges }) => {
  const vertices = horizontal_edges
    .map((edge) => [edge.start, edge.finish])
    .flat()
    .sort(vertexSort('x', 'y'))

  let edges = []

  // Scan across the sorted pixels (left to right), joining 2 horizontal edges at a time
  for (let i = 0; i < vertices.length; i += 2) {
    edges.push(new Edge(vertices[i], vertices[i + 1]))
  }

  return edges
}

/* Helper models */

class Edge {
  /**
   * @param {Vertex} start
   * @param {Vertex} finish
   */
  constructor(start, finish) {
    this.start = start
    this.finish = finish

    return this
  }
}

class Vertex {
  /**
   * @param {number} x
   * @param {number} y
   */
  constructor(x, y) {
    this.x = x
    this.y = y

    return this
  }
}

/**
 * @param {number} x
 * @param {number} y Used for unique array items
 */
const coords = (x, y) => `${x}_${y}`

/**
 * @param {string} coord
 * @returns {[number, number]}
 */
const split_coord = (coord) => coord.split('_').map(Number)

/* Rendering */

/**
 * [description]
 *
 * @param {Object} params
 * @param {SvgCanvas} params.ctx [description]
 * @param {{ paths: number[][]; color: string }} params.polygon [description]
 * @param {import('@pogzul/engine').Shape} params.layer [description]
 */
const render = ({ ctx, polygon, layer }) => {
  ctx.beginPath()

  for (let [index, path] of polygon.paths.entries()) {
    // Anti-clockwise for inner paths
    if (index === 1) path.reverse()

    const first_vertex = path.shift()

    ctx.moveTo(
      layer.x + first_vertex.x * config.pixel_size,
      layer.y + first_vertex.y * config.pixel_size
    )

    for (let vertex of path) {
      ctx.lineTo(
        layer.x + vertex.x * config.pixel_size,
        layer.y + vertex.y * config.pixel_size
      )
    }

    ctx.closePath()
  }

  ctx.fillStyle = polygon.color
  ctx.fill()
}
