import Tool from './Tool.js'

import { Pixels, Shape, draw } from '@pogzul/engine'
import { state } from '../stores'

class LineTool extends Tool {
  /** @type {'Line'} */
  name = 'Line'

  shortcut = 'l'

  /** @param {import('./ToolEvent').default} evt */
  onMouseDown(evt) {
    super.onMouseDown(evt)

    this.#draw(evt)
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseUp(evt) {
    const result = draw({
      scene: evt.scene,
      pixels: this.pixels,
      layer_ids: evt.active_layer_ids
    })

    if (result) {
      state.update((old_state) => ({
        ...old_state,
        active_layer_ids: typeof result === 'number' ? [] : [result.id]
      }))
    }

    return super.onMouseUp(evt)
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseMove(evt) {
    if (!evt.is_mouse_down) {
      return false
    }

    this.#draw(evt)

    super.onMouseMove(evt)
  }

  /* Private */

  /**
   * Bresenham line algorithm
   *
   * @param {import('./ToolEvent').default} evt
   */
  #draw({ active_color }) {
    let pixels = new Pixels()

    let delta_x = Math.abs(this.x2 - this.x1),
      delta_y = Math.abs(this.y2 - this.y1)

    let sx = this.x1 < this.x2 ? 1 : -1,
      sy = this.y1 < this.y2 ? 1 : -1

    let error = delta_x - delta_y

    let x = this.x1,
      y = this.y1

    while (true) {
      pixels.set(x, y, active_color)

      if (x === this.x2 && y === this.y2) break

      let err2 = 2 * error

      if (err2 > -delta_y) {
        error -= delta_y
        x += sx
      }

      if (err2 < delta_x) {
        error += delta_x
        y += sy
      }
    }

    this.pixels = pixels
  }
}

export default LineTool
