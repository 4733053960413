import type { CollisionCallback } from './colliding.ts'
import type { Runtime, RuntimeWindow } from '@pogzul/engine'

import { Group, Shape, active } from '@pogzul/engine'
import { collision_shape_shape, collision_shape_group } from './shapes.js'
import { collision_entity_window } from './entities.js'

export function collision_tag_shape(
  tag: Tag,
  shape: Shape,
  callback: CollisionCallback
) {
  const entity_ids = active.scene.components.tags[tag] || []

  for (let id of entity_ids) {
    const entity = active.scene.entities[id]

    if (!(entity instanceof Shape)) continue

    collision_shape_shape(entity, shape, callback)
  }
}

export function collision_tag_group(
  tag: string,
  group: Group,
  callback: CollisionCallback
) {
  const entity_ids = active.scene.components.tags[tag] || []

  for (let id of entity_ids) {
    const entity = active.scene.entities[id]

    if (!(entity instanceof Shape)) continue

    collision_shape_group(entity, group, callback)
  }
}

export function collision_tag_tag(
  this: Runtime,
  tag: Tag,
  target: Tag,
  callback: CollisionCallback
) {
  const entity_ids = active.scene.components.tags[tag] || []

  for (let id of entity_ids) {
    const entity = active.scene.entities[id]

    if (!(entity instanceof Shape)) continue

    const target_entity_ids = active.scene.components.tags[target] || []

    for (let target_id of target_entity_ids) {
      const target_entity = active.scene.entities[target_id]

      if (!(target_entity instanceof Shape) || target_entity.id === entity.id)
        continue

      collision_shape_shape(entity, target_entity, callback)
    }
  }
}

export function collision_tag_window(
  this: Runtime,
  tag: Tag,
  window: RuntimeWindow,
  callback: CollisionCallback
) {
  const entity_ids = active.scene.components.tags[tag] || []

  for (let id of entity_ids) {
    const entity = active.scene.entities[id]

    if (!(entity instanceof Shape)) continue

    collision_entity_window(entity, window, callback)
  }
}

export function collision_tag_scene(
  this: Runtime,
  tag: Tag,
  callback: CollisionCallback
) {
  const entity_ids = active.scene.components.tags[tag] || []

  for (let id of entity_ids) {
    const entity = active.scene.entities[id]

    if (!(entity instanceof Shape)) continue

    for (let id in active.scene.entities) {
      const target_entity = active.scene.entities[id]

      if (!(target_entity instanceof Shape) || target_entity.id === entity.id)
        continue

      collision_shape_shape(entity, target_entity, callback)
    }
  }
}
