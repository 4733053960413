import { class_function_wrapper } from '../internal'
import { Point } from './Point.js'

export class Vector extends Point {
  get magnitude() {
    return Math.sqrt(this.x ** 2 + this.y ** 2)
  }
  get mag() {
    return this.magnitude
  }

  get angle() {
    return Math.atan2(this.y, this.x)
  }
  set angle(angle) {
    this.rotate(angle - this.angle)
  }
  get angleDeg() {
    return (this.angle * 180) / Math.PI
  }

  get isZero() {
    return this.x === 0 && this.y === 0
  }

  /** @param {number} radians */
  rotate(radians) {
    const sin = Math.sin(radians),
      cos = Math.cos(radians)

    const x2 = this.x * cos - this.y * sin,
      y2 = this.x * sin + this.y * cos

    this.x = x2
    this.y = y2

    return this
  }

  normalize() {
    const magnitude = this.magnitude

    this.x = this.x / magnitude
    this.y = this.y / magnitude

    return this
  }
  norm() {
    this.normalize()
  }

  // add, subtract, multiply (dot product)
}

export const vec = class_function_wrapper(Vector)
