import { SUPPORTED_EVENTS } from './config'
import { RuntimeEvent } from '../internal'

/**
 * @param {keyof typeof SUPPORTED_EVENTS} type
 * @param {function(RuntimeEvent):any} listener
 * @this {import('@pogzul/engine').Runtime}
 */
export function addEventListener(type, listener) {
  if (!type || !listener) {
    return this.console.error('TypeError: Not enough arguments')
  } else if (typeof listener !== 'function') {
    return this.console.error('TypeError: The handler must be a function')
  }

  const target = SUPPORTED_EVENTS[type]

  if (target) {
    this.event_listeners[type] ??= {}

    const handler = (evt) => {
      if (!this.is_running) return

      return listener(new RuntimeEvent(evt))
    }

    if (target === 'artboard') {
      this.artboard_target.addEventListener(type, handler, true)
    } else {
      window.addEventListener(type, handler, true)
    }

    this.event_listeners[type][listener.toString()] = handler

    return true
  } else {
    this.console.error(
      `'${type}' event is not supported or doesn't exist. Try one of ${Object.keys(
        SUPPORTED_EVENTS
      ).join(', ')}.`
    )
  }
}

/**
 * @param {keyof typeof SUPPORTED_EVENTS} type
 * @param {function(import('../internal').RuntimeEvent):any} listener
 * @this {import('@pogzul/engine').Runtime}
 */
export function removeEventListener(type, listener) {
  if (!type || !listener) {
    return this.console.error('TypeError: Not enough arguments')
  } else if (!['function', 'string'].includes(typeof listener)) {
    return this.console.error('TypeError: The handler must be a function')
  }

  const target = SUPPORTED_EVENTS[type]

  if (target) {
    const handler = (this.event_listeners[type] || {})[listener.toString()]

    if (handler) {
      if (target === 'artboard') {
        this.artboard_target.removeEventListener(type, handler, true)
      } else {
        window.removeEventListener(type, handler, true)
      }

      delete this.event_listeners[type]?.[listener.toString()]

      return true
    }

    return false
  } else {
    this.console.error(
      `'${type}' event is not supported or doesn't exist. Try one of ${Object.keys(
        SUPPORTED_EVENTS
      ).join(', ')}.`
    )
  }
}
