<script>
  import { tools, artboard_state } from '@pogzul/artboard'
  import { present_users } from '$lib/stores'
  import { update_presence } from '$lib/realtime'
  import { page } from '@inertiajs/svelte'
</script>

<svelte:window
  on:mousemove|passive={(event) => {
    update_presence({
      mouseX: event.clientX - $artboard_state.editor_offsets.x,
      mouseY: event.clientY - $artboard_state.editor_offsets.y,
      active_tool: $artboard_state.active_tool,
      pixels: tools[$artboard_state.active_tool].pixels
    })
  }}
/>

<ul class="absolute top-3 left-3 z-10 bg-base-300/50 px-2 py-1 text-xs space-y-1 max-h-24 overflow-auto">
  <li class="flex space-x-2 items-center">
    <div class="h-2.5 w-2.5 rounded-full" style="background-color: {$page.props.current_user.color};"></div>

    <span class="">{$page.props.current_user.alias} (you)</span>
  </li>

  {#each $present_users as user}
    <li class="flex space-x-2 items-center">
      <div class="h-2.5 w-2.5 rounded-full" style="background-color: {user.color};"></div>

      <span class="">{user.alias}</span>
    </li>
  {/each}
</ul>
