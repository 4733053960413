import { class_function_wrapper } from '../internal'

export class Point {
  constructor(x = 0, y = 0) {
    this.x = x
    this.y = y
  }
}

export const pos = class_function_wrapper(Point)
export { pos as point }
