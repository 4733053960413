export const icons = {
  move: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 100 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H20V20H40V40H60V60H80V80H100V100H60V120H80V160H60V140H40V120H20V140H0V0Z" fill="currentColor" />
    </svg>
  `,

  brush: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M120 0H140V20H120V0Z" />
      <path d="M100 20H120V40H100V20Z" />
      <path d="M80 40H100V60H80V40Z" />
      <path d="M60 60H80V80H60V60Z" />
      <path d="M40 80H60V100H40V80Z" />
      <path d="M0 100H40V140H20V120H0V100Z" />
      <path d="M0 120H20V140H0V120Z" style="fill: var(--tool-color)" />
    </svg>
  `,

  line: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M140 0H120V20H140V0Z" />
      <path d="M120 20H100V40H120V20Z" />
      <path d="M100 40H80V60H100V40Z" />
      <path d="M80 60H60V80H80V60Z" />
      <path d="M60 80H40V100H60V80Z" />
      <path d="M40 100H20V120H40V100Z" />
      <path d="M20 120H0V140H20V120Z" />
    </svg>
  `,

  rectangle: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H140V140H0V0ZM20 20V120H120V20H20Z" style="fill: var(--tool-color)" />
    </svg>
  `,

  fill: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60 0H80V20H100V40H120V60H140V80H120V100H100V120H80V140H60V120H40V100H20V80H40V40H60V0Z" fill="black"/>
      <path d="M40 0H60V40H40V80H20V120H0V40H20V20H40V0Z" fill="#808080"/>
    </svg>

  `,

  text: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H140V40H120V20H80V120H100V140H40V120H60V20H20V40H0V0Z" fill="currentColor" />
    </svg>
  `,

  undo: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 180 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M120 0H140V20H120H100H80V0H100H120ZM60 40V20H80V40H60ZM40 60V40H60V60H40ZM0 80V100H20H40H60H80V80H60H40V60H20V40V20H0V40V60V80ZM160 40H140V20H160V40ZM160 40H180V60H160V40Z" />
    </svg>
  `,

  redo: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 180 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M60 0H40V20H60H80H100V0H80H60ZM120 40V20H100V40H120ZM140 60V40H120V60H140ZM180 80V100H160H140H120H100V80H120H140V60H160V40V20H180V40V60V80ZM20 40H40V20H20V40ZM20 40H0V60H20V40Z" />
    </svg>
  `,

  folder: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 120" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20H20V100H0V20Z" />
      <path d="M20 0H80V20H20V0Z" />
      <path d="M20 100H120V120H20V100Z" />
      <path d="M80 20H120V40H80V20Z" />
      <path d="M120 40H140V100H120V40Z" />
    </svg>
  `,

  save: (klass) => `
    <svg class="flex-shrink-0 ${klass}" fill="currentColor" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M60 40H40V60H60H80V40H60Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M80 80H40V100H80H100V80H80Z" />
      <path d="M100 20H120V40H100V20Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H20H40H60H100V20H60H40H20V40V60V80V100V120H120V40H140V140H120H20H0V120V100V80V60V40V0Z" />
    </svg>
  `,

  eraser: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30 100.124H50V120.124H30V100.124Z" fill="#CCC"/>
      <path d="M10 100.124H30V120.124H10V100.124Z" fill="#CCC"/>
      <path d="M10 80.124H30V100.124H10V80.124Z" fill="currentColor"/>
      <path d="M50 100.124H70V120.124H50V100.124Z" fill="#CCC"/>
      <path d="M50 80.124H70V100.124H50V80.124Z" fill="currentColor"/>
      <path d="M70 80.124H90V100.124H70V80.124Z" fill="currentColor"/>
      <path d="M30 80.124H50V100.124H30V80.124Z" fill="currentColor"/>
      <path d="M70 60.124H90V80.124H70V60.124Z" fill="currentColor"/>
      <path d="M50 60.124H70V80.124H50V60.124Z" fill="currentColor"/>
      <path d="M50 40.124H70V60.124H50V40.124Z" fill="currentColor"/>
      <path d="M90 60.124H110V80.124H90V60.124Z" fill="currentColor"/>
      <path d="M90 40.124H110V60.124H90V40.124Z" fill="currentColor"/>
      <path d="M30 60.124H50V80.124H30V60.124Z" fill="currentColor"/>
      <path d="M110 40.124H130V60.124H110V40.124Z" fill="currentColor"/>
      <path d="M70 40.124H90V60.124H70V40.124Z" fill="currentColor"/>
      <path d="M110 20.124H130V40.124H110V20.124Z" fill="currentColor"/>
      <path d="M90 20.124H110V40.124H90V20.124Z" fill="currentColor"/>
      <path d="M70 20.124H90V40.124H70V20.124Z" fill="currentColor"/>
    </svg>
  `,

  download: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 80H20V120H0V80Z" />
      <path d="M20 120H120V140H20V120Z" />
      <path d="M20 40H40V60H20V40Z" />
      <path d="M60 0H80V60H100V80H80V100H60V80H40V60H60V0Z" />
      <path d="M100 40H120V60H100V40Z" />
      <path d="M120 80H140V120H120V80Z" />
    </svg>
  `,

  terminal: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 180 140" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0H160V20H20V0Z" />
      <path d="M20 120H160V140H20V120Z" />
      <path d="M40 40H60V60H40V40Z" />
      <path d="M40 80H60V100H40V80Z" />
      <path d="M60 60H80V80H60V60Z" />
      <path d="M100 80H140V100H100V80Z" />
      <path d="M160 20H180V120H160V20Z" />
      <path d="M0 20H20V120H0V20Z" />
    </svg>
  `,

  sun: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 160 160" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M60 40H100V60H120V100H100V120H60V100H40V60H60V40Z" />
      <path d="M40 140H60V160H40V140Z" />
      <path d="M40 0H60V20H40V0Z" />
      <path d="M100 140H120V160H100V140Z" />
      <path d="M100 0H120V20H100V0Z" />
      <path d="M140 40H160V60H140V40Z" />
      <path d="M140 100H160V120H140V100Z" />
      <path d="M0 40H20V60H0V40Z" />
      <path d="M0 100H20V120H0V100Z" />
    </svg>
  `,

  moon: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 100 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H60V20H80V40H100V120H80V140H60V160H0V140H40V120H60V40H40V20H0V0Z" fill="currentColor"/>
    </svg>
  `,

  'chevron-up': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 100 60" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 40H20V60H0V40Z" />
      <path d="M20 20H40V40H20V20Z" />
      <path d="M40 0H60V20H40V0Z" />
      <path d="M60 20H80V40H60V20Z" />
      <path d="M80 40H100V60H80V40Z" />
    </svg>
  `,

  'chevron-right': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 60 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0L20 20L-8.74228e-07 20L0 -8.74228e-07L20 0Z" />
      <path d="M40 20L40 40L20 40L20 20L40 20Z" />
      <path d="M60 40L60 60L40 60L40 40L60 40Z" />
      <path d="M40 60L40 80L20 80L20 60L40 60Z" />
      <path d="M20 80L20 100L-8.74228e-07 100L0 80L20 80Z" />
    </svg>
  `,

  plus: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M80 60V20H60V60H20V80H60V120H80V80H120V60H80Z" fill="currentColor"/>
    </svg>
  `,

  'eye-slash': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40 100H100V120H40V100Z" fill="#808080"/>
      <path d="M40 20H100V40H40V20Z" fill="#808080"/>
      <path d="M20 40H40V60H20V40Z" fill="#808080"/>
      <path d="M20 80H40V100H20V80Z" fill="#808080"/>
      <path d="M0 60H20V80H0V60Z" fill="#808080"/>
      <path d="M100 80H120V100H100V80Z" fill="#808080"/>
      <path d="M100 40H120V60H100V40Z" fill="#808080"/>
      <path d="M120 60H140V80H120V60Z" fill="#808080"/>
      <path d="M0 120H20V140H0V120Z" fill="#C4C4C4"/>
      <path d="M20 100H40V120H20V100Z" fill="#C4C4C4"/>
      <path d="M40 80H60V100H40V80Z" fill="#C4C4C4"/>
      <path d="M60 60H80V80H60V60Z" fill="#C4C4C4"/>
      <path d="M80 40H100V60H80V40Z" fill="#C4C4C4"/>
      <path d="M100 20H120V40H100V20Z" fill="#C4C4C4"/>
      <path d="M120 0H140V20H120V0Z" fill="#C4C4C4"/>
    </svg>
  `,

  eyedropper: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 120H20V140H0V120Z" fill="#808080"/>
      <path d="M20 80H40V100H60V120H40V140H20V120H0V100H20V80Z" fill="currentColor"/>
      <path d="M40 60H60V80H40V60Z" fill="currentColor"/>
      <path d="M80 0H140V60H100V80H80V60H60V40H80V0Z" fill="currentColor"/>
      <path d="M60 80H80V100H60V80Z" fill="currentColor"/>
    </svg>
  `,

  'question-mark': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60 60H100V80H80V100H60V60Z" fill="currentColor"/>
      <path d="M40 0H100V20H40V0Z" fill="currentColor"/>
      <path d="M100 20H120V60H100V20Z" fill="currentColor"/>
      <path d="M60 120H80V140H60V120Z" fill="currentColor"/>
    </svg>
  `,

  share: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M80 0H60V20H40V40H20V60H40V40H60V100H80V40H100V60H120V40H100V20H80V0ZM20 80H0V120H20V140H120V120H140V80H120V120H20V80Z" fill="currentColor"/>
    </svg>
  `,

  home: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60 0H80V20H100V40H120V60H140V80H120V140H100V120H40V140H20V80H0V60H20V40H40V20H60V0ZM60 40V60H40V100H100V60H80V40H60Z" fill="currentColor"/>
    </svg>
  `,

  people: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40 130H60V170H40V130Z" fill="currentColor"/>
      <path d="M40 70H60V110H40V70Z" fill="currentColor"/>
      <path d="M60 110H100V130H60V110Z" fill="currentColor"/>
      <path d="M60 50H100V70H60V50Z" fill="currentColor"/>
      <path d="M100 70H120V110H100V70Z" fill="currentColor"/>
      <path d="M100 130H120V170H100V130Z" fill="currentColor"/>
      <path d="M80 110H100V130H80V110Z" fill="currentColor"/>
      <path d="M80 50H100V70H80V50Z" fill="currentColor"/>
      <path d="M100 90H140V110H100V90Z" fill="currentColor"/>
      <path d="M100 30H140V50H100V30Z" fill="currentColor"/>
      <path d="M140 50H160V90H140V50Z" fill="currentColor"/>
      <path d="M140 110H160V150H140V110Z" fill="currentColor"/>
    </svg>
  `,

  command: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60 40H100V60H60V40Z" fill="currentColor"/>
      <path d="M60 100H100V120H60V100Z" fill="currentColor"/>
      <path d="M100 60H120V100H100V60Z" fill="currentColor"/>
      <path d="M40 60H60V100H40V60Z" fill="currentColor"/>
      <path d="M0 100H60V160H0V100ZM20 120V140H40V120H20Z" fill="currentColor"/>
      <path d="M0 0H60V60H0V0ZM20 20V40H40V20H20Z" fill="currentColor"/>
      <path d="M100 100H160V160H100V100ZM120 120V140H140V120H120Z" fill="currentColor"/>
      <path d="M100 0H160V60H100V0ZM120 20V40H140V20H120Z" fill="currentColor"/>
    </svg>
  `,

  fork: (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H60V60H0V0ZM20 20V40H40V20H20Z" fill="currentColor"/>
      <path d="M80 0H140V60H80V0ZM100 20V40H120V20H100Z" fill="currentColor"/>
      <path d="M20 60H40V100H80V80H100V60H120V100H100V120H40V160H20V60Z" fill="currentColor"/>
    </svg>
  `,

  'horizontal-align-left': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H140V20H0V0Z" fill="currentColor"/>
      <path d="M0 80H120V100H0V80Z" fill="currentColor"/>
      <path d="M0 40H100V60H0V40Z" fill="currentColor"/>
      <path d="M0 120H80V140H0V120Z" fill="currentColor"/>
    </svg>
  `,

  'horizontal-align-center': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H140V20H0V0Z" fill="currentColor"/>
      <path d="M0 80H140V100H0V80Z" fill="currentColor"/>
      <path d="M20 40H120V60H20V40Z" fill="currentColor"/>
      <path d="M40 120H100V140H40V120Z" fill="currentColor"/>
    </svg>
  `,

  'horizontal-align-right': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H140V20H0V0Z" fill="currentColor"/>
      <path d="M20 80H140V100H20V80Z" fill="currentColor"/>
      <path d="M40 40H140V60H40V40Z" fill="currentColor"/>
      <path d="M60 120H140V140H60V120Z" fill="currentColor"/>
    </svg>
  `,

  'vertical-align-top': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H140V20H0V0Z" fill="currentColor"/>
      <path d="M20 80H40V100H20V80Z" fill="currentColor"/>
      <path d="M60 40H80V60H100V80H80V140H60V80H40V60H60V40Z" fill="currentColor"/>
      <path d="M100 80H120V100H100V80Z" fill="currentColor"/>
    </svg>
  `,

  'vertical-align-middle': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 60H140V80H0V60Z" fill="currentColor"/>
      <path d="M40 0H60V20H40V0Z" fill="currentColor"/>
      <path d="M40 120H60V140H40V120Z" fill="currentColor"/>
      <path d="M60 100H80V120H60V100Z" fill="currentColor"/>
      <path d="M60 20H80V40H60V20Z" fill="currentColor"/>
      <path d="M80 0H100V20H80V0Z" fill="currentColor"/>
      <path d="M80 120H100V140H80V120Z" fill="currentColor"/>
    </svg>
  `,

  'vertical-align-bottom': (klass) => `
    <svg class="flex-shrink-0 ${klass}" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 120H140V140H0V120Z" fill="currentColor"/>
      <path d="M20 40H40V60H20V40Z" fill="currentColor"/>
      <path d="M60 0H80V60H100V80H80V100H60V80H40V60H60V0Z" fill="currentColor"/>
      <path d="M100 40H120V60H100V40Z" fill="currentColor"/>
    </svg>
  `
}
