<script lang="ts">
  import { Text, config, parse_color, set_color } from '@pogzul/engine'
  import { Icon } from '../..'
  import { artboard_state } from '../../stores'

  let { scene, render } = $props<{ scene: import('@pogzul/engine').Scene, render: () => number }>()

  const active_layer_id = $derived($artboard_state.active_layer_ids.length === 1 && $artboard_state.active_layer_ids[0])

  const layer = $derived(scene.entities[active_layer_id])

  const hex_color = $derived($artboard_state.active_color.reduce((hex, int) => hex + int.toString(16).padStart(2, '0'), '#'))

  const HORIZONTAL_ALIGNMENTS: Text['justify'][] = ['left', 'center', 'right']

  const VERTICAL_ALIGNMENTS: Text['align'][] = ['top', 'middle', 'bottom']
</script>

<div class="max-h-full overflow-scroll p-2 w-[9rem] bg-base-300/50 dark:bg-base-100/90 flex flex-col justify-between">
  <div class="space-y-3">
    {#if layer}
      <div>
        <label class="label pt-0 pb-0.5 text-sm" for="shape-name">
          Name
        </label>
        <input
          id="shape-name"
          type="text"
          class="input input-sm input-bordered min-w-0 w-full"
          bind:value={layer.name}
        />
      </div>

      <div class="flex items-center justify-between">
        <label class="label pt-0 pb-0.5 text-sm" for="shape-is_hidden">
          Hidden
        </label>
        <input
          id="shape-is_hidden"
          type="checkbox"
          class="checkbox dark:checkbox-success border-2"
          bind:checked={layer.is_hidden}
        />
      </div>
    {:else}
      <p class="text-center text-xs opacity-60 mt-2">
        Select a layer.
      </p>
    {/if}

    {#if layer instanceof Text}
      <div>
        <h4 class="font-sans font-semibold text-sm">Text</h4>

        <div class="flex items-center justify-between mt-2">
          <input
            id="shape-font_size"
            type="number"
            class="input input-sm input-bordered min-w-0 max-w-[3rem]"
            bind:value={layer.size}
          />
        </div>

        <div class="mt-2 flex">
          {#each HORIZONTAL_ALIGNMENTS as align}
            <button
              class="btn btn-sm btn-square btn-ghost hover:z-10 { layer.justify === align ? '!bg-base-content/15' : 'text-base-content/45' }"
              on:click={() => {
                layer.justify = align

                render()
              }}
            >
              <Icon name="horizontal-align-{align}" class="h-4 w-4" />
            </button>
          {/each}
        </div>

        <div class="flex mt-1.5">
          {#each VERTICAL_ALIGNMENTS as align}
            <button
              class="btn btn-sm btn-square btn-ghost hover:z-10 { layer.align === align ? '!bg-base-content/15' : 'text-base-content/45' }"
              on:click={() => {
                layer.align = align

                render()
              }}
            >
              <Icon name="vertical-align-{align}" class="h-4 w-4" />
            </button>
          {/each}
        </div>
      </div>
    {/if}
<!--
    <div class="dropdown dropdown-hover dropdown-right">
      <div class="flex items-center justify-between">
        <label class="label pt-0 pb-0.5" for="shape-is_contained">
          <span class="label-text">Contained</span>
        </label>
        <input
          id="shape-is_contained"
          type="checkbox"
          class="checkbox dark:checkbox-success border-2"
          bind:checked={scene.entities[active_layer_id].is_contained}
        />
      </div>

      <div class="dropdown-content">
        <p>Keeps the layer<br /> inside the window.</p>
      </div>
    </div> -->

<!--       <div class="dropdown dropdown-hover dropdown-right">
      <div class="flex items-center justify-between">
        <label class="label pt-0 pb-0.5" for="shape-is_kinematic">
          <span class="label-text">Kinematic</span>
        </label>
        <input
          id="shape-is_kinematic"
          type="checkbox"
          class="checkbox dark:checkbox-success border-2"
          bind:checked={scene.entities[active_layer_id].is_kinematic}
        />
      </div>

      <div class="dropdown-content">
        <p>Gives the layer velocity<br /> and acceleration.</p>
      </div>
    </div> -->
  </div>

  <div class="space-y-2">
    <div class="flex items-center justify-between">
      <h4 class="font-sans font-semibold text-sm">Fill</h4>

      <div class="relative w-7 h-7 flex-shrink-0">
        <input
          type="color"
          class="absolute inset-0 block w-full h-full bg-transparent appearance-none focus:outline-none cursor-pointer"
          value={hex_color}
          on:change={
            ({ target }) => $artboard_state.active_color = parse_color(target.value)
          }
          on:click={ (evt) => {
            if (evt.shiftKey && $artboard_state.active_layer_ids.length) {
              evt.preventDefault()

              for (let id of $artboard_state.active_layer_ids) {
                set_color(scene.entities[id], $artboard_state.active_color)
              }

              scene = scene
            }
          }}
        />
      </div>
    </div>

    <ul class="grid grid-cols-5 gap-1">
      {#each Object.values(config.default_colors) as color }
        <li>
          <button
            on:click={ (evt) => {
              $artboard_state.active_color = parse_color(color)

              if (evt.shiftKey) {
                for (let id of $artboard_state.active_layer_ids) {
                  set_color(scene.entities[id], $artboard_state.active_color)
                }

                scene = scene
              }
            }}
            class="h-[22px] w-[22px] flex-shrink-0 flex items-center justify-center"
            style="background-color: { color };"
          />
        </li>
      {/each}
    </ul>
  </div>
</div>

<style lang="postcss">
  .dropdown-content {
    @apply z-[1] shadow bg-base-100/90 p-1.5 px-2.5 ml-4 !-translate-y-1/2 !top-1/2;
  }

  .dropdown-content p {
    @apply text-xs whitespace-nowrap;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input {
    @apply !bg-opacity-50;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
  }
</style>
