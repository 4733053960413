import type { CollisionCallback } from './colliding'

import { Group, active, Shape } from '@pogzul/engine'
import { is_not_colliding_entity_entity } from './entities'
import { collision_shape_shape, collision_shape_group } from './shapes'

export function collision_group_shape(
  group: Group,
  shape: Shape,
  callback: CollisionCallback
) {
  if (is_not_colliding_entity_entity(shape, group)) return

  for (let id of group.layer_ids) {
    const entity = active.scene.entities[id]

    if (entity instanceof Group)
      return collision_group_shape(entity, shape, callback)

    collision_shape_shape(entity, shape, callback)
  }
}

export function collision_group_group(
  group: Group,
  target: Group,
  callback: CollisionCallback
) {
  if (is_not_colliding_entity_entity(group, target)) return

  for (let shape_id of group.layer_ids) {
    const shape = active.scene.entities[shape_id]

    if (shape instanceof Group)
      return collision_group_group(group, shape, callback)

    for (let target_id of target.layer_ids) {
      const target_shape = active.scene.entities[target_id]
      if (target_id === shape_id || target_shape instanceof Group) continue

      collision_shape_shape(shape, target_shape, callback)
    }
  }
}

export function collision_group_tag(
  group: Group,
  tag: string,
  callback: CollisionCallback
) {
  const entity_ids = active.scene.components.tags[tag] || []

  for (let id of entity_ids) {
    const entity = active.scene.entities[id]

    if (!(entity instanceof Shape)) continue

    collision_group_shape(group, entity, callback)
  }
}

export function collision_group_scene(
  group: Group,
  callback: CollisionCallback
) {
  for (let shape_id of group.layer_ids) {
    const shape = active.scene.entities[shape_id]

    if (shape instanceof Group)
      return collision_group_group(group, shape, callback)

    for (let id in active.scene.entities) {
      const entity = active.scene.entities[id]
      if (
        entity.id === group.id ||
        entity.id === shape.id ||
        entity instanceof Group
      )
        continue // Don't check same-layer collisions

      collision_shape_shape(shape, entity, callback)
    }
  }
}
