import { config, rgb_array_to_string } from '@pogzul/engine'
/*
  renderPixels

  Renders a Pixels object to the canvas
*/

const ERASE_COLOR = new Uint8Array([0, 0, 0, 128 /* 50% opaque */])

/**
 * @param {object} params
 * @param {import('@pogzul/engine').Pixels} params.pixels
 * @param {import('../../webgl').Context} params.ctx
 * @param {boolean} [params.is_tool]
 * @param {number} [params.alpha]
 */
export const renderPixels = ({ pixels, ctx, is_tool = false, alpha = 1 }) => {
  for (let [x, row] of pixels) {
    for (let [y, pixel_color] of row) {
      let color = pixel_color
        ? new Uint8Array([...pixel_color, alpha * 255 /* Add alpha channel */])
        : is_tool
        ? ERASE_COLOR
        : new Uint8Array([0, 0, 0, 0])

      ctx.fillRect({
        x: x * config.pixel_size,
        y: y * config.pixel_size,
        width: config.pixel_size,
        height: config.pixel_size,
        color
      })
    }
  }
}

export const renderPixelsCanvas2D = ({ ctx, pixels }) => {
  for (let [x, row] of pixels) {
    for (let [y, color] of row) {
      ctx.fillStyle = rgb_array_to_string(color)

      ctx.fillRect(
        x * config.pixel_size,
        y * config.pixel_size,
        config.pixel_size,
        config.pixel_size
      )
    }
  }
}
