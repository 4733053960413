import { Scene } from '../models'

class Active {
  scene = $state(new Scene())
}

export const active = new Active()

if (typeof $inspect !== 'undefined') $inspect(active.scene)

/** @param {Scene} next_scene */
export const set_scene = (next_scene) => (active.scene = next_scene)
