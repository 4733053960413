export const VERTEX_FORMAT = [
  { name: 'a_pos', size: 2 },
  { name: 'a_uv', size: 2 },
  { name: 'a_color', size: 4 }
]

export const STRIDE = VERTEX_FORMAT.reduce(
  (sum, format) => sum + format.size,
  0
)
