<script>
  import { meta_tags } from '$lib/components'

  export let image_src

  meta_tags({ image: image_src })
</script>

<div
  class="inset-0 fixed min-h-screen opacity-20 dark:opacity-[.15] pointer-events-none z-[-1]"
  style="background: var(--checkered-background) repeat;"
></div>

<div class="w-full py-16 md:pt-48 md:pb-32">
  <main class="mx-auto max-w-7xl px-4 text-center">
    <h2 class="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl leading-relaxed">
      The programmable artboard
    </h2>

    <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-12">
      <a href="/projects/new" class="btn btn-lg border-purple-400 text-xl font-medium text-white bg-purple-600 hover:bg-purple-700 border-[3px] hover:border-purple-500">Make good art</a>
    </div>
  </main>
</div>


<div class="space-y-3">
  <p class="text-center text-lg">Draw. Code. Play.</p>

  <div class="image-fade h-auto shadow bg-base-200/50 -mx-3 md:max-w-6xl md:mx-auto w-screen">
    <img src="/space_invaders_light.png" alt="Space Invaders" class="w-full dark:hidden" />
    <img src="/space_invaders_dark.png" alt="Space Invaders" class="w-full hidden dark:inline" />
  </div>
</div>

<style>
  .image-fade {
    -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  }
</style>