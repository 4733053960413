export class Canvas {
  /** @type {HTMLCanvasElement} */
  element =
    typeof window !== 'undefined'
      ? document.createElement('canvas')
      : fake_canvas

  /** @type {Pick<HTMLImageElement, 'src'>} */
  image = typeof window === 'undefined' ? { src: '' } : new Image()

  ctx = this.element.getContext('2d')

  constructor() {
    this.width = 1
    this.height = 1

    this.renderImage()
  }

  /** @param {number} val */
  set width(val) {
    this.element.width = val
  }

  /** @param {number} val */
  set height(val) {
    this.element.height = val
  }

  /** @param {function(CanvasRenderingContext2D): void} callback */
  render(callback) {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.element.width, this.element.height)

      callback(this.ctx)

      this.renderImage()
    }
  }

  renderImage() {
    return (this.image.src = this.element.toDataURL())
  }
}

const TRANSPARENT_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

/**
 * Canvas wrapper for a nicer API for layers
 *
 * @type {HTMLCanvasElement} - Lol
 */
const fake_canvas = {
  // @ts-ignore
  getContext() {
    return {
      clearRect() {},
      setTransform() {},
      fillRect() {},
      measureText() {
        return {}
      },
      fillText() {}
    }
  },
  toDataURL() {
    return TRANSPARENT_PIXEL
  }
}

export default Canvas
