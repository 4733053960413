import { entity_name_for, update_shape } from '@pogzul/engine'
import Pixels from './Pixels'

import { Entity } from '../../Entity.js'

export class Shape extends Entity {
  /** @type {string} */
  name

  /** @type {Pixels} */
  pixels
  /**
   * @param {Partial<
   *   Omit<Shape, 'pixels'> & {
   *     pixels: import('./Pixels').PixelsObject
   *   }
   * >} opts
   */
  constructor(opts = {}) {
    super(opts)
    this.pixels = new Pixels(opts.pixels || {})
    this.name ??= entity_name_for('Shape')
    update_shape(this)
  }
}

/** @param {Partial<Shape>} props */
// export const create_shape = (props) => {
//   const shape = {
//     ...props,
//     pixels: new Pixels(props.pixels || {}),
//     name: props.name || entity_name_for('Shape')
//   }

//   update_shape(shape)

//   return shape
// }

// export default Shape
