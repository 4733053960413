<script context="module">
  import BaseLayout from '../layouts/BaseLayout.svelte'

  export const layout = [BaseLayout]
</script>

<script>
  import { onMount } from 'svelte'
  import { Artboard, Icon, artboard_state } from '@pogzul/artboard'
  import { IDE } from '@pogzul/IDE'
  import { Project, Runtime, is_running, active } from '@pogzul/engine'

  import { project, present_users, persist_store, preferences, has_run, world, synced_store } from '$lib/stores'
  import { sync, initialize_document, undo_manager } from '$lib/realtime'
  import { PresentUsers, ThemeSwitcher, ActionBar, ShortcutsModal, ToggleIDE, SaveModal, ForkProject } from '$lib/components'

  let data
  export { data as project }

  export let current_user

  export let is_project_owner

  let editable = is_project_owner || data.public_access === 'write'

  const doc = initialize_document(data)
  const map = doc.getMap()
  $project = Project.from(doc, data.id)



  // const store = synced_store(map)

  // const { ytext_ids } = $store

  // console.log("HEY", $ytext_ids = [12, 12341])



  const ytext = map.get('ytexts').get(String(map.get('ytext_ids')[0]))


  if (!$artboard_state.active_scene_ids.length || !$project.scene_ids.includes($artboard_state.active_scene_ids[0])) $artboard_state.active_scene_ids = [$project.scene_ids[0]]

  active.scene = $project.scenes[$artboard_state.active_scene_ids[0]]

  const { codemirror_extension } = sync(data.id, doc, editable)

  /** @type {() => number} */
  let render

  /** @type {import('@pogzul/IDE').Terminal} */
  let terminal

  onMount(() => {
    const runtime = new Runtime({
      terminal,
      render: () => render(),
      document: project,
      ytext,
      state: artboard_state,
      artboard_target: document.getElementById("artboard-target"),
    })

    return () => runtime.stop()
  })

  /** @type {HTMLDivElement} */
  let screen_size_notice
</script>

<div class="sm:hidden absolute z-[1000] top-3 inset-x-3" bind:this={screen_size_notice}>
  <button on:click={() => screen_size_notice.remove()} class="text-left bg-base-100 dark:bg-base-300 border-base-200 border-[3px] shadow-xl mx-auto max-w-sm p-3 flex items-center space-x-3">
    <svg class="stroke-info shrink-0 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>

    <p>Pogzul works best on a desktop, for now.</p>
  </button>
</div>

<PresentUsers />

<div class="h-full flex" id="editor">
  <div class="relative flex-1">
    <Artboard
      bind:render
      {persist_store}
      {project}
      {present_users}
      {editable}
    >
      <ActionBar slot="actions" {undo_manager} />
    </Artboard>

    <div class="flex space-x-2 absolute bottom-3 right-3 z-10">
      {#if editable}
        <ShortcutsModal />
      {/if}

      <ThemeSwitcher />
    </div>

    <div class="absolute flex space-x-2 top-3 right-3 z-10">
      {#if editable && !current_user.activated_at}
        <SaveModal />
      {:else}
        <ForkProject project_id={data.id} />
      {/if}

      <ToggleIDE />
    </div>
  </div>

  <div class="flex-1 z-10 overflow-hidden transition-[max-width] duration-300 { preferences.value.ide_visible ? 'max-w-2xl' : 'max-w-0' }">
    <IDE
      extensions={[codemirror_extension]}
      {ytext}
      {editable}
      bind:terminal
    >
      <div slot="actions" class="absolute bottom-0 right-0 p-2 z-10">
        {#if $has_run && !$is_running}
          <button class="btn btn-warning btn-outline bg-base-100" on:click={() => $has_run = false}>
            Reset
          </button>
        {/if}

        <button class="btn { $is_running ? 'btn-error btn-outline bg-base-100' : '' }" on:click={() => $is_running = !$is_running}>
          {$is_running ? 'Stop' : 'Run' }
        </button>
      </div>
    </IDE>
  </div>
</div>
