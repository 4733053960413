import { pick } from '@pogzul/engine'
import { Scene } from './Scene.js'

export class Project {
  /** @type {string} */
  id

  /** @type {string} */
  name

  /** @type {Record<number, Scene>} */
  scenes

  /** @type {number[]} */
  scene_ids

  /** @param {Project} opts */
  constructor(opts) {
    Object.assign(this, pick(opts, 'id', 'name', 'scenes', 'scene_ids'))
  }

  /**
   * @param {import('yjs').Doc} doc
   * @param {string} id
   */
  static from(doc, id) {
    const map = doc.getMap()

    const scenes = parse_scenes(map)

    return new Project({
      id,
      name: map.get('name'),
      scene_ids: map.get('scene_ids'),
      scenes
    })
  }
}

/** @param {import('yjs').Map<any>} map */
const parse_scenes = (map) =>
  Object.fromEntries(
    Object.entries(map.get('scenes')).map(([id, scene]) => [
      Number(id),
      new Scene(scene)
    ])
  )
