import { config, Group, Shape, Text } from '@pogzul/engine'

/**
 * @param {object} opts
 * @param {Shape | Group | Text} opts.layer
 * @param {import('../../webgl').Context} opts.ctx - Our WebGL rendering context
 * @param {ArtboardState} opts.state
 * @param {boolean} opts.is_hovering
 * @param {boolean} opts.is_active
 * @param {number} opts.alpha
 */
export const renderLayer = ({ layer, ...params }) => {
  if ('pixels' in layer) return renderShape({ shape: layer, ...params })
  if ('font' in layer) return renderText({ text: layer, ...params })

  // return renderShape({ layer, ...params })
}

export const OUTLINE_COLOR = new Uint8Array([56, 189, 248, 255]),
  HOVER_PADDING = 2

/* Shape layers */

/**
 * @param {object} opts
 * @param {import('@pogzul/engine').Shape} opts.shape
 * @param {import('../../webgl').Context} opts.ctx - Our WebGL rendering context
 * @param {ArtboardState} opts.state
 * @param {boolean} opts.is_hovering
 * @param {boolean} opts.is_active
 * @param {number} opts.alpha
 */
const renderShape = ({
  shape,
  ctx,
  state,
  is_hovering,
  is_active,
  alpha = shape.opacity
}) => {
  /** Or hovering over a different layer? */
  const is_hovering_other = !is_hovering && state.hovering_layer_ids.length > 0

  /*
    De-emphasize layer for clarity
  */
  // Hovering over another layer
  if (is_hovering_other) {
    alpha = 0.7
  }

  if (shape.is_hidden) {
    if (!is_active) return

    alpha = 0.7
  }

  if (is_hovering && !is_active) {
    renderShapeOutline({ shape, ctx })
  }

  ctx.drawShape({ shape, alpha })
}

const renderShapeOutline = ({ shape, ctx }) => {
  const scale = 1
  for (let [x, row] of shape.pixels) {
    for (let [y, color] of row) {
      if (!color) continue

      ctx.fillRect({
        x: shape.x + x * config.pixel_size * scale - HOVER_PADDING,
        y: shape.y + y * config.pixel_size * scale - HOVER_PADDING,
        width: config.pixel_size * scale + 2 * HOVER_PADDING,
        height: config.pixel_size * scale + 2 * HOVER_PADDING,
        color: OUTLINE_COLOR
      })
    }
  }
}

/* Text layers */

const renderText = ({
  text,
  ctx,
  state,
  is_hovering,
  is_active,
  alpha = text.opacity
}) => {
  /** Or hovering over a different layer? */
  const is_hovering_other = !is_hovering && state.hovering_layer_ids.length > 0

  /*
    De-emphasize layer for clarity
  */
  // Hovering over another layer
  if (is_hovering_other) {
    alpha = 0.7
  }

  if (text.is_hidden) {
    if (!is_active) return

    alpha = 0.7
  }

  if (is_hovering && !is_active) {
    ctx.strokeText({ text, color: OUTLINE_COLOR })
  }

  ctx.drawText({ text, alpha })
}

// const renderTextOutline = ({ layer, ctx }) => {
//   const font = fonts[layer.fontFamily]

//   if (!font) return

//   return ctx.strokeText({
//     layer,
//     font,
//     color: OUTLINE_COLOR,
//     lineWidth: BOUNDARY_WIDTH
//   })
// }
