<script>
  import { page, Link } from '@inertiajs/svelte'
  import { ThemeSwitcher } from '$lib/components'
</script>

<div class="py-3 md:pl-2 flex justify-between items-center">
  <span class="text-xl font-display"><Link href="/">Pogzul</Link></span>

  <div class="flex items-center space-x-3">
    <ul class="flex space-x-4">
      <li><Link href="/blog">Blog</Link></li>

      {#if $page.props.current_user}
        <li><Link href="/projects">Projects</Link></li>
      {/if}
    </ul>

    <ThemeSwitcher />
  </div>
</div>