import { Context } from './Context'

/**
 * @param {WebGLRenderingContext} gl
 * @returns {Context}
 */
export const initializeWebGL = (gl) => {
  gl.enable(gl.BLEND)
  gl.blendFuncSeparate(
    gl.SRC_ALPHA,
    gl.ONE_MINUS_SRC_ALPHA,
    gl.ONE,
    gl.ONE_MINUS_SRC_ALPHA
  )

  return new Context(gl)
}
