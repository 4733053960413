<script context="module">
  import { writable } from 'svelte/store';

  const DEFAULT_TAGS = {
    site: 'Pogzul',
    title: 'The programmable artboard',
    description: 'A whimsical place to make and share good art.',
    image: undefined
  }

  let tags = writable(DEFAULT_TAGS)

  let is_meta_tags_called = writable(false)

  export const meta_tags = (options = {}) => {
    tags.set({ ...DEFAULT_TAGS, ...options })

    is_meta_tags_called.set(true)
  }
</script>

<script>
  import { onMount } from 'svelte'
  import { page, router } from '@inertiajs/svelte'

  $: url = $page.props.config.app_url + $page.url
  $: page_title = `${$tags.site} • ${$tags.title}`

  onMount(() => {
    router.on('navigate', () => {
      if (!$is_meta_tags_called) meta_tags()

      $is_meta_tags_called = false
    })
  })
</script>

<svelte:head>
  <title>{page_title}</title>
  <meta name="description" content={$tags.description} />

  <!--  Essential -->
  <meta property="og:title" content={page_title} />
  <meta property="og:type" content="website" />
  <meta property="og:image" content={$tags.image || $page.props.default_image} />
  <meta property="og:url" content={url} />

  {#if $tags.image}
    <meta name="twitter:card" content="summary_large_image" />
  {/if}

  <!--  Recommended -->
  <meta property="og:description" content={$tags.description} />
  <meta property="og:site_name" content={$tags.site} />
  <meta name="twitter:image:alt" content={$tags.title} />

  <!--  Required for Analytics -->
  <meta name="twitter:site" content="@pogzul" />
</svelte:head>