<script lang="ts">
  import { onMount } from 'svelte'

  import { EditorState, Compartment } from '@codemirror/state'
  import { EditorView, keymap } from '@codemirror/view'

  import { extensions as codemirror_extensions } from '../config/codemirror.js'
  import { is_running } from '@pogzul/engine'

  type Props = {
    extensions: any[]
    text: string
    editable: boolean
  }

  let {
    extensions = [],
    text,
    editable = true,
  } = $props<Props>()


  let text_editor_el: HTMLElement

  let view: EditorView

  let state: EditorState

  const compartment = new Compartment()

  // $: if (extensions.length && state) {
  //   state = create_state()

  //   view.setState(state)
  // }

  onMount(() => {
    state = create_state()

    view = new EditorView({
      state,
      parent: text_editor_el,
    })
  })

  let is_running_initialized = false
    is_running.subscribe((new_is_running) => {
    if (!is_running_initialized) return (is_running_initialized = true)

    view.dispatch({
      effects: compartment.reconfigure(EditorState.readOnly.of(editable && new_is_running))
    })

    if (new_is_running) {
      setTimeout(() => view.contentDOM.blur(), 0)
    }
  })

  const create_state = () => EditorState.create({
    doc: text,
    extensions: [
      codemirror_extensions,
      extensions,
      compartment.of(EditorView.editable.of(editable)),
      EditorState.allowMultipleSelections.of(true),
      keymap.of([{ key: 'Mod-b', run: () => {
        view.contentDOM.blur()

        return true
      }}])
    ]
  })
</script>

<svelte:window
  on:keydown={(evt) => {
    if (evt.key === 'Escape' && $is_running) {
      $is_running = false

      if (view.contentDOM.focus) setTimeout(() => view.contentDOM.focus(), 0)
    }
  }}
/>


<div class="h-full w-full focus-within:outline-none overflow-auto" bind:this={text_editor_el}></div>

