<script lang="ts">
  import { tools, ToolEvent } from '../../tools'
  import { find_shortcut } from '../../config'
  import { artboard_state } from '../../stores'
  import { is_running } from '@pogzul/engine';

  type Props = {
    render: () => number
    scene: import('@pogzul/engine').Scene
    canvas_el: HTMLCanvasElement
    canvas_target_el: HTMLElement
  }

  let {
    render,
    scene,
    canvas_el,
    canvas_target_el
  } = $props<Props>()

  const tool = $derived(tools[$artboard_state.active_tool])

  let is_running_initialized = false
    is_running.subscribe((new_is_running) => {
    if (!is_running_initialized) return (is_running_initialized = true)

    if (new_is_running) {
      $artboard_state.active_layer_ids = []
      $artboard_state.hovering_layer_ids = []
    }
  })

  const createToolEvent = (evt: MouseEvent | KeyboardEvent): ToolEvent => new ToolEvent({ evt, is_targeting_canvas: evt.target === canvas_el, is_targeting_editor: evt.target === canvas_target_el })

  const onmousemove = (evt: MouseEvent) => {
    evt.stopPropagation()

    if ($is_running) return

    $artboard_state.mouse_x = evt.clientX
    $artboard_state.mouse_y = evt.clientY

    if (evt.target === canvas_target_el || tool.name === 'Move') {
      tool.onMouseMove(createToolEvent(evt))
    }

    render()
  }

  const onmousedown = (evt: MouseEvent) => {
    evt.stopPropagation()

    if ($is_running) return

    if (evt.buttons === 2) return // Right click...

    if (evt.target === canvas_target_el || tool.name === 'Move' && evt.target === canvas_el) {
      tool.onMouseDown(createToolEvent(evt))
    }

    render()
  }

  const onmouseup = async (evt: MouseEvent) => {
    evt.stopPropagation()

    if ($is_running) return

    if (![canvas_el, canvas_target_el].includes(evt.target)) return

    if (evt.target === canvas_target_el || tool.name === 'Move') {
      const result = tool.onMouseUp(createToolEvent(evt))

      if (result instanceof Promise) {
        let is_resolved = false

        result.then(() => is_resolved = true)

        while (!is_resolved) {
          await new Promise(resolve => setTimeout(resolve, 30));

          render()
        }
      }
    }

    /** Trigger Svelte's reactive binding... */
    scene = scene

    render()
  }

  const onkeydown = (evt: KeyboardEvent) => {
    evt.stopPropagation()

    if ($is_running) return evt.preventDefault()

    // if (tool.name === 'Text') return tool.onKeyDown(createToolEvent(evt))

    const is_editable_element = ['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName.toUpperCase()) || document.activeElement?.contentEditable === 'true'

    if (evt.key === 'Escape') {
      // Messed up place to put this but yolo
      document.querySelectorAll('dialog').forEach(dialog => dialog.close())

      if (is_editable_element) return document.activeElement?.blur()
    }

    if (is_editable_element) return

    const shortcut = find_shortcut(evt)
    if (shortcut) {
      evt.preventDefault()

      if (shortcut(evt, scene)) {
        /** Trigger Svelte's reactive binding... */
        scene = scene
      }

    }

    render()
  }

  const oninput = (evt: Event & { currentTarget: EventTarget & Window; }) => {
    evt.stopPropagation()

    if (tool.name === 'Text') {
      tool.onInput(createToolEvent(evt))

      scene = scene
    }

    render()
  }

</script>

<svelte:window
  {onmousemove}
  {onmousedown}
  {onmouseup}
  {onkeydown}
  {oninput}
/>