<script>
  import { meta_tags } from '$lib/components'

  export let post

  meta_tags({ title: post.title, image: post.image })
</script>

<svelte:head>
  <title>Pogzul • {post.title}</title>
</svelte:head>

<main class="mx-auto max-w-2xl w-full prose py-12">
  <h1>{post.title}</h1>

  <img src={post.image} alt={post.title} />

  {@html post.html}
</main>