<script>
  import { Icon } from '@pogzul/artboard'
  import { ProjectsList } from '../ProjectsList'

  let modal

  const show_modal = () => {
    fetch_projects()

    modal.showModal()
  }

  let projects = []

  export const fetch_projects = async () => {
    const res = await fetch(`/projects`)

    if (res.ok) projects = await res.json()
  }

</script>

<button class="btn btn-square btn-sm" on:click={show_modal}>
  <Icon name="folder" class="h-4 w-4" />
</button>

<dialog id="share_modal" bind:this={modal} class="modal">
  <div class="modal-box space-y-3">
    <ProjectsList {projects} />

    <form method="dialog" class="flex justify-end">
      <button class="btn">Close</button>
    </form>
  </div>

  <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
</dialog>