<script>
  const MAX_TERMINAL_HISTORY = 100

  let terminal_el

  export const append = (value) => {
    terminal_el.innerHTML += value

    if (terminal_el.children && terminal_el.children.length >= MAX_TERMINAL_HISTORY) {
      terminal_el.children[0].remove()
    }

    terminal_el.scrollTop = terminal_el.scrollHeight
  }

  export const clear = () => { terminal_el.innerHTML = null }
</script>

<div class="max-h-32 bg-base-100/50 border-t border-base-content/20 w-full h-full relative">
  <pre
    bind:this={terminal_el}
    class="font-mono px-3 whitespace-pre-wrap select-all overflow-y-scroll max-h-full h-full"
    style="font-size: 0.8rem;"
  ></pre>

<!--   <div class="absolute h-10 flex items-center bottom-0 right-0 pr-3">
    <button on:click={ () => open = !open }>
      <svg class="h-3 text-primary transition-transform duration-layout { open ? 'rotate-180' : '' }" viewBox="0 0 100 60" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 40H20V60H0V40Z" />
        <path d="M20 20H40V40H20V20Z" />
        <path d="M40 0H60V20H40V0Z" />
        <path d="M60 20H80V40H60V20Z" />
        <path d="M80 40H100V60H80V40Z" />
      </svg>
    </button>
  </div> -->
</div>

<style lang="postcss">
  pre > :global(*) {
    @apply -mx-3 px-3 py-0.5;
  }
</style>