import { Pixels, config, delete_layers, pixel_grid } from '@pogzul/engine'

const neighbour_positions = [
  [-1, -1],
  [-1, 0],
  [-1, 1],
  [1, -1],
  [1, 0],
  [1, 1],
  [0, -1],
  [0, 1]
]

const count_neighbours = (grid, x, y) => {
  let count = 0

  for (let [dx, dy] of neighbour_positions) {
    if (grid[x + dx]?.[y + dy]) count++
  }

  return count
}

/**
 * @param {object} params
 * @param {number} [params.interval=0.2] - The interval between updates in
 *   seconds. Default is `0.2`
 * @param {boolean} [params.iterate=false] Default is `false`
 * @param {string} [params.color='black'] Default is `'black'`
 * @this {import('@pogzul/engine').Runtime}
 */
export function gameOfLife(opts = {}) {
  if (!this.active_scene.layer_ids.length)
    return this.console.error('You need at least 1 layer to play with!')

  const next = () => {
    if (!this.is_running) return

    const scene = this.active_scene

    const grid = pixel_grid(scene)
    let new_grid = {}

    const set = (x, y) => ((new_grid[x] ??= {})[y] = opts.color || 'black')

    const RESOLUTION = config.canvas_size / config.pixel_size

    for (let x = 0; x < RESOLUTION; x++) {
      for (let y = 0; y < RESOLUTION; y++) {
        const neighbours = count_neighbours(grid, x, y)

        if (grid[x]?.[y]) {
          if (neighbours === 2 || neighbours === 3) {
            set(x, y)
          }
        } else if (neighbours === 3) {
          set(x, y)
        }
      }
    }

    const layer = scene.entities[scene.layer_ids[0]]

    if (JSON.stringify(new_grid) === '{}') {
      return delete_layers({ scene, layer_ids: [layer.id] })
    }

    layer.pixels = new Pixels(new_grid)
    layer.x = 0
    layer.y = 0

    scene.layer_ids = [layer.id]
    scene.entities = { [layer.id]: layer }

    if (opts.iterate) {
      this.stop()
    } else {
      setTimeout(next, (opts.interval || 0.2) * 1000)
    }
  }

  next()
}

export { gameOfLife as gol }
