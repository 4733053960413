<script lang="ts">
  import { onMount } from 'svelte'
  // import { quintOut } from 'svelte/easing'
  // import { crossfade } from 'svelte/transition'

  import Sortable from 'sortablejs'

  const {
    list,
    key = null,
    class: class_name = '',
    onsort = null
  } = $props<{ list: any[], key?: string, class: string, onsort?: (any) => void }>()

  // /** @type {any[]} */
  // export let list

  // export let key = null

  let list_el: HTMLUListElement = $state()

  onMount(() => {
    const options = {
      ghostClass: 'opacity-50',
      onSort: function(evt) {
        onsort && onsort(evt)
      }
    }

    Sortable.create(list_el, options)
  })

  // const [send, receive] = crossfade({
  //   duration: d => Math.sqrt(d * 200),
  //   fallback(node, params) {
  //     const style = getComputedStyle(node)
  //     const transform = style.transform === 'none' ? '' : style.transform
  //     return {
  //       duration: 600,
  //       easing: quintOut,
  //       css: t => `
  //         transform: ${transform} scale(${t});
  //         opacity: ${t}
  //       `
  //     };
  //   }
  // });

  const get_key = item => (key ? item[key] : item)
</script>

<ul bind:this={list_el} class="{class_name}">
  {#each list as item, index (get_key(item))}
      <!-- in:receive={{ key: get_key(item) }}
      out:send={{ key: get_key(item) }}
      animate:flip={{ duration: 200 }} -->
    <li
      class="cursor-move"
    >
      <slot {item} {index}>
        <p>{ get_key(item) }</p>
      </slot>
    </li>
  {/each}
</ul>