import { Pixels } from '@pogzul/engine'
import { renderPixels } from '../utilities'

const defaultProps = {
  get pixels() {
    return new Pixels()
  },

  /*
    Coordinates for the event(s) — start and current/end
  */
  x1: null,
  y1: null,
  x2: null,
  y2: null
}

/**
 * @class Tool
 * @type {Pixels} pixels
 */
class Tool {
  /** @type {Pixels} */
  pixels = new Pixels()

  /** @type {number} */
  x1

  /** @type {number} */
  y1

  /** @type {number} */
  x2

  /** @type {number} */
  y2

  constructor(opts = {}) {
    Object.assign(this, defaultProps, opts)
  }

  /** @param {ReturnType<import('@pogzul/engine').initializeWebGL>} ctx */
  render(ctx) {
    if (this.pixels.size === 0) return

    renderPixels({ ctx, pixels: this.pixels, is_tool: true })
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseDown({ mouse_x, mouse_y, x, y }) {
    this.x1 = x
    this.y1 = y
    this.x2 = x
    this.y2 = y

    this.screen_x1 = mouse_x
    this.screen_y1 = mouse_y
    this.screen_x2 = mouse_x
    this.screen_y2 = mouse_y
  }

  /** @param {import('./ToolEvent').default} _evt */
  onMouseUp(_evt) {
    this.#reset()
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseMove({ is_mouse_down, mouse_x, mouse_y, x, y }) {
    if (is_mouse_down) {
      this.screen_x2 = mouse_x
      this.screen_y2 = mouse_y

      this.x2 = x
      this.y2 = y
    }
  }

  /** @param {import('./ToolEvent').default} _evt */
  onInput(_evt) {}

  #reset() {
    Object.assign(this, defaultProps)
  }
}

export default Tool
