import Tool from './Tool.js'

import { Pixels, Shape, draw } from '@pogzul/engine'
import { state } from '../stores'

class RectangleTool extends Tool {
  /** @type {'Rectangle'} */
  name = 'Rectangle'

  shortcut = 'r'

  /** @param {import('./ToolEvent').default} evt */
  onMouseDown(evt) {
    super.onMouseDown(evt)

    this.#draw(evt)
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseUp(evt) {
    const result = draw({
      scene: evt.scene,
      pixels: this.pixels,
      layer_ids: evt.active_layer_ids
    })

    if (result) {
      state.update((old_state) => ({
        ...old_state,
        active_layer_ids: typeof result === 'number' ? [] : [result.id]
      }))
    }

    return super.onMouseUp(evt)
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseMove(evt) {
    if (!evt.is_mouse_down) {
      return false
    }

    this.#draw(evt)

    super.onMouseMove(evt)
  }

  /* Private */

  /** @param {import('./ToolEvent').default} evt */
  #draw({ active_color }) {
    let pixels = {}

    const x1 = Math.min(this.x1, this.x2),
      y1 = Math.min(this.y1, this.y2),
      x2 = Math.max(this.x1, this.x2),
      y2 = Math.max(this.y1, this.y2)

    for (let x = x1; x <= x2; x++) {
      pixels[x] = {
        [y1]: active_color,
        [y2]: active_color
      }
    }

    for (let y = y1; y <= y2; y++) {
      for (let x of [x1, x2]) {
        pixels[x][y] = active_color
      }
    }

    this.pixels = new Pixels(pixels)
  }
}

export default RectangleTool
