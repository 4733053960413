<script>
  import { page, useForm } from '@inertiajs/svelte'
  import { copy_to_clipboard } from '$lib/utilities'

  let experience

  let is_publishing = false

  $: experience_url = `${$page.props.config.app_url}/e/${experience?.external_id}`

  $: form = useForm({
    visibility: experience?.visibility
  })

  export const fetch_experience = async () => {
    if (!$page.props.project.experience_id) return

    const res = await fetch(`/experiences/${$page.props.project.experience_id}`)

    if (res.ok) experience = await res.json()
  }

  function handle_update(evt) {
    evt.preventDefault()

    $form.patch(this.action, {
      onSuccess: fetch_experience
    })
  }

  const publishing_form = useForm()

  const handle_publish = (method) => {
    return function (evt){
      evt.preventDefault()

      is_publishing = true

      $publishing_form[method](this.action, {
        onSuccess: () => fetch_experience(),
        onFinish: () => is_publishing = false
      })
    }
  }

  const format_datetime = date => new Intl.DateTimeFormat(undefined, { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(date))
</script>

<p>A dedicated page to experience the project.</p>

{#if experience}
  <form
    action="/experiences/{experience.id}"
    method="post"
    class="space-y-3"
    on:change={handle_update}
  >
    <div class="flex justify-between">
      <div class="flex space-x-3">
        <input
          id="experience_visibility_hidden"
          type="radio"
          name="experience[visibility]"
          value="hidden"
          class="radio"
          bind:group={$form.visibility}
          checked={$form.visibility === 'hidden'}
        />

        <label for="experience_visibility_hidden">
          <p class="font-semibold">Public</p>
          <p class="text-base-content/70 text-sm">Anyone with the link can view it.</p>
        </label>
      </div>
    </div>

    <div class="flex space-x-3">
      <input
        id="experience_visibility_private"
        type="radio"
        name="experience[visibility]"
        value="private"
        class="radio"
        bind:group={$form.visibility}
        checked={$form.visibility === 'private'}
      />

      <label for="experience_visibility_private">
        <p class="font-semibold">Private</p>
        <p class="text-base-content/70 text-sm">Only you can view it.</p>
      </label>
    </div>
  </form>

  <button
    class="join w-full"
    on:click={() => copy_to_clipboard(experience_url)}
  >
    <input
      type="text"
      class="input input-bordered min-w-0 w-full join-item hover:z-10 focus:z-10"
      readonly
      value={experience_url}
    />

    <div class="btn btn-success btn-outline join-item hover:z-10">
      Copy link
    </div>
  </button>

  <div class="flex items-center justify-between mt-6">
    <small>Published at {format_datetime(experience.published_at)}.</small>

    <form action="/publishing/{experience.id}" on:submit={handle_publish('patch')}>
      <button class="btn btn-sm btn-outline">
        {#if is_publishing}<span class="loading loading-dots loading-sm"></span>{/if}

        Update
      </button>
    </form>
  </div>
{:else}
  <form action="/projects/{$page.props.project.id}/experiences" on:submit={handle_publish('post')}>
    <button class="btn btn-success btn-outline w-full">
      {#if is_publishing}<span class="loading loading-dots"></span>{/if}

      Publish
    </button>
  </form>
{/if}