import { m3 } from './matrices'
import { quad, vec2 } from './utils'

import { Graphics } from './Graphics.js'
import { MatrixStack } from './MatrixStack.js'
import { Shader } from './Shader.js'
import { Texture } from './Texture.js'

import { fonts } from '@pogzul/engine'

import { textures, canvases } from '../stores'

export class Context {
  transform_stack = new MatrixStack()

  /** @type {Graphics} */
  graphics

  /** @param {WebGLRenderingContext} gl */
  constructor(gl) {
    this.gl = gl

    this.graphics = new Graphics({
      gl,
      transform_stack: this.transform_stack
    })

    this.text_shader = new Shader({
      gl,
      fragment_source: msdf_fragment_shader
    })
  }

  /**
   * @param {number} sx - Scale x
   * @param {number} vSkew - Vertical skew
   * @param {number} hSkew - Horizontal skew
   * @param {number} sy - Scaly y
   * @param {number} tx - Translate x
   * @param {number} ty - Translate y
   */
  setTransform(sx = 1, vSkew, hSkew, sy = 1, tx = 0, ty = 0) {
    this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height)

    this.transform_stack.reset()

    this.transform_stack.project(this.gl.canvas.width, this.gl.canvas.height)

    this.transform_stack.translate(tx, ty)
    this.transform_stack.scale(sx, sy)
  }

  /*
    Rendering
  */

  frameStart() {
    this.gl.clear(this.gl.COLOR_BUFFER_BIT)
  }

  frameEnd() {
    this.graphics.flush()
  }

  /*
    Geometry
  */

  /**
   * @param {Object} opts
   * @param {number} opts.x
   * @param {number} opts.y
   * @param {number} opts.width
   * @param {number} opts.height
   * @param {Uint8Array} opts.color - An [r,g,b,a] array (values between 0-255)
   * @returns {void}
   */
  fillRect({ x, y, width, height, color }) {
    this.graphics.drawQuad({ pos: vec2(x, y), width, height, color })
  }

  /**
   * @param {Object} opts
   * @param {number} opts.x
   * @param {number} opts.y
   * @param {number} opts.width
   * @param {number} opts.height
   * @param {number} opts.lineWidth
   * @param {Uint8Array} opts.color - An [r,g,b,a] array (values between 0-255)
   * @returns {void}
   */
  strokeRect({ x, y, width, height, lineWidth, color }) {
    let x2 = x + width,
      y2 = y + height

    const w = lineWidth / 2

    // Top
    this.graphics.drawQuad({
      pos: vec2(x - w, y - w),
      width: lineWidth + x2 - x,
      height: lineWidth,
      color
    })

    // Right
    this.graphics.drawQuad({
      pos: vec2(x2 - w, y - w),
      width: lineWidth,
      height: lineWidth + y2 - y,
      color
    })

    // Bottom
    this.graphics.drawQuad({
      pos: vec2(x - w, y2 - w),
      width: lineWidth + x2 - x,
      height: lineWidth,
      color
    })

    // Left
    this.graphics.drawQuad({
      pos: vec2(x - w, y - w),
      width: lineWidth,
      height: lineWidth + y2 - y,
      color
    })
  }

  /**
   * @param {Object} opts
   * @param {import('@pogzul/engine').Shape} opts.shape
   * @param {number} opts.alpha - Opacity of the text
   * @returns {void}
   */
  drawShape({ shape, alpha }) {
    if (!textures.get(shape.id)) {
      textures.set(
        shape.id,
        new Texture({ gl: this.gl, src: canvases[shape.id]?.element })
      )
    }

    /*
      Scale and translate the 1 unit quad to dx, dy, dWidth and dHeight
    */
    let matrix = m3.translate(this.transform_stack.current, shape.x, shape.y)
    matrix = m3.scale(matrix, shape.width, shape.height)

    this.graphics.drawQuad({
      texture: textures.get(shape.id),
      matrix,
      color: new Uint8Array([255, 255, 255, alpha * 255])
    })
  }

  /*
    Text
  */

  /**
   * @param {Object} opts
   * @param {import('@pogzul/engine').Text} opts.text - The text layer to draw
   * @param {number} opts.alpha - Opacity of the text
   * @param {Uint8Array} [opts.color]
   * @param {number} [opts.weight]
   * @returns {void}
   */
  drawText({ text, alpha, color, weight }) {
    const font = fonts[text.font]

    if (!font) return

    if (!textures.get(font.id)) {
      textures.set(
        font.id,
        new Texture({
          gl: this.gl,
          src: font.atlas,
          filter: 'linear'
        })
      )
    }

    const { width, height } = textures.get(font.id)

    const scale = text.size / font.size

    const line_height = font.glyph_height

    let lines = []

    // Split on newlines and spaces
    // const words = text.value.split(/(?:\r\n|\r|\n|\s)/)

    /** @param {string} text */
    const measure_text = (text) =>
      text
        .split('')
        .reduce((sum, char) => sum + font.characters[char].xadvance, 0)

    let line = {
      text: '',
      width: 0
    }

    const words = text.value.split(/(\r\n|\r|\n|\s)/)

    for (let word of words) {
      if (!word) continue

      if (/(\r\n|\r|\n)/.test(word)) {
        lines.push(line)
        line = { text: '', width: 0 }

        continue
      }

      const width = measure_text(word)

      if ((line.width + width) * scale > text.width) {
        lines.push(line)

        line = { text: word, width }
      } else {
        line.text += word
        line.width += width
      }
    }

    lines.push(line)

    let y = 0

    if (text.align === 'middle') {
      y = (text.height / scale - line_height * lines.length) / 2
    } else if (text.align === 'bottom') {
      y = text.height / scale - line_height * lines.length
    }

    for (let i = 0; i < lines.length; i++) {
      let x = 0

      if (text.justify === 'center') {
        x = (text.width / scale - lines[i].width) / 2
      } else if (text.justify === 'right') {
        x = text.width / scale - lines[i].width
      }

      for (let char of lines[i].text) {
        const config = font.characters[char]

        this.graphics.drawQuad({
          pos: vec2(
            text.x + (x + config.xoffset) * scale,
            text.y + (y + config.yoffset) * scale
          ),
          width: config.width * scale,
          height: config.height * scale,
          quad: quad(
            config.x / width,
            config.y / height,
            config.width / width,
            config.height / height
          ),
          color:
            color || new Uint8Array([...text.color.slice(0, 3), alpha * 255]),
          texture: textures.get(font.id),
          shader: this.text_shader,
          uniform: {
            u_dist: weight || 0.5
          }
        })

        x += config.xadvance
      }

      y += line_height
    }
  }

  /**
   * @param {Object} opts
   * @param {import('@pogzul/engine').Text} opts.text - The text layer to draw
   * @param {number} opts.alpha - Opacity of the text
   * @param {Uint8Array} opts.color - Opacity of the text
   * @returns {void}
   */
  strokeText({ text, alpha, color }) {
    this.drawText({ text, alpha, color, weight: 0.3 })
  }
}

const sdf_fragment_shader = `
uniform float u_dist;

vec4 color(vec2 pos, vec2 uv, vec4 color, sampler2D tex) {
  vec4 tex_color = texture(tex, uv);

  float alpha = step(u_dist, tex_color.a);

  return vec4(color.rgb, alpha * color.a);
}
`

// const msdf_fragment_shader = `
// precision highp float;

// uniform float u_dist;

// // configure per font
// float px_range = 8.0;

// float median(float r, float g, float b) {
//   return max(min(r, g), min(max(r, g), b));
// }

// float screen_px_range() {
//   vec2 msdf_unit_range = px_range / vec2(textureSize(u_tex, 0));

//   vec2 screen_tex_size = vec2(1.0) / fwidth(v_uv);

//   return dot(msdf_unit_range, screen_tex_size);
// }

// vec4 color(vec2 pos, vec2 uv, vec4 color, sampler2D tex) {
//   vec3 tex_color = texture(tex, uv).rgb;

//   float signed_dist = median(tex_color.r, tex_color.g, tex_color.b);

//   float screen_px_distance = screen_px_range() * (signed_dist - u_dist);

//   float alpha = clamp(screen_px_distance + u_dist, 0.0, 1.0);

//   return vec4(color.rgb, alpha * color.a);
// }
// `

const msdf_fragment_shader = `
precision highp float;

uniform float u_dist;

// configure per font
float px_range = 8.0;

float median(float r, float g, float b) {
  return max(min(r, g), min(max(r, g), b));
}

vec4 color(vec2 pos, vec2 uv, vec4 color, sampler2D tex) {
  // Bilinear sampling of the distance field
  vec3 tex_color = texture(tex, uv).rgb;

  // Acquire the signed distance
  float signed_dist = median(tex_color.r, tex_color.g, tex_color.b) - u_dist;

  // Weight between inside and outside (anti-aliasing)
  float alpha = clamp(signed_dist / fwidth(signed_dist) + u_dist, 0.0, 1.0);

  // Combining the background and foreground color
  return vec4(color.rgb, alpha * color.a);
}
`

export default Context
