import { Callback } from '../../internal'

import {
  Group,
  Shape,
  Text,
  Entity,
  config,
  RuntimeWindow,
  active
} from '@pogzul/engine'

import type { Tag, Runtime } from '@pogzul/engine'

import { collision_entity_window, collision_window_scene } from './entities'
import {
  collision_shape_shape,
  collision_shape_group,
  collision_shape_tag,
  collision_shape_scene
} from './shapes'

import {
  collision_group_shape,
  collision_group_group,
  collision_group_tag,
  collision_group_scene
} from './groups'

import {
  collision_tag_shape,
  collision_tag_group,
  collision_tag_tag,
  collision_tag_window,
  collision_tag_scene
} from './tags'

export type Collision = Partial<{
  top: boolean
  right: boolean
  bottom: boolean
  left: boolean
  x: number
  y: number
}>

export type Collidable = Shape | Group | Tag | RuntimeWindow

export type CollisionCallback = ({}: {
  layer: Entity
  target: Shape | RuntimeWindow
  resolve: () => void
  collision: Collision
}) => void

type CollisionOptions = Partial<{ interval: number }>

export function colliding(
  this: import('@pogzul/engine').Runtime,
  layer: Collidable | Array<Collidable> | CollisionCallback | undefined,
  target: Collidable | Array<Collidable> | CollisionCallback | undefined,
  callback?: CollisionCallback | CollisionOptions | undefined,
  opts: CollisionOptions = {}
): void {
  if (!layer) throw new Error('`colliding` requires at least 1 argument.')

  if (Array.isArray(layer)) {
    layer.map((new_layer) =>
      colliding.call(this, new_layer, target, callback, opts)
    )
    return
  }

  if (Array.isArray(target)) {
    target.map((new_target) =>
      colliding.call(this, layer, new_target, callback, opts)
    )
    return
  }

  let collision_fn: (
    layer: Collidable,
    target: Collidable | CollisionCallback,
    callback: CollisionCallback
  ) => void

  let cb: CollisionCallback

  if (typeof layer === 'function') {
    cb = layer
    if (typeof target === 'object') opts = callback

    collision_fn = colliding_all.bind(this)
  } else if (layer instanceof Shape) {
    collision_fn = colliding_shape.bind(this)
  } else if (layer instanceof Text) {
    throw new Error("Text doesn't yet work with collisions.")
  } else if (layer instanceof Group) {
    collision_fn = colliding_group.bind(this)
  } else if (layer instanceof RuntimeWindow) {
    collision_fn = colliding_window.bind(this)
  } else if (typeof layer === 'string') {
    collision_fn = colliding_tag.bind(this)
  }

  if (typeof target === 'function') {
    cb = target
    if (typeof callback === 'object') opts = callback
  } else if (typeof callback === 'function') {
    cb = callback
  }

  const effect = new Callback(() => {
    collision_fn(layer, target, ({ layer, target, collision }) =>
      cb({
        layer,
        target,
        collision,
        resolve: () => resolve_collision(layer, target, collision)
      })
    )
  }, opts?.interval || 0)

  this.effects.push(effect)
}

function colliding_all(
  this: Runtime,
  layer: undefined,
  target: undefined,
  callback: CollisionCallback
) {
  const entities = Object.values(active.scene.entities)

  for (let i = 0; i < entities.length - 1; i++) {
    if (entities[i] instanceof Group) continue
    for (let j = i + 1; j < entities.length; j++) {
      if (entities[j] instanceof Group) continue

      // this.console.log('checking!', entities[i].id == entities[j].id)

      collision_shape_shape(entities[i], entities[j], callback)
    }
  }
}

function colliding_shape(
  shape: Shape,
  target: Collidable | Function,
  callback: CollisionCallback
): void {
  switch (true) {
    case typeof target === 'object' && 'pixels' in target:
      if (shape.id === target.id)
        throw new Error(`A shape is always colliding with itself!`)

      return collision_shape_shape(shape, target, callback)

    case target instanceof Group:
      return collision_shape_group(shape, target, callback)

    case target instanceof RuntimeWindow:
      return collision_entity_window(shape, target, callback)

    case typeof target === 'string':
      return collision_shape_tag(shape, target, callback)

    case typeof target === 'function':
      return collision_shape_scene(shape, callback)
  }
}

function colliding_group(
  group: Group,
  target: Collidable | Function,
  callback: CollisionCallback
) {
  switch (true) {
    case 'pixels' in target:
      return collision_group_shape(group, target, callback)

    case target instanceof Group:
      if (group.id === target.id)
        throw new Error(`A group is always colliding with itself!`)

      return collision_group_group(group, target, callback)

    case target instanceof RuntimeWindow:
      return collision_entity_window(group, target, callback)

    case typeof target === 'string':
      return collision_group_tag(group, target, callback)

    case typeof target === 'function':
      return collision_group_scene(group, callback)
  }
}

function colliding_tag(
  this: Runtime,
  tag: Tag,
  target: Collidable | Function,
  callback: CollisionCallback
) {
  switch (true) {
    case 'pixels' in target:
      return collision_tag_shape(tag, target, callback)

    case target instanceof Group:
      return collision_tag_group(tag, target, callback)

    case target instanceof RuntimeWindow:
      return collision_tag_window.call(this, tag, target, callback)

    case typeof target === 'string':
      return collision_tag_tag.call(this, tag, target, callback)

    case typeof target === 'function':
      return collision_tag_scene.call(this, tag, callback)
  }
}

function colliding_window(
  this: Runtime,
  window: RuntimeWindow,
  target: Collidable | Function,
  callback: CollisionCallback
) {
  switch (true) {
    case 'pixels' in target:
      return collision_entity_window(target, window, callback)

    case target instanceof Group:
      return collision_entity_window(target, window, callback)

    case target instanceof RuntimeWindow:
      throw new Error(`The window can't collide with itself!`)

    case typeof target === 'string':
      return collision_tag_window.call(this, target, window, callback)

    case typeof target === 'function':
      return collision_window_scene.call(this, window, callback)
  }
}

function resolve_collision(
  entity: Entity,
  target: Entity | RuntimeWindow,
  collision: Collision
) {
  const target_x = target instanceof RuntimeWindow ? 0 : target.x,
    target_y = target instanceof RuntimeWindow ? 0 : target.y

  // if (collision.left || collision.right) {
  //   const target_x = target instanceof RuntimeWindow ? 0 : target.x

  //   let overlap_x =
  //     ((entity.x - target_x) % config.pixel_size) +
  //     (collision.right ? 0 : -config.pixel_size)
  //   // ((entity.x + target_x) % config.pixel_size) +
  //   // (collision.right ? 0 : -config.pixel_size)

  //   // if (target instanceof RuntimeWindow) {
  //   entity.x -= overlap_x
  //   // } else {
  //   //   overlap_x /= 2
  //   //   entity.x -= overlap_x
  //   //   target.x += overlap_x
  //   // }
  // }

  if (collision.left) {
    let overlap_x =
      ((10_000 + entity.x - target_x) % config.pixel_size) - config.pixel_size

    /**
     * 10, 20 => -10 20, 10 => -10 22, 12 => -10 38, 4 => -6 -12, 14 => -8 -2, 4
     * => -6 -32, 50 => -2
     */

    entity.x -= overlap_x
  } else if (collision.right) {
    let overlap_x = (10_000 + entity.x - target_x) % config.pixel_size

    // if (target instanceof RuntimeWindow) {
    entity.x -= overlap_x
    // } else {
    //   overlap_x /= 2
    //   entity.x -= overlap_x
    //   target.x += overlap_x
    // }
  }

  if (collision.top) {
    let overlap_y =
      ((10_000 + entity.y - target_y) % config.pixel_size) - config.pixel_size

    entity.y -= overlap_y
  } else if (collision.bottom) {
    let overlap_y = (10_000 + entity.y - target_y) % config.pixel_size

    // if (target instanceof RuntimeWindow) {
    entity.y -= overlap_y
    // } else {
    //   overlap_y /= 2
    //   entity.y -= overlap_y
    //   target.y += overlap_y
    // }
  }
}
