import {
  Shape,
  add_shapes,
  config,
  canvas_coordinate,
  is_running,
  delete_layers,
  sort_layers,
  group_layers,
  ungroup_layers
} from '@pogzul/engine'
import { get } from 'svelte/store'
import { state, clipboard } from '../stores'
import { tools } from '../tools'
import { compute_layers_boundary } from '../utilities'
// import { undo_manager } from '../realtime'

/**
 * @callback ShortcutHandler
 * @param {KeyboardEvent} event
 * @param {import('@pogzul/engine').Scene} scene
 * @returns {void | boolean} - Return truthy if the document state changed
 */

/** @type {ShortcutHandler} */
const select_visible_layers = (_event, scene) =>
  state.update((old_state) => ({
    ...old_state,
    active_layer_ids: scene.layer_ids.filter(
      (id) => !scene.entities[id].is_hidden
    ),
    active_tool: scene.layer_ids.length > 1 ? 'move' : old_state.active_tool
  }))

/** @type {ShortcutHandler} */
const select_layer = (event, scene) => {
  const { active_layer_ids } = get(state)

  // Same order as Layers.svelte: closest at the top
  const layer_ids = [...scene.layer_ids].reverse()

  let current_index = layer_ids.indexOf(active_layer_ids[0])
  if (event.shiftKey && current_index === -1) current_index += 2

  const next_index =
    (layer_ids.length + current_index + (event.shiftKey ? -1 : 1)) %
    layer_ids.length

  state.update((old_state) => ({
    ...old_state,
    active_layer_ids: [layer_ids[next_index]]
  }))
}

/**
 * @param {number} x
 * @param {number} y
 * @returns {ShortcutHandler}
 */
const move_layer = (x, y) => (event, scene) => {
  const { active_layer_ids } = get(state)

  if (!active_layer_ids.length) return

  const multiplier = event.shiftKey ? 5 : 1

  const dx = x * multiplier * config.pixel_size,
    dy = y * multiplier * config.pixel_size

  for (let id of active_layer_ids) {
    const layer = scene.entities[id]

    layer.x += dx
    layer.y += dy
  }

  return true
}
/** @type {ShortcutHandler} */
const handle_group_layers = (_evt, scene) => {
  const { active_layer_ids } = get(state)

  if (active_layer_ids.length < 2) return

  const group_id = group_layers({ scene, layer_ids: active_layer_ids })

  state.update((old_state) => ({
    ...old_state,
    active_layer_ids: [group_id],
    hovering_layer_ids: []
  }))

  return true
}

/** @type {ShortcutHandler} */
const handle_ungroup_layers = (_evt, scene) => {
  const { active_layer_ids } = get(state)

  if (!active_layer_ids.length) return

  const layer_ids = ungroup_layers({ scene, group_ids: active_layer_ids })

  state.update((old_state) => ({
    ...old_state,
    active_layer_ids: layer_ids,
    hovering_layer_ids: []
  }))

  return true
}

/** @type {ShortcutHandler} */
const delete_active_layers = (_event, scene) => {
  const { active_layer_ids } = get(state)

  if (!active_layer_ids.length) return

  delete_layers({ scene, layer_ids: active_layer_ids })

  state.update((old_state) => ({
    ...old_state,
    active_layer_ids: []
  }))

  return true
}

/**
 * @param {number} delta
 * @returns {ShortcutHandler}
 */
const change_layers_z_index = (delta) => (_event, scene) => {
  sort_layers({ scene, layer_ids: get(state).active_layer_ids, delta })

  return true
}

/**
 * @param {keyof typeof import('../tools').tools} new_tool
 * @returns {ShortcutHandler}
 */
const set_tool = (new_tool) => (_event) => {
  state.update((old_state) => ({
    ...old_state,
    hovering_layer_ids: [],
    active_tool: new_tool
  }))
}

/** @type {ShortcutHandler} */
const copy = (_event, scene) => {
  const { active_layer_ids } = get(state)

  if (!active_layer_ids.length) return

  const layers = active_layer_ids.map((id) => scene.entities[id])

  clipboard.value = JSON.parse(JSON.stringify(layers))
}

/** @type {ShortcutHandler} */
const paste = (_evt, scene) => {
  const copied_layers = clipboard.value

  if (!Array.isArray(copied_layers) || copied_layers.length === 0) return false

  const { mouse_x, mouse_y, editor_offsets } = get(state)

  const group_boundary = compute_layers_boundary(copied_layers)

  const shapes = copied_layers.map((layer) => {
    const { id: _old_id, ...new_layer } = layer

    const same_name_count = Object.values(scene.entities).filter(({ name }) =>
      name.includes(new_layer.name.replace(/ \(copy.+/, ''))
    ).length

    new_layer.name =
      same_name_count > 1
        ? new_layer.name.replace(/ \(copy.+/, '') + ` (copy ${same_name_count})`
        : new_layer.name + ' (copy)'

    new_layer.x += canvas_coordinate(
      mouse_x - editor_offsets.x - group_boundary.x - group_boundary.width / 2
    )
    new_layer.y += canvas_coordinate(
      mouse_y - editor_offsets.y - group_boundary.y - group_boundary.height / 2
    )

    return new_layer
  })

  const new_shapes = add_shapes({ scene, shapes })

  state.update((old_state) => ({
    ...old_state,
    hovering_layer_ids: [],
    active_layer_ids: new_shapes.map(({ id }) => id)
  }))

  return true
}

const undo = () => {
  return true
}

const redo = () => {
  return true
}

/** @type {ShortcutHandler} */
const flatten_layers = (_evt, scene) => {
  const { active_layer_ids } = get(state)

  if (active_layer_ids.length < 2) return

  const [first_id, ...layer_ids] = scene.layer_ids.filter((id) =>
    active_layer_ids.includes(id)
  )

  const first_layer = scene.entities[first_id]

  for (let id of layer_ids) {
    const layer = scene.entities[id]

    const dx = (layer.x - first_layer.x) / config.pixel_size,
      dy = (layer.y - first_layer.y) / config.pixel_size

    for (let [x, row] of layer.pixels) {
      for (let [y, color] of row) {
        first_layer.pixels.set(x + dx, y + dy, color)
      }
    }
  }

  delete_layers({ scene, layer_ids })

  first_layer.update()

  state.update((old_state) => ({
    ...old_state,
    active_layer_ids: [first_layer.id]
  }))

  return true
}

/** @type {ShortcutHandler} */
const escape_key = (_event) => {
  const { active_layer_ids } = get(state)

  if (active_layer_ids.length) {
    state.update((old_state) => ({ ...old_state, active_layer_ids: [] }))
  } else {
    state.update((old_state) => ({ ...old_state, active_tool: 'move' }))
  }
}

const select_scene = () => {
  alert('selecting!')
}

/** @type {Object<string, ShortcutHandler>} */
export const shortcuts = {
  /* Layers */
  mod_a: select_visible_layers,
  n: select_layer,

  left: move_layer(-1, 0),
  right: move_layer(+1, 0),
  up: move_layer(0, -1),
  down: move_layer(0, +1),

  mod_g: handle_group_layers,
  mod_u: handle_ungroup_layers,

  backspace: delete_active_layers,

  // ctrl_1: select_scene,

  mod_left_square_bracket: change_layers_z_index(-1),
  mod_right_square_bracket: change_layers_z_index(1),

  mod_e: flatten_layers,

  /* Tools */
  ...Object.keys(tools).reduce(
    (obj, key) => ({
      ...obj,
      [tools[key].shortcut]: set_tool(key)
    }),
    {}
  ),

  /* Clipboard */
  mod_c: copy,
  mod_v: paste,

  /* Undo/Redo */
  mod_z: undo,
  mod_y: redo,

  mod_b: () => is_running.set(!get(is_running)),

  /* Project */
  // mod_b: run,

  /* Misc */
  escape: escape_key
}

/** @type {Object<string, string>} */
const CUSTOM_KEY_NAMES = {
  Backspace: 'backspace',

  Escape: 'escape',

  ArrowLeft: 'left',
  ArrowUp: 'up',
  ArrowRight: 'right',
  ArrowDown: 'down',

  '[': 'left_square_bracket',
  ']': 'right_square_bracket',

  ' ': 'spacebar',

  Control: ' ',
  Meta: ' '
}

/** @param {KeyboardEvent} evt */
export const find_shortcut = (evt) => {
  let key = (CUSTOM_KEY_NAMES[evt.key] || evt.key).trim().toLowerCase()

  if (evt.metaKey || evt.getModifierState('OS') /* Windows Key */) {
    key = 'mod_' + key
  }

  if (evt.ctrlKey) {
    key = 'ctrl_' + key
  }

  return shortcuts[key]
}
