import { VERTEX_FORMAT } from './config'

export class Shader {
  /** @type {WebGLRenderingContext} */
  gl

  /** @type {WebGLProgram} */
  program

  /**
   * @param {object} params
   * @param {WebGLRenderingContext} params.gl
   * @param {string} [params.vertex_source]
   * @param {string} [params.fragment_source]
   */
  constructor({
    gl,
    vertex_source = DEFAULT_VERTEX,
    fragment_source = DEFAULT_FRAGMENT
  }) {
    this.gl = gl

    const vertex_shader_source = VERTEX_TEMPLATE.replace(
      '{{position_function}}',
      vertex_source
    ).trim()

    const fragment_shader_source = FRAGMENT_TEMPLATE.replace(
      '{{color_function}}',
      fragment_source
    ).trim()

    // Compile the shaders
    const vertex_shader = gl.createShader(gl.VERTEX_SHADER)
    if (!vertex_shader) throw new Error('gl.createShader returned null')

    const fragment_shader = gl.createShader(gl.FRAGMENT_SHADER)
    if (!fragment_shader) throw new Error('gl.createShader returned null')

    gl.shaderSource(vertex_shader, vertex_shader_source)
    gl.shaderSource(fragment_shader, fragment_shader_source)
    gl.compileShader(vertex_shader)
    gl.compileShader(fragment_shader)

    // Create the program
    const program = gl.createProgram()
    if (!program) throw new Error('gl.createProgram returned null')

    this.program = program

    gl.attachShader(program, vertex_shader)
    gl.attachShader(program, fragment_shader)

    // Bind the vertex attributes
    VERTEX_FORMAT.forEach((vertex, index) =>
      gl.bindAttribLocation(program, index, vertex.name)
    )

    gl.linkProgram(program)

    const linked = gl.getProgramParameter(program, gl.LINK_STATUS)

    if (!linked) {
      const vert_error = gl.getShaderInfoLog(vertex_shader)
      if (vert_error) throw new Error('VERTEX SHADER: ' + vert_error)
      const frag_error = gl.getShaderInfoLog(fragment_shader)
      if (frag_error) throw new Error('FRAGMENT SHADER: ' + frag_error)
    }

    gl.deleteShader(vertex_shader)
    gl.deleteShader(fragment_shader)
  }

  bind() {
    this.gl.useProgram(this.program)
  }

  /** @param {import('./types').Uniform} uniform */
  send(uniform) {
    for (let name in uniform) {
      const location = this.gl.getUniformLocation(this.program, name)

      this.gl.uniform1f(location, uniform[name])
    }
  }
}

const VERTEX_TEMPLATE = `
#version 300 es

in vec2 a_pos;
in vec2 a_uv;
in vec4 a_color;

out vec2 v_pos;
out vec2 v_uv;
out vec4 v_color;

{{position_function}}

void main() {
  v_pos = a_pos;
  v_uv = a_uv;
  v_color = a_color;

  gl_Position = position(a_pos, a_uv, a_color);
}
`

const FRAGMENT_TEMPLATE = `
#version 300 es

precision mediump float;

in vec2 v_pos;
in vec2 v_uv;
in vec4 v_color;

uniform sampler2D u_tex;

out vec4 output_color;

{{color_function}}

void main() {
  output_color = color(v_pos, v_uv, v_color, u_tex);

  if (output_color.a == 0.0) {
    discard;
  }
}
`

const DEFAULT_VERTEX = `
vec4 position(vec2 pos, vec2 uv, vec4 color) {
  return vec4(pos, 1, 1);
}
`

const DEFAULT_FRAGMENT = `
vec4 color(vec2 pos, vec2 uv, vec4 color, sampler2D tex) {
  return color * texture(tex, uv);
}
`

export default Shader
