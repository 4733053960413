import { Group, Shape, Text, Pixels } from '../models'
import { Canvas } from '../models/Canvas'
import { grid_coordinate } from './coordinates'
import { parse_color } from './colors'
import { config } from '../config'
import { render_pixels_canvas_2d } from './rendering'

import { canvases, textures, active } from '../stores'

/**
 * @param {object} opts
 * @param {import('@pogzul/engine').Scene} opts.scene
 * @param {number[]} opts.layer_ids
 * @param {number} opts.delta - How far to move the layers
 */
export const sort_layers = ({ scene, layer_ids, delta = 1 }) => {
  if (delta === 0) return false

  /** Sort the layers to change by their position in the scene's layer_ids */
  const ids = [...layer_ids].sort(
    (a, b) => delta * (scene.layer_ids.indexOf(b) - scene.layer_ids.indexOf(a))
  )

  const layers_at_edge = ids.some(
    (id) =>
      (delta < 0 && id === scene.layer_ids[0]) ||
      (delta > 0 && id === scene.layer_ids[scene.layer_ids.length - 1])
  )

  if (layers_at_edge) return false

  for (let id of ids) {
    const index = scene.layer_ids.indexOf(id)

    const new_layer_ids = [...scene.layer_ids]

    const [moved_id] = new_layer_ids.splice(index, 1)

    new_layer_ids.splice(Math.max(0, index + delta), 0, moved_id)

    scene.layer_ids = new_layer_ids
  }
}

/**
 * @param {object} opts
 * @param {import('@pogzul/engine').Scene} opts.scene
 * @param {number[]} opts.layer_ids
 * @returns {number} - The group id
 */
export const group_layers = ({ scene, layer_ids }) => {
  const group = new Group({
    layer_ids: layer_ids.flat(),
    scene
  })

  const index = scene.layer_ids.indexOf(group.layer_ids[0])

  scene.layer_ids[index] = group.id
  scene.layer_ids = scene.layer_ids.filter(
    (id) => !group.layer_ids.includes(id)
  )

  scene.entities[group.id] = group

  return group.id
}

/**
 * @param {(number | number[])[]} group_ids
 * @returns {number[]} - The layer ids
 */

/**
 * @param {object} opts
 * @param {import('@pogzul/engine').Scene} opts.scene
 * @param {number[]} opts.group_ids
 * @returns {number[]} - The layer ids
 */
export const ungroup_layers = ({ scene, group_ids }) => {
  /** @type {number[]} */
  let layer_ids = []

  for (let group_id of group_ids.flat()) {
    const group = scene.entities[group_id]

    if (!(group instanceof Group)) return group_ids.flat()

    const index = scene.layer_ids.indexOf(group_id)

    let { [group_id]: _layer, ...rest } = scene.entities

    scene.entities = rest
    scene.layer_ids = [
      ...scene.layer_ids.slice(0, index),
      ...group.layer_ids,
      ...scene.layer_ids.slice(index + 1)
    ]

    layer_ids = [...layer_ids, ...group.layer_ids]
  }

  return layer_ids
}

/** @param {'Shape' | 'Group' | 'Text'} type */
export const entity_name_for = (type) => {
  const entities_of_type = Object.values(active.scene.entities).filter(
    (entity) => entity.type === type
  ).length

  return `${type} ${entities_of_type + 1}`
}

/**
 * @param {Group | Shape | Text} layer
 * @param {number} x - The exact x position in the scene
 * @param {number} y - The exact y position in the scene
 * @returns {ReturnType<Pixels['get']>}
 */
export const get_pixel = (layer, x, y) => {
  if (layer instanceof Text) return

  if (layer instanceof Group) {
    for (let id of layer.layer_ids) {
      const pixel = get_pixel(active.scene.entities[id], x, y)

      if (pixel) return pixel
    }
  } else {
    const scale = 1

    return layer.pixels.get(
      grid_coordinate((x - layer.x) / scale),
      grid_coordinate((y - layer.y) / scale)
    )
  }
}

/**
 * @param {Group | Shape | Text} layer
 * @param {Uint8Array} color
 */
export const set_color = (layer, color) => {
  if ('pixels' in layer) {
    const parsed_color = parse_color(color)

    for (let [x, row] of layer.pixels) {
      for (let [y] of row) {
        layer.pixels.set(x, y, parsed_color)
      }
    }

    update_shape(layer)
  } else if (layer instanceof Group) {
    for (let id of layer.layer_ids) {
      set_color(active.scene.entities[id], color)
    }
  } else if (layer instanceof Text) {
    layer.color = color
  }
}

/** @param {Shape} shape */
export const update_shape = (shape) => {
  const { left, right, top, bottom } = shape.pixels.computeBounds()

  shape.x += left * config.pixel_size
  shape.y += top * config.pixel_size

  shape.width = (right - left) * config.pixel_size
  shape.height = (bottom - top) * config.pixel_size

  if (left !== 0 || top !== 0) {
    let pixels = new Pixels()

    for (let [x, row] of shape.pixels) {
      for (let [y, color] of row) {
        pixels.set(x - left, y - top, color)
      }
    }

    shape.pixels = pixels
  }

  let canvas = new Canvas()

  canvas.width = shape.width
  canvas.height = shape.height

  canvas.render((ctx) => {
    render_pixels_canvas_2d({ ctx, shape })
  })

  canvases[shape.id] = canvas

  textures.get(shape.id)?.update(canvas.element)
}
