import { config, rgb_array_to_hex } from '@pogzul/engine'

const { pixel_size, canvas_size } = config

export const import_image = async (src) =>
  new Promise((resolve) => {
    let canvas = document.createElement('canvas')

    canvas.width = canvas_size
    canvas.height = canvas_size

    let ctx = canvas.getContext('2d')

    let image = new Image()

    image.onload = function () {
      const { width, height } = image

      const scaling_factor = Math.max(width, height) / canvas_size

      ctx.drawImage(
        image,
        0,
        0,
        width / scaling_factor,
        height / scaling_factor
      )

      const image_data = ctx.getImageData(0, 0, canvas_size, canvas_size).data

      resolve(pixelize_image_data(image_data))
    }

    image.src = src
  })

const pixelize_image_data = (image_data) => {
  let pixels = {}

  for (let x = 0; x < canvas_size / pixel_size; x++) {
    for (let y = 0; y < canvas_size / pixel_size; y++) {
      let pixel = get_pixel(image_data, x, y)

      if (pixel) (pixels[x] = pixels[x] || {})[y] = pixel
    }
  }

  return pixels
}

const get_pixel = (image_data, x, y) => {
  let [r, g, b, a] = [[], [], [], []]

  let firstIndex = y * pixel_size * canvas_size * 4 + x * pixel_size * 4

  for (let j = 0; j < pixel_size; j++) {
    let posY = firstIndex + j * canvas_size * 4

    for (let i = 0; i < pixel_size; i++) {
      let index = posY + i * 4

      r.push(image_data[index])
      g.push(image_data[index + 1])
      b.push(image_data[index + 2])
      a.push(image_data[index + 3])
    }
  }

  let alpha = mean(a) / 255

  if (alpha < 0.1) {
    return null
  } else {
    return rgb_array_to_hex([mean(r), mean(g), mean(b), alpha])
  }
}

const mean = (arr) => arr.reduce((sum, val) => sum + val, 0) / arr.length
