<script>
  import { Icon } from '@pogzul/artboard'
  import { preferences } from '$lib/stores'

  const toggle_theme = () => {
    preferences.value = { ...preferences.value, theme: (preferences.value.theme === 'light' ? 'dark' : 'light' ) }

    document.documentElement.setAttribute('data-theme', preferences.value.theme)
  }
</script>

<label class="btn btn-square btn-sm swap swap-rotate">
  <!-- this hidden checkbox controls the state -->
  <input type="checkbox" checked={preferences.value.theme === 'light'} on:change={toggle_theme} />

  <Icon name="sun" class="swap-off h-4 w-4 text-yellow-400" />

  <Icon name="moon" class="swap-on h-4 w-4" />
</label>