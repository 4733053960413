import { config } from '../config'
import { grid_coordinate } from './coordinates'
import { render_pixels_canvas_2d } from './rendering'

import { Canvas } from '../models/Canvas'
import { Group, Shape } from '../models'

/** @param {import('@pogzul/engine').Scene} scene */
export const scene_image = (scene) => {
  const canvas = new Canvas()

  canvas.width = config.canvas_size
  canvas.height = config.canvas_size

  /** @type {(Text | Shape)[]} */
  // @ts-ignore
  const layers = scene.layer_ids.reduce((acc, id) => {
    const entity = scene.entities[id]

    if (entity instanceof Group) return [...acc, ...entity.layers]

    return [...acc, entity]
  }, [])

  canvas.render((ctx) => {
    for (let layer of layers) {
      if (layer instanceof Text || layer.is_hidden) continue

      if ('pixels' in layer) {
        ctx.setTransform(1, 0, 0, 1, layer.x, layer.y)
        render_pixels_canvas_2d({ ctx, shape: layer })
      }
    }
  })

  return canvas.image
}

/** @param {import('@pogzul/engine').Scene} scene */
export const pixel_grid = (scene) => {
  /** @type {Record<number, Record<number, Uint8Array>>} */
  let grid = {}

  /** @type {(Text | Shape)[]} */
  // @ts-ignore
  const layers = scene.layer_ids.reduce((acc, id) => {
    const entity = scene.entities[id]

    if (entity instanceof Group) return [...acc, ...entity.layers]

    return [...acc, entity]
  }, [])

  for (let layer of layers) {
    if (layer instanceof Text || layer.is_hidden) continue

    for (let [x, row] of layer.pixels) {
      for (let [y, pixel_color] of row) {
        const x_pos = x + grid_coordinate(layer.x),
          y_pos = y + grid_coordinate(layer.y)

        ;(grid[x_pos] ??= {})[y_pos] = pixel_color
      }
    }
  }

  return grid
}
