import { class_function_wrapper } from '../internal'
import { parse_color } from '@pogzul/engine'

/**
 * @param {number} r
 * @param {number} g
 * @param {number} b
 */
// function Color(r = 0, g = 0, b = 0) {
//   if (!new.target) return new Color(r, g, b)

//   return new Uint8Array([r, g, b])
// }

export class Color extends Uint8Array {
  /** @param {string | Uint8Array | number[]} color */
  constructor(color) {
    if (typeof color === 'string') {
      super(parse_color(color))
    } else {
      super(color)
    }
  }
}

export const color = class_function_wrapper(Color)
