import type { Collision, CollisionCallback } from './colliding'
import type { Tag } from '@pogzul/engine'

import {
  Group,
  Shape,
  config,
  last_entity_positions,
  get_pixel,
  active
} from '@pogzul/engine'
import { is_not_colliding_entity_entity } from './entities'

export function collision_shape_group(
  shape: Shape,
  group: Group,
  callback: CollisionCallback
) {
  if (is_not_colliding_entity_entity(shape, group)) return

  for (let id of group.layer_ids) {
    const target = active.scene.entities[id]

    if (target instanceof Group)
      return collision_shape_group(shape, target, callback)

    collision_shape_shape(shape, target, callback)
  }
}

export function collision_shape_scene(
  shape: Shape,
  callback: CollisionCallback
) {
  for (let id in active.scene.entities) {
    const entity = active.scene.entities[id]
    if (!(entity instanceof Shape) || entity.id === shape.id) continue // Don't check same-layer collisions

    collision_shape_shape(shape, entity, callback)
  }
}

export function collision_shape_tag(
  shape: Shape,
  tag: Tag,
  callback: CollisionCallback
) {
  const entity_ids = active.scene.components.tags[tag] || []

  for (let id of entity_ids) {
    const entity = active.scene.entities[id]

    if (!(entity instanceof Shape)) continue

    collision_shape_shape(shape, entity, callback)
  }
}

export function collision_shape_shape(
  shape: Shape,
  target: Shape,
  callback: CollisionCallback
) {
  if (is_not_colliding_entity_entity(shape, target)) return

  const last_positions = last_entity_positions.get(active.scene.id)

  const { pixel_size } = config

  const { x: old_x, y: old_y } = last_positions?.get(shape.id) || shape
  const { x: old_target_x, y: old_target_y } =
    last_positions?.get(target.id) || target

  const dx_shape = shape.x - old_x,
    dy_shape = shape.y - old_y

  const dx_target = target.x - old_target_x,
    dy_target = target.y - old_target_y

  const dx = dx_shape - dx_target,
    dy = dy_shape - dy_target

  let collision: Collision = {}

  const left_collision = (x: number, y: number) => {
    const x_pos = shape.x + x * pixel_size

    let y_pos = old_y + y * pixel_size

    const top_left = get_pixel(target, x_pos, y_pos)
    if (top_left) {
      return {
        x: x_pos,
        y: y_pos
      }
    }

    y_pos = old_y + (y + 0.9) * pixel_size

    const top_right = get_pixel(target, x_pos, y_pos)
    if (top_right)
      return {
        x: x_pos,
        y: y_pos
      }
  }

  const right_collision = (x: number, y: number) => {
    const x_pos = shape.x + (x + 0.9) * pixel_size

    let y_pos = old_y + y * pixel_size

    const top_right = get_pixel(target, x_pos, y_pos)
    if (top_right)
      return {
        x: x_pos,
        y: y_pos
      }

    y_pos = old_y + (y + 0.9) * pixel_size

    const bottom_right = get_pixel(target, x_pos, y_pos)
    if (bottom_right)
      return {
        x: x_pos,
        y: y_pos
      }
  }

  const top_collision = (x: number, y: number) => {
    const y_pos = shape.y + y * pixel_size

    let x_pos = shape.x - dx_shape + x * pixel_size

    const top_left = get_pixel(target, x_pos, y_pos)
    if (top_left)
      return {
        x: x_pos,
        y: y_pos
      }

    x_pos = shape.x - dx_shape + (x + 0.9) * pixel_size

    const top_right = get_pixel(target, x_pos, y_pos)
    if (top_right)
      return {
        x: x_pos,
        y: y_pos
      }
  }

  const bottom_collision = (x: number, y: number) => {
    const y_pos = shape.y + (y + 0.9) * pixel_size

    let x_pos = shape.x - dx_shape + x * pixel_size

    const bottom_left = get_pixel(target, x_pos, y_pos)
    if (bottom_left)
      return {
        x: x_pos,
        y: y_pos
      }

    x_pos = shape.x - dx_shape + (x + 0.9) * pixel_size

    const bottom_right = get_pixel(target, x_pos, y_pos)
    if (bottom_right)
      return {
        x: x_pos,
        y: y_pos
      }
  }

  if (dx === 0 && dy === 0) return

  /* Narrowphase by the pixels */
  for (let [x, row] of shape.pixels) {
    for (let [y] of row) {
      if (dx < 0) {
        const left = left_collision(x, y)
        if (left) {
          collision.left = true
          collision.x = left.x
          collision.y = left.y
        }
      } else if (dx > 0) {
        const right = right_collision(x, y)
        if (right) {
          collision.right = true
          collision.x = right.x
          collision.y = right.y
        }
      }

      if (dy < 0) {
        const top = top_collision(x, y)
        if (top) {
          collision.top = true
          collision.x ??= top.x
          collision.y ??= top.y
        }
      } else if (dy > 0) {
        const bottom = bottom_collision(x, y)
        if (bottom) {
          collision.bottom = true
          collision.x ??= bottom.x
          collision.y ??= bottom.y
        }
      }
    }
  }

  if ('x' in collision) {
    callback({ layer: shape, target, collision, resolve: () => {} })
  }
}
