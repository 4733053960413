<script>
  import { useForm } from '@inertiajs/svelte'
  import { Icon } from '@pogzul/artboard'

  const form = useForm()

  const { project_id, class: class_name } = $props()

  function fork_project(evt) {
    $form.post(this.action)
  }
</script>

<form action="/projects/{project_id}/forks" method="post" on:submit={fork_project}>
  <div class="dropdown dropdown-hover dropdown-bottom dropdown-end">
    <button class="btn btn-sm {class_name}">
      <Icon name="fork" class="h-3.5 w-3.5" />
      Fork
    </button>

    <div class="dropdown-content z-[1] shadow bg-base-100/90 p-2 mt-2">
      <p class="whitespace-nowrap text-xs !font-sans">Create your own copy of this project.</p>
    </div>
  </div>
</form>
