<script lang="ts">
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'
  import Canvas from './Canvas'
  import { state, clipboard } from '../stores'

  import { config, active } from '@pogzul/engine'

  type Props = {
    project: import('@pogzul/app').ProjectStore
    persist_store: any
    present_users: PresentUsersStore,
    render: () => number
  }

  let {
    project,
    persist_store = null,
    present_users = writable([]),
    render
  } = $props<Props>()

  onMount(() => project.subscribe(render))

  if (persist_store) persist_store('clipboard', clipboard, { sync: true })

  let canvas_el: HTMLCanvasElement

  // The element used for interactions
  let canvas_target_el: HTMLElement

  const active_scene_id = $derived($project.scenes[$state.active_scene_ids[0]] ? $state.active_scene_ids[0] : $project.scene_ids[0])

  $effect(() => active.scene = $project.scenes[active_scene_id])
</script>

<div
  class="artboard grid gap-3 h-full p-3"
  style="
    grid-template-columns: 1fr {config.canvas_size}px 1fr;
    grid-template-rows: 1fr {config.canvas_size}px 1fr;
  "
>
  <div class="col-start-2 z-10 flex justify-between items-end">
    <slot name="actions" />
  </div>

  <div class="row-start-2 col-start-2">
    <Canvas
      bind:canvas_el
      bind:canvas_target_el
      bind:render
      editable={false}
      {present_users}
    />
  </div>
</div>

<style lang="postcss">
  .artboard > * {
    pointer-events: none;
  }

  .artboard > * > :global(*) {
    pointer-events: auto;
  }
</style>
