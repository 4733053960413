<script context="module">
  import BaseLayout from '../layouts/BaseLayout.svelte'

  export const layout = [BaseLayout]
</script>

<script>
  import { onMount } from 'svelte'
  import { Experience, Icon, artboard_state } from '@pogzul/artboard'
  import { Project, Runtime, is_running } from '@pogzul/engine'

  import { project } from '$lib/stores'
  import { initialize_document } from '$lib/realtime'
  import { ThemeSwitcher, ForkProject } from '$lib/components'

  export let experience

  export let current_user

  const doc = initialize_document(experience)
  $project = Project.from(doc, '')

  if (!$artboard_state.active_scene_ids.length) $artboard_state.active_scene_ids = [$project.scene_ids[0]]

  /** @type {() => number} */
  let render

  onMount(() => {
    const runtime = new Runtime({
      render: () => render(),
      document: project,
      state: artboard_state,
      terminal: undefined,
      artboard_target: document.getElementById("artboard-target"),
    })

    return () => runtime.stop()
  })
</script>

<div class="h-full flex" id="editor">
  <div class="relative flex-1">
    {#if current_user && experience.is_forkable}
      <div class="absolute top-3 right-3 z-10">
        <div class="join">
          <ForkProject class="join-item" project_id={experience.project_id} />

          <div class="dropdown dropdown-hover dropdown-end">
            <div tabindex="0" role="button" class="btn btn-sm btn-square join-item mb-2">
              <Icon name="chevron-up" class="h-3.5 w-3.5 rotate-180" />
            </div>

            <ul class="dropdown-content z-[1] menu p-0 shadow bg-base-200">
              <li>
                <a class="whitespace-nowrap" href="/projects/{experience.project_id}">View source</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    {/if}

    <Experience
      bind:render
      {project}
    >
      <div slot="actions" class="w-full flex justify-between">
        <h1 class="text-xl">{$project.name}</h1>

        <button class="btn btn-sm" on:click={() => $is_running = !$is_running}>
          {$is_running ? 'Stop' : 'Play' }
        </button>
      </div>
    </Experience>

    <div class="flex space-x-2 absolute bottom-3 right-3 z-10">
      <ThemeSwitcher />
    </div>
  </div>
</div>

<svelte:window on:keydown|preventDefault />