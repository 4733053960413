import { Font } from './Font.js'

import * as RetroGaming from './RetroGaming'

export { default as ASCII_CHARS } from './ascii_charset.txt'

/** @type {Record<string, Font>} */
const fonts = {}

if (typeof window !== 'undefined') {
  const image = new Image()

  image.onload = () => {
    fonts['RetroGaming'] = new Font({
      atlas: image,
      config: RetroGaming.config
    })
  }

  image.src = RetroGaming.msdf
}

export { Font, fonts }
