import { Callback } from '../internal'

/**
 * @param {function():any} callback
 * @param {Partial<{ interval: number }>} [options]
 * @this {import('@pogzul/engine').Runtime}
 */
export function effect(callback, options) {
  this.effects.unshift(new Callback(callback, options?.interval || 0))
}
