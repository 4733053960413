import { Callback } from '../internal'

/**
 * @callback KeyboardEventHandler
 * @param {string} key
 * @param {function(import('../internal').RuntimeEvent):any} cb
 * @param {number} [interval] - Interval in seconds
 */

/**
 * @param {keyof typeof this.keyboard_callbacks} name
 * @param {string} key
 * @param {function(import('../internal').RuntimeEvent):any} cb
 * @param {number} [interval] - Interval in seconds
 * @this {import('@pogzul/engine').Runtime}
 */
function create_keyboard_callback(name, key, cb, interval = 0) {
  let func = cb

  if (typeof key === 'function') {
    func = key
  }

  let callback = new Callback(func, interval, {
    condition: (evt) => (typeof key == 'string' ? evt.key === key : true)
  })

  this.keyboard_callbacks[name].push(callback)
}

/**
 * @type {KeyboardEventHandler}
 * @this {import('@pogzul/engine').Runtime}
 */
export function keypress(...args) {
  return create_keyboard_callback.call(this, 'keypress', ...args)
}

/**
 * @type {KeyboardEventHandler}
 * @this {import('@pogzul/engine').Runtime}
 */
export function keydown(...args) {
  return create_keyboard_callback.call(this, 'keydown', ...args)
}

/**
 * @type {KeyboardEventHandler}
 * @this {import('@pogzul/engine').Runtime}
 */
export function keyup(...args) {
  return create_keyboard_callback.call(this, 'keyup', ...args)
}

const attachArrowKeys_options = {
  velocity: 200,
  up: true,
  right: true,
  down: true,
  left: true
}
/**
 * @param {import('@pogzul/engine').Layer} layer
 * @param {Partial<attachArrowKeys_options>} options
 * @this {import('@pogzul/engine').Runtime}
 */
export function attachArrowKeys(layer, options = {}) {
  const opts = { ...attachArrowKeys_options, ...options }

  opts.up &&
    keydown.call(this, 'up', () => (layer.y -= opts.velocity * this.dt()))
  opts.down &&
    keydown.call(this, 'down', () => (layer.y += opts.velocity * this.dt()))
  opts.left &&
    keydown.call(this, 'left', () => (layer.x -= opts.velocity * this.dt()))
  opts.right &&
    keydown.call(this, 'right', () => (layer.x += opts.velocity * this.dt()))
}

/**
 * @param {string} code
 * @param {function(import('../internal').RuntimeEvent):any} cb
 * @param {number} [interval] - Interval in seconds
 * @this {import('@pogzul/engine').Runtime}
 */
function create_wasd_callback(code, cb, interval = 0) {
  let callback = new Callback(cb, interval, {
    condition: (evt) => evt.code === code
  })

  this.keyboard_callbacks.keydown.push(callback)
}

const attachWasdKeys_options = {
  velocity: 200,
  w: true,
  a: true,
  s: true,
  d: true
}

/**
 * @param {import('@pogzul/engine').Layer} layer
 * @param {Partial<attachWasdKeys_options>} options
 * @this {import('@pogzul/engine').Runtime}
 */
export function attachWasdKeys(layer, options = {}) {
  const opts = { ...attachWasdKeys_options, ...options }

  opts.w &&
    create_wasd_callback.call(
      this,
      'KeyW',
      () => (layer.y -= opts.velocity * this.dt())
    )
  opts.a &&
    create_wasd_callback.call(
      this,
      'KeyA',
      () => (layer.x -= opts.velocity * this.dt())
    )
  opts.s &&
    create_wasd_callback.call(
      this,
      'KeyS',
      () => (layer.y += opts.velocity * this.dt())
    )
  opts.d &&
    create_wasd_callback.call(
      this,
      'KeyD',
      () => (layer.x += opts.velocity * this.dt())
    )
}
