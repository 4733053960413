import Tool from './Tool.js'

import { grid_coordinate, colors_are_equal, draw } from '@pogzul/engine'
import { state } from '../stores'

class BrushTool extends Tool {
  /** @type {'Brush'} */
  name = 'Brush'

  shortcut = 'b'

  /** @type {boolean} */
  #is_dragging = false

  /** @type {Uint8Array | undefined} */
  #dragging_color = undefined

  /** @param {import('./ToolEvent').default} evt */
  onMouseDown(evt) {
    super.onMouseDown(evt)

    this.#draw(evt)
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseUp(evt) {
    const result = draw({
      scene: evt.scene,
      pixels: this.pixels,
      layer_ids: evt.active_layer_ids
    })

    if (result) {
      state.update((old_state) => ({
        ...old_state,
        active_layer_ids: typeof result === 'number' ? [] : [result.id]
      }))
    }

    this.#is_dragging = false
    this.#dragging_color = undefined

    return super.onMouseUp(evt)
  }

  /** @param {import('./ToolEvent').default} evt */
  onMouseMove(evt) {
    if (!evt.is_mouse_down) return

    this.#draw(evt)

    super.onMouseMove(evt)
  }

  /* Private */

  /** @param {import('./ToolEvent').default} evt */
  #draw({ x, y, active_color, scene, active_layer_ids }) {
    const active_layer = active_layer_ids.length
      ? scene.entities[active_layer_ids[0]]
      : null

    /* Erase pixels if the selected one is the same as the top layer */
    let initial_color = active_color

    if (active_layer && 'pixels' in active_layer) {
      const layer_pixel_color = active_layer.pixels.get(
        x - grid_coordinate(active_layer.x),
        y - grid_coordinate(active_layer.y)
      )

      if (colors_are_equal(active_color, layer_pixel_color)) {
        initial_color = null
      }
    }

    this.pixels.set(
      x,
      y,
      this.#is_dragging ? this.#dragging_color : initial_color,
      true
    )

    // Persist the previous color for long drags
    if (!this.#is_dragging) {
      this.#is_dragging = true
      this.#dragging_color = initial_color
    }
  }
}

export default BrushTool
