import { get } from 'svelte/store'
import { evaluate } from './evaluate.js'
import { Console } from './Console.js'
import { Scope } from './Scope.js'
import { addEventListener, removeEventListener } from '../functions'
import { SUPPORTED_EVENTS } from '../functions/events/config'
import { config, pick, is_running, last_entity_positions } from '@pogzul/engine'
import { StopExecutionError } from './errors'

export class Runtime {
  /** @type {import('../functions/internal').Callback[]} */
  effects = []

  /** @type {Record<'keypress' | 'keydown' | 'keyup', any[]>} */
  keyboard_callbacks = create_keyboard_callbacks()

  /** @type {Record<string, import('../functions/internal').RuntimeEvent>} */
  keydown_events = {}

  /**
   * @type {Partial<
   *   Record<keyof typeof SUPPORTED_EVENTS, Record<string, function>>
   * >}
   */
  event_listeners = {}

  is_running = false

  /** @type {number} */
  previous_frame_s = 0

  /** @type {Function} */
  render = () => {}

  /** @type {Terminal | undefined} */
  terminal

  /**
   * @type {import('svelte/store').Writable<
   *   import('@pogzul/engine').Project
   * >}
   */
  document

  /** @type {import('yjs').Text} */
  ytext

  /** @type {any} */
  state

  /** @type {HTMLElement} */
  artboard_target

  /**
   * @param {Pick<
   *   Runtime,
   *   'render' | 'terminal' | 'document' | 'state' | 'artboard_target'
   * >} config
   */
  constructor(config) {
    Object.assign(
      this,
      pick(
        config,
        'render',
        'terminal',
        'document',
        'ytext',
        'state',
        'artboard_target'
      )
    )

    this.console = new Console(this)

    this.frame = handle_errors.call(this, this.frame.bind(this))
    this.execute = handle_errors.call(this, this.execute.bind(this))
    this.dt = this.dt.bind(this)

    let is_running_initialized = false
    is_running.subscribe((new_is_running) => {
      if (!is_running_initialized) return (is_running_initialized = true)

      if (new_is_running) {
        this.start()
        this.execute(this.active_file)
      } else {
        this.stop()
      }
    })
  }

  dt() {
    return Date.now() / 1000 - this.previous_frame_s
  }

  start() {
    this.terminal?.clear()
    is_running.set((this.is_running = true))
    this.previous_frame_s = 0
    requestAnimationFrame(this.frame)
  }

  stop() {
    is_running.set((this.is_running = false))

    for (let event in this.event_listeners) {
      for (let listener in this.event_listeners[event]) {
        removeEventListener.call(this, event, listener)
      }
    }
  }

  get scenes() {
    const { scenes, scene_ids } = get(this.document)

    return scene_ids.map((id) => scenes[id])
  }

  /** @returns {import('@pogzul/engine').Scene} */
  get active_scene() {
    const { scenes } = get(this.document)

    const [active_scene_id] = get(this.state).active_scene_ids

    return scenes[active_scene_id]
  }

  update_entity_positions() {
    const scene = this.active_scene

    const positions = new Map()

    for (const id in scene.entities) {
      positions.set(Number(id), scene.entities[id].position)
    }

    last_entity_positions.set(scene.id, positions)
  }

  set active_scene(scene) {
    this.state.update((old_state) => ({
      ...old_state,
      active_scene_ids: [scene.id]
    }))
  }

  get active_file() {
    return this.ytext.toString()
  }

  get window() {
    return new RuntimeWindow(this)
  }

  /** @param {string} script */
  async execute(script) {
    this.reset()

    addEventListener.call(this, 'keydown', (evt) => {
      // Don't run if the key is currently pressed
      if (!this.keydown_events[evt.key]) {
        for (let cb of this.keyboard_callbacks['keypress']) {
          cb.call(evt)
        }
      }

      this.keydown_events[evt.key] = evt
    })

    addEventListener.call(this, 'keyup', (evt) => {
      // Don't run unless the key is currently pressed
      if (this.keydown_events[evt.key]) {
        for (let cb of this.keyboard_callbacks['keyup']) {
          cb.call(evt)
        }
      }

      delete this.keydown_events[evt.key]
    })

    const result = await evaluate(
      this.console,
      script,
      new Scope({ runtime: this }),
      {}
    )

    if (typeof result !== 'undefined') this.stop()
  }

  reset() {
    this.keyboard_callbacks = create_keyboard_callbacks()
    this.keydown_events = {}
    this.event_listeners = {}
    this.update_entity_positions()
    this.effects = []
  }

  /** @param {number} _elapsed_ms */
  frame(_elapsed_ms) {
    if (!this.is_running) return

    const now = Date.now() / 1000

    for (let key in this.keydown_events) {
      for (let cb of this.keyboard_callbacks['keydown']) {
        cb.call(this.keydown_events[key])
      }
    }

    // this.inputCallbacks.flush();
    // Input
    // Updates
    // Effects

    const delta_s = now - (this.previous_frame_s || now)
    for (let entity of Object.values(this.active_scene.entities)) {
      // entity.update(delta_s)
    }

    for (let i = 0, length = this.effects.length; i < length; i++) {
      this.effects[i].call(this)
    }

    this.update_entity_positions()

    this.render()

    this.previous_frame_s = now

    requestAnimationFrame(this.frame)
  }
}

/**
 * @param {function} func
 * @this {Runtime}
 */
function handle_errors(func) {
  return (...args) => {
    try {
      return func(...args)
    } catch (exception) {
      if (
        exception instanceof Error &&
        !(exception instanceof StopExecutionError)
      )
        this.console.error(`${exception.name}: ${exception.message}`)
    }
  }
}

const create_keyboard_callbacks = () => ({
  keypress: [],
  keydown: [],
  keyup: []
})

export class RuntimeWindow {
  /** @param {Runtime} runtime */
  constructor(runtime) {
    this.name = 'Pogzul'
    this.width = config.canvas_size
    this.height = config.canvas_size
    this.pixel_size = config.pixel_size
    this.console = runtime.console
  }
}

export default Runtime
