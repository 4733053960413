import * as Y from 'yjs'
import { set_world } from '../stores'

/**
 * @param {import('$lib/db').Tables<'projects'>} record
 * @returns {Y.Doc}
 */
export const initialize_document = (record) => {
  const doc = new Y.Doc()

  if (record.document) {
    Y.applyUpdateV2(doc, decode_hex_to_bytes(record.document))
  }

  /** @type {World} */
  // @ts-ignore
  const map = doc.getMap()

  map.set('name', record.name)

  if (!map.has('ytexts')) {
    /** @type {Y.Map<Y.Text>} */
    // @ts-ignore
    const text_files = map.get('text_files')

    map.set('ytexts', text_files.clone())
    map.set('ytext_ids', Array.from(text_files.keys()).map(Number))

    map.delete('text_files')
    map.delete('file_ids')
    map.delete('files')
  }

  set_world(map)

  return doc
}

/**
 * @param {Y.Map} map
 * @param {import('@pogzul/engine').Project} project
 */
export const update_document = (map, project) => {
  map.set('name', project.name)
  map.set('scenes', JSON.parse(JSON.stringify(project.scenes)))
  map.set('scene_ids', project.scene_ids)
}

/** @param {string} hex */
const decode_hex_to_bytes = (hex) => {
  if (!/^[0-9a-fA-F]*$/.test(hex) || hex.length % 2 !== 0) {
    throw new Error('Invalid hex string')
  }

  const array = new Uint8Array(hex.length / 2)

  for (let i = 0; i < hex.length / 2; i++) {
    const byte = parseInt(hex.substring(i * 2, i * 2 + 2), 16)
    array[i] = byte
  }

  return array
}
