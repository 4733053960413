import { config } from '@pogzul/engine'

/**
 * Get the grid coordinate from a position on the canvas.
 *
 * @param {number} value
 */
export const grid_coordinate = (value) => Math.floor(value / config.pixel_size)

/**
 * Get the canvas coordinate from a grid position.
 *
 * @param {number} value
 */
export const canvas_coordinate = (value) =>
  grid_coordinate(value) * config.pixel_size

/**
 * @param {object} opts
 * @param {number} opts.mouse_x
 * @param {number} opts.mouse_y
 * @param {{ x: number; y: number }} [opts.offsets]
 */
export const normalize_mouse_coordinates = ({
  mouse_x,
  mouse_y,
  offsets = { x: 0, y: 0 }
}) => ({
  x: grid_coordinate(mouse_x - offsets.x),
  y: grid_coordinate(mouse_y - offsets.y)
})
