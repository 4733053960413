<script>
  import { tools } from '../../tools'
  import { state } from '../../stores'
  import { Icon } from '../../components'

  /**
   * @type {(keyof typeof import('../../tools').tools)[]}
   * @ts-ignore */
  const available_tools = Object.keys(tools)
</script>

<!-- <div class="inline-flex flex-col justify-between"> -->
  <div class="space-y-1.5 flex flex-col">
    {#each available_tools as tool_key}
      {@const tool = tools[tool_key]}

      <div class="dropdown dropdown-hover dropdown-right">
        <button
          class="btn btn-square btn-sm"
          class:!border-primary={$state.active_tool === tool_key}
          on:click={ () => $state.active_tool = tool_key }
        >
          <Icon name={tool_key} class="h-5 w-5" />
        </button>

        <div class="dropdown-content z-[1] shadow bg-base-100/90 p-1 ml-1.5">
          <p class="flex items-center text-xs pl-2">{ tool.name } <kbd class="ml-2 kbd kbd-sm">{tool.shortcut}</kbd></p>
        </div>
      </div>
    {/each}
  </div>
<!--
  <div>
    <button
      class="btn btn-square btn-sm"
    >
      {@html icons.eraser('h-5 w-5')}
    </button>
  </div> -->
<!-- </div> -->
