import { rgb_array_to_string, config } from '@pogzul/engine'

/**
 * @param {object} opts
 * @param {CanvasRenderingContext2D} opts.ctx
 * @param {import('@pogzul/engine/models').Shape} opts.shape
 */
export const render_pixels_canvas_2d = ({ ctx, shape }) => {
  for (let [x, row] of shape.pixels) {
    for (let [y, color] of row) {
      ctx.fillStyle = rgb_array_to_string(color)

      ctx.fillRect(
        x * config.pixel_size,
        y * config.pixel_size,
        config.pixel_size,
        config.pixel_size
      )
    }
  }
}

/**
 * @param {object} opts
 * @param {CanvasRenderingContext2D} opts.ctx
 * @param {import('@pogzul/engine/models').Shape} opts.shape
 */
export const render_text_canvas_2d = ({ ctx, text }) => {
  ctx.fillStyle = rgb_array_to_string('red')

  ctx.font = '24px RetroGaming'

  ctx.fillText(text.content, text.x, text.y, text.width)
}
