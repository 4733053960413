import type { Collision, CollisionCallback } from './colliding.ts'
import type { Entity, Runtime, RuntimeWindow } from '@pogzul/engine'

export const collision_entity_window = (
  entity: Entity,
  window: RuntimeWindow,
  callback: CollisionCallback
) => {
  let collision: Collision = {}

  if (entity.x < 0) {
    collision ??= {}
    collision.left = true
    collision.x = entity.x
    collision.y = entity.y
  } else if (entity.x + entity.width > window.width) {
    collision ??= {}
    collision.right = true
    collision.x = entity.x + entity.width
    collision.y = entity.y
  }

  if (entity.y < 0) {
    collision ??= {}
    collision.top = true
    collision.x = entity.x
    collision.y = entity.y
  } else if (entity.y + entity.height > window.height) {
    collision ??= {}
    collision.bottom = true
    collision.x = entity.x
    collision.y = entity.y + entity.height
  }

  if ('x' in collision)
    callback({ layer: entity, target: window, collision, resolve: () => {} })
}

export function collision_window_scene(
  this: Runtime,
  window: RuntimeWindow,
  callback: CollisionCallback
) {
  const scene = this.active_scene

  for (let id in scene.entities) {
    collision_entity_window(scene.entities[id], window, callback)
  }
}

/* Broadphase AABB collision check */
export const is_not_colliding_entity_entity = (
  a: Entity,
  b: Entity
): boolean => {
  return (
    a.x > b.x + b.width ||
    a.x + a.width < b.x ||
    a.y > b.y + b.height ||
    a.y + a.height < b.y
  )
}
