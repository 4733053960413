<script lang="ts">
  import { Scene, scene_image, active } from '@pogzul/engine'
  import { state } from '../../stores'
  import { SortableList } from '../../components'

  const {
    project,
    editable = true
  } = $props<{ project: ProjectStore, editable: boolean }>()

  const sort_scenes = (evt) => {
    if (!editable) return

    const { oldIndex, newIndex } = evt.detail

    const scene_ids = [...$project.scene_ids]

    const [moved_id] = scene_ids.splice(oldIndex, 1)

    scene_ids.splice(newIndex, 0, moved_id)

    $project.scene_ids = scene_ids
  }

  const create_scene = () => {
    if (!editable) return

    active.scene = new Scene()

    $project.scenes[active.scene.id] = active.scene
    $project.scene_ids = [...$project.scene_ids, active.scene.id]

    $state.active_scene_ids = [active.scene.id]
  }

  const delete_scene = (id) => {
    if (!editable) return

    let { [id]: _scene, ...rest } = $project.scenes

    const old_index = $project.scene_ids.indexOf(id)

    $project.scenes = rest
    $project.scene_ids = $project.scene_ids.filter((scene_id) => scene_id !== id)

    if ($state.active_scene_ids.includes(id)) {
      const next_active_scene_id = $project.scene_ids[Math.min(old_index, $project.scene_ids.length - 1)]

      $state.active_scene_ids = [next_active_scene_id]
    }

    active.scene = $project.scenes[$state.active_scene_ids[0]]
  }
</script>

<div class="flex items-start select-none p-1.5 space-x-1.5 bg-base-300/50 dark:bg-base-100/90">
  <div class="">
    <SortableList
      class="flex space-x-1.5"
      list={$project.scene_ids}
      onsort={sort_scenes}
      let:item={id}
    >
      <div class="dropdown dropdown-hover dropdown-top">
        <button
          on:click={(evt) => {
            if (evt.shiftKey && $project.scene_ids.length > 1) {
              delete_scene(id)
            } else {
              $state.active_scene_ids = [id]
              active.scene = $project.scenes[id]
            }

            $state.active_layer_ids = []
            $project.scene_ids = $project.scene_ids
          }}
          on:focus
          class="flex-shrink-0 flex items-center justify-center"
        >
          <div class="btn btn-square" class:!border-success={$state.active_scene_ids.includes(id)}>
            <img
              src={scene_image($project.scenes[id]).src}
              class="max-h-8 w-8 object-contain crisp-edges"
              alt="Scene {id}"
            />
          </div>

          {#if editable && $project.scene_ids.length > 1}
            <div class="dropdown-content z-[1] shadow bg-base-100/90 p-2 mb-2">
              <p class="flex items-center text-xs whitespace-nowrap">
                <kbd class="kbd kbd-xs mr-1">Shift</kbd> + <kbd class="kbd kbd-xs mx-1">Click</kbd> to delete
              </p>
            </div>
          {/if}
        </button>
      </div>
    </SortableList>
  </div>

  {#if editable}
    <div class="dropdown dropdown-hover dropdown-top">
      <button on:click={create_scene} class="flex-shrink-0 flex items-center justify-center">
        <div class="btn btn-square">
          <svg class="h-6 w-6 text-content" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M80 60V20H60V60H20V80H60V120H80V80H120V60H80Z" fill="currentColor"/>
          </svg>
        </div>

        <div class="dropdown-content z-[1] shadow bg-base-100/90 p-2 mb-2">
          <p class="whitespace-nowrap text-xs">New scene</p>
        </div>
      </button>
    </div>
  {/if}
</div>