import { writable } from 'svelte/store'

/** @returns {ArtboardStateStore} */
export const state = writable({
  active_scene_ids: [],
  active_layer_ids: [],
  hovering_layer_ids: [],
  active_color: new Uint8Array([0, 0, 0]),
  cursor: null,
  editor_offsets: { x: 0, y: 0 },
  mouse_x: 0,
  mouse_y: 0,

  /** @type {keyof typeof import('../tools').tools} */
  active_tool: 'brush'
})

export { state as artboard_state }

// Should this be one object, or a class with individual state fields?
