import { Pixels, Scene, Shape, Group, config } from '@pogzul/engine'
import { update_shape } from '../utilities'

/**
 * @param {{
 *   scene: Scene
 *   pixels: Pixels
 *   layer_ids: number[]
 * }} params
 * @returns {Shape | number | false}
 */
export const draw = ({ scene, layer_ids, pixels }) => {
  let [layer_id, ...ids] = layer_ids

  // Don't draw on multiple layers at once
  if (ids.length > 0) return false

  const layer = layer_id ? scene.entities[layer_id] : null

  if (layer && 'pixels' in layer) {
    const normal_pixels = normalize_pixels({ layer, pixels })

    if (layer.pixels.size === 0) {
      delete_layers({ scene, layer_ids: [layer_id] })

      return layer_id
    } else {
      for (let [x, row] of normal_pixels) {
        for (let [y, color] of row) {
          layer.pixels.set(x, y, color)
        }
      }

      if (layer.pixels.size === 0) {
        delete_layers({ scene, layer_ids: [layer_id] })
        return layer_id
      }

      update_shape(layer)

      return layer
    }
  } else {
    const [shape] = add_shapes({ scene, shapes: [{ pixels }] })

    return shape
  }
}

/**
 * @param {{
 *   scene: Scene
 *   shapes: ConstructorParameters<typeof Shape>
 * }} params
 * @returns {Shape[]}
 */
export const add_shapes = ({ scene, shapes }) => {
  return [...shapes].flat().map((shape_data) => {
    const shape = new Shape({ ...shape_data, scene })

    scene.entities[shape.id] = shape

    scene.layer_ids = [...scene.layer_ids, shape.id]

    return shape
  })
}

/**
 * @param {{
 *   scene: Scene
 *   layer_ids: number[]
 * }} params
 * @returns {number[]}
 */
export const delete_layers = ({ scene, layer_ids }) => {
  /** @type {number[]} [description] */
  let deleted_ids = []

  for (let id of layer_ids.flat()) {
    delete scene.entities[id]

    deleted_ids.push(id)
  }

  scene.layer_ids = scene.layer_ids.filter((id) => !deleted_ids.includes(id))

  const groups = Object.values(scene.entities).filter(is_group)

  for (let group of groups) {
    group.layer_ids = group.layer_ids.filter((id) => !deleted_ids.includes(id))
  }

  return deleted_ids
}

/**
 * @param {any} item
 * @returns {item is Group}
 */
const is_group = (item) => item instanceof Group

/**
 * Takes an object of grid-normalized pixels and returns the layer-normalized
 * pixels.
 *
 * @param {Object} opts
 * @param {Shape} opts.layer
 * @param {Pixels} opts.pixels
 */
const normalize_pixels = ({ layer, pixels }) => {
  let normal_pixels = new Pixels()

  for (let [x, row] of pixels) {
    for (let [y, color] of row) {
      let nX = Number(x) - layer.x / config.pixel_size,
        nY = Number(y) - layer.y / config.pixel_size

      normal_pixels.set(nX, nY, pixels.get(x, y) || null, true)
    }
  }

  return normal_pixels
}
