import { javascript } from '@codemirror/lang-javascript'
import {
  keymap,
  lineNumbers,
  highlightActiveLineGutter,
  highlightActiveLine,
  drawSelection,
  highlightSpecialChars,
  rectangularSelection,
  dropCursor
} from '@codemirror/view'

import {
  syntaxHighlighting,
  bracketMatching,
  foldKeymap,
  foldGutter,
  indentOnInput
} from '@codemirror/language'

import { classHighlighter } from '@lezer/highlight'

import {
  defaultKeymap,
  indentWithTab,
  history,
  historyKeymap
} from '@codemirror/commands'

import { searchKeymap, highlightSelectionMatches } from '@codemirror/search'

import {
  autocompletion,
  acceptCompletion,
  completionKeymap,
  closeBrackets,
  closeBracketsKeymap
} from '@codemirror/autocomplete'

const custom_commands = [
  // Tab auto complete
  {
    key: 'Tab',
    run: acceptCompletion
  },
  // Escape to blur the editor
  {
    key: 'Escape',
    run: (view) => view.contentDOM.blur()
  }
]

export const extensions = [
  lineNumbers(),
  highlightSpecialChars(),
  drawSelection(),
  highlightActiveLineGutter(),
  // highlightActiveLine(),
  highlightSelectionMatches(),
  autocompletion(),
  closeBrackets(),
  bracketMatching(),
  dropCursor(),
  // foldGutter(),
  // rectangularSelection(),
  history(),
  indentOnInput(),
  syntaxHighlighting(classHighlighter, {
    fallback: true
  }),
  javascript(),
  keymap.of([
    ...custom_commands,
    ...defaultKeymap,
    ...searchKeymap,
    ...completionKeymap,
    ...foldKeymap,
    ...historyKeymap,
    ...closeBracketsKeymap,
    indentWithTab
  ])
  // javascriptLanguage.data.of({
  //   autocomplete: jsSnippets,
  // }),
]
