/**
 * @param {Tag} tag
 * @this {import('@pogzul/engine').Runtime}
 */
export function get(tag) {
  // const entities = Object.values(this.active_scene.entities).filter(
  //   (e) => e.tags[tag]
  // )
  const entities = []

  return entities
}
