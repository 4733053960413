import { Shape, Group, Text } from './layers'
import { Entity } from './Entity.js'

import { random_uint_32, pick } from '@pogzul/engine'

export class Scene {
  /** @type {number} */
  id = random_uint_32()

  /** @type {Record<number, Shape | Text | Group>} */
  entities = {}

  /** @type {Record<string, Component>} */
  components = {
    names: {},
    tags: {},
    positions: {},
    areas: {}
  }

  /** @type {number[]} */
  layer_ids = []

  /** @param {Partial<Scene>} opts */
  constructor(opts = {}) {
    Object.assign(this, pick(opts, 'id', 'entities', 'layer_ids'))

    this.components = {
      ...this.components,
      ...(opts.components || {})
    }

    const entity_ids = Object.keys(this.entities).map(Number)

    this.entities = entity_ids.reduce((entities, id) => {
      if (!this.entities[id]) return entities

      return {
        ...entities,
        [id]: Entity.parse({ ...this.entities[id], id, scene: this })
      }
    }, {})

    this.layer_ids = this.layer_ids.filter((id) => this.entities[id])
  }
}

export default Scene
