/**
 * @param {number} seconds
 * @param {function(number):any} [onTick]
 * @this {import('@pogzul/engine').Runtime}
 */
export async function countdown(seconds, onTick) {
  return new Promise((resolve) => {
    const tick = () => {
      if (seconds <= 0) return resolve(undefined)

      onTick ? onTick(seconds) : this.console.log(Math.round(seconds) + '...')
      seconds--

      setTimeout(tick, 1000)
    }

    tick()
  })
}
