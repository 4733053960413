import { deep_equal } from './utils.js'
import { Texture } from './Texture.js'
import { Shader } from './Shader.js'

import { STRIDE } from './config'

const MAX_BATCHED_QUAD = 2048

const MAX_BATCHED_VERTICES = MAX_BATCHED_QUAD * 4 * STRIDE,
  MAX_BATCHED_INDICES = MAX_BATCHED_QUAD * 6

export class BatchRenderer {
  /** @type {WebGLRenderingContext} */
  gl

  /** @type {number[]} */
  vertex_queue = []

  /** @type {number[]} */
  index_queue = []

  /** @type {Texture | null} */
  current_texture

  /** @type {Shader | null} */
  current_shader

  /** @type {import('./types').Uniform} */
  current_uniform

  /**
   * @param {object} params
   * @param {WebGLRenderingContext} params.gl
   */
  constructor({ gl }) {
    this.gl = gl

    /** Create index buffer */
    const index_buffer = gl.createBuffer()
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, index_buffer)
    gl.bufferData(
      gl.ELEMENT_ARRAY_BUFFER,
      MAX_BATCHED_INDICES * 4,
      gl.DYNAMIC_DRAW
    )

    /** Create vertex buffer */
    const vertex_buffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, vertex_buffer)
    gl.bufferData(gl.ARRAY_BUFFER, MAX_BATCHED_VERTICES * 4, gl.DYNAMIC_DRAW)
  }

  /**
   * @param {object} params
   * @param {number[]} params.vertices
   * @param {number[]} params.indices
   * @param {Texture} params.texture
   * @param {Shader} params.shader
   * @param {import('./types').Uniform} params.uniform
   */
  push({ vertices, indices, texture, shader, uniform }) {
    if (
      texture !== this.current_texture ||
      shader !== this.current_shader ||
      !deep_equal(uniform, this.current_uniform)
    )
      this.flush()

    const index_offset = this.vertex_queue.length / STRIDE

    this.vertex_queue = [...this.vertex_queue, ...vertices]

    for (let index of indices) {
      this.index_queue.push(index + index_offset)
    }

    this.current_texture = texture
    this.current_shader = shader
    this.current_uniform = uniform
  }

  flush() {
    if (
      !this.current_shader ||
      this.index_queue.length === 0 ||
      this.vertex_queue.length === 0
    )
      return

    this.current_shader.bind()
    this.current_shader.send(this.current_uniform)

    /* Bind the current texture */
    this.current_texture?.bind()

    /* Buffer ('send') data to the GPU */
    this.gl.bufferSubData(
      this.gl.ARRAY_BUFFER,
      0,
      new Float32Array(this.vertex_queue)
    )
    this.gl.bufferSubData(
      this.gl.ELEMENT_ARRAY_BUFFER,
      0,
      new Uint16Array(this.index_queue)
    )

    /* And draw! */
    this.gl.drawElements(
      this.gl.TRIANGLES,
      this.index_queue.length,
      this.gl.UNSIGNED_SHORT,
      0
    )

    this.vertex_queue = []
    this.index_queue = []
  }
}
