<script lang="ts">
  import { onMount } from 'svelte'
  import { renderScene, renderPixels, import_image } from '../../utilities'

  import { Icon } from '../../components'
  import { config, Pixels, initializeWebGL, add_shapes, active } from '@pogzul/engine'
  // import wasm_url from '@pogzul/engine/wasm?url'

  // console.log("HEY", wasm)

  import { artboard_state } from '../../stores'
  import { tools } from '../../tools'

  import { TextLayerInput } from '../Layers'

  type Props = {
    editable: boolean
    canvas_el: HTMLCanvasElement
    canvas_target_el: HTMLElement
    present_users: PresentUsersStore
  }

  let {
    editable = true,
    canvas_el,
    canvas_target_el,
    present_users
  } = $props<Props>()

  let ctx: ReturnType<typeof initializeWebGL>

  let is_dragging = $state(false)

  const start_dragging = () => is_dragging = true
  const stop_dragging = () => is_dragging = false

  const handle_drop = ({ dataTransfer }) => {
    let file = dataTransfer.files[0]

    if (file.type.match(/image/)) {
      let reader = new FileReader()

      reader.onload = async function (evt) {
        const pixels = await import_image(evt.target.result)

        add_shapes({ scene: active.scene, shapes: [{ pixels }]})

        render()
      }

      reader.readAsDataURL(file);
    }
  }


  const tool = $derived(tools[$artboard_state.active_tool])

  onMount(() => {
    const unsubscribe = watch_canvas_dimensions()

    initialize_canvas()

    if (ctx) render()

    present_users.subscribe(render)

    return unsubscribe
  })

  const initialize_canvas = () => {
    // WebAssembly.instantiateStreaming(fetch(wasm_url), {
    //   env: { console_log: (arg) => console.log(arg) }
    // }).then(result => {
    //   // console.log("HEY", result.instance.exports.add(2, 12))
    // })

    let gl: WebGLRenderingContext = canvas_el.getContext('webgl2', { antialias: false })

    if (gl) {
      ctx = initializeWebGL(gl)

      update_dimensions()
    } else {
      alert("WebGL 2 isn't supported.")
    }
  }

  const watch_canvas_dimensions = () => {
    const resizeObserver = new ResizeObserver(_entries => {
      if (ctx) update_dimensions()
    })

    resizeObserver.observe(document.querySelector('.artboard'))

    return () => resizeObserver.disconnect()
  }

  export const update_dimensions = () => {
    const pixel_ratio = window.devicePixelRatio || 1

    /* Set canvas size */
    const canvasRect = canvas_el.getBoundingClientRect()

    canvas_el.width  = canvasRect.width * pixel_ratio
    canvas_el.height = canvasRect.height * pixel_ratio

    /** Offset canvas by the canvas target element */
    const canvasTargetRect = canvas_target_el.getBoundingClientRect()

    const x = Math.floor(canvasTargetRect.x) + window.scrollX,
          y = Math.floor(canvasTargetRect.y) + window.scrollY

    $artboard_state.editor_offsets = { x, y }

    canvas_el.style.left = `-${ x }px`;
    canvas_el.style.top = `-${ y }px`;

    // Setting it here prevents it flashing in the wrong place on load
    document.documentElement.style.setProperty(
      '--background-position',
      `${ x % config.pixel_size }px ${ y % config.pixel_size }px`
    )

    ctx.setTransform(pixel_ratio, 0, 0, pixel_ratio, x * pixel_ratio, y * pixel_ratio)

    render()
  }

  export const render = () => requestAnimationFrame(() => {
    if (!ctx) return;

    ctx.frameStart()

    renderScene({ scene: active.scene, ctx, state: $artboard_state })

    tool.render(ctx)

    // Feels... inefficient. But it works!
    $present_users.forEach(({ pixels }) =>
      renderPixels({ ctx, pixels: new Pixels(pixels), alpha: 0.5 })
    )

    ctx.frameEnd()
  })
</script>

<div
  class="inset-0 absolute w-[100vw] h-[100vh] opacity-40 dark:opacity-25 pointer-events-none z-[-1]"
  style="background: var(--checkered-background) var(--background-position);"
></div>

<!-- <div
  class="inset-0 absolute w-[100vw] h-[100vh] opacity-40 dark:opacity-25 pointer-events-none z-[-1]"
  style="background: var(--grid-background); background-position: var(--background-position); background-size: 20px 20px;"
></div>
 -->
<div
  class="z-[5] w-full h-full relative "
  style="
    max-width: { config.canvas_size }px;
    max-height: { config.canvas_size }px;
  "
>
  <canvas
    bind:this={canvas_el}
    class="crisp-edges z-[-1] inset-0 absolute w-[100vw] h-[100vh] bg-transparent"
  ></canvas>

  <div
    id="artboard-target"
    class="relative inset-0 aspect-square border-2 border-transparent overflow-shadow { is_dragging ? 'bg-success/25 !border-success' : '' }"
    style="
      width: { config.canvas_size }px;
      height: { config.canvas_size }px;
      cursor: {$artboard_state.cursor || tool.cursor || 'default'} !important;
    "
    role="img"
    bind:this={ canvas_target_el }
    on:dragstart|preventDefault
    on:dragover|preventDefault={start_dragging}
    on:dragenter|preventDefault={start_dragging}
    on:dragleave|preventDefault={stop_dragging}
    on:dragend|preventDefault={stop_dragging}
    on:drop|preventDefault={stop_dragging}
    on:drop|preventDefault={handle_drop}
  >
    <div class="absolute inset-0 flex pointer-events-none">
      {#if is_dragging }
        <h2 class="text-success text-xl m-auto leading-none">Maximum effort.</h2>
      {/if}
    </div>
  </div>

  {#if editable && tool.name === 'Text'}
    <TextLayerInput scene={active.scene} />
  {/if}
</div>

<svelte:window on:resize={update_dimensions} on:mousemove|preventDefault />

<div>
  {#each $present_users as user}
    {#if user.active_tool}
      <div
        class="flex items-center absolute z-20"
        style="
          left: {user.mouseX + $artboard_state.editor_offsets.x}px;
          top: {user.mouseY + $artboard_state.editor_offsets.y}px;
          color: {user.color};
        "
      >
        <Icon name={user.active_tool} class="h-4 w-4" />
      </div>
    {/if}
  {/each}
</div>

<style lang="postcss">
  .overflow-shadow {
    box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.4);
  }

  :global([data-theme="light"]) {
    --checkered-background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='10' height='10' fill='%23E4E4E7'/%3E%3Crect x='10' y='10' width='10' height='10' fill='%23E4E4E7'/%3E%3Crect y='10' width='10' height='10' fill='white'/%3E%3Crect x='10' width='10' height='10' fill='white'/%3E%3C/svg%3E%0A");
  }

  :global([data-theme="dark"]) {
    --checkered-background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='10' height='10' fill='%2352525B'/%3E%3Crect x='10' y='10' width='10' height='10' fill='%2352525B'/%3E%3Crect y='10' width='10' height='10' fill='%2327272A'/%3E%3Crect x='10' width='10' height='10' fill='%2327272A'/%3E%3C/svg%3E%0A");
  }

  :root {
    --grid-background:
      linear-gradient(to right, grey 1px, transparent 1px) 9px 16px,
      linear-gradient(to bottom, grey 1px, transparent 1px) 9px 16px;
  }
</style>