<script>
  import { onMount } from 'svelte'
  import { Icon, tools } from '@pogzul/artboard'

  /** @type {HTMLDialogElement} */
  let shortcuts_modal

  let mod_key = '⌘'

  onMount(() => {
    const is_mac = navigator.platform.toLowerCase().indexOf('mac') >= 0

    if (!is_mac) mod_key = 'Ctrl'
  })
</script>

<svelte:window
  on:keydown={(evt) => {
    const active_element = document.activeElement

    const is_editable_element = ['INPUT', 'TEXTAREA'].includes(String(active_element?.tagName).toUpperCase()) || active_element instanceof HTMLElement && active_element.contentEditable === 'true'

    if (!is_editable_element && evt.key === '/' && (evt.metaKey || evt.getModifierState('OS'))) {
      evt.preventDefault()
      evt.stopPropagation()
      shortcuts_modal.showModal()
    }
  }}
/>

<button class="btn btn-square btn-sm" on:click={ () => shortcuts_modal.showModal() }>
  <Icon name="command" class="h-4 w-4" />
</button>

<dialog id="shortcuts_modal" bind:this={shortcuts_modal} class="modal">
  <div class="modal-box space-y-3">
    <h3 class="font-bold text-xl">Shortcuts</h3>


    <div class="tabs tabs-lifted w-full">
      <input type="radio" name="shortcuts-tabs" class="tab focus:outline-none" aria-label="Tools" checked />
      <div class="tab-content">
        <ul>
          {#each Object.values(tools) as tool}
            <li>
              <p>{tool.name} tool</p>

              <kbd class="kbd">{tool.shortcut}</kbd>
            </li>
          {/each}
        </ul>
      </div>

      <input type="radio" name="shortcuts-tabs" class="tab focus:outline-none" aria-label="Layers" />
      <div class="tab-content">
        <ul>
          <li>
            <p>Select visible layers</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">a</kbd>
            </div>
          </li>

          <li>
            <p>Join/flatten seleted layers</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">e</kbd>
            </div>
          </li>

          <li>
            <p>Copy selected layers</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">c</kbd>
            </div>
          </li>

          <li>
            <p>Paste copied layers</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">v</kbd>
            </div>
          </li>

          <li>
            <div>
              <p>Move selected layers 1 pixel</p>
              <p class="mt-2">
                <small>Hold <kbd class="kbd">Shift</kbd> to move 5 pixels</small>
              </p>
            </div>

            <div class="grid grid-cols-3 gap-1 grid-rows-2">
              <kbd class="kbd col-start-2"><Icon name="chevron-up" class="h-4 w-4" /></kbd>
              <kbd class="kbd row-start-2"><Icon name="chevron-up" class="h-4 w-4 -rotate-90" /></kbd>
              <kbd class="kbd row-start-2"><Icon name="chevron-up" class="h-4 w-4 rotate-180" /></kbd>
              <kbd class="kbd row-start-2"><Icon name="chevron-up" class="h-4 w-4 rotate-90" /></kbd>
            </div>
          </li>

          <li>
            <p>Delete selected layers</p>

            <kbd class="kbd">Del</kbd>
          </li>

          <li>
            <p>Bring selected layers forward</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">]</kbd>
            </div>
          </li>

          <li>
            <p>Send selected layers backward</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">[</kbd>
            </div>
          </li>

          <li>
            <p>Group layers</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">g</kbd>
            </div>
          </li>

          <li>
            <p>Ungroup layers</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">u</kbd>
            </div>
          </li>
        </ul>
      </div>

      <input type="radio" name="shortcuts-tabs" class="tab focus:outline-none" aria-label="Colors" />
      <div class="tab-content">
        <ul>
          <li>
            <p>Change layer color</p>

            <div>
              <kbd class="kbd">Shift</kbd>
              +
              <kbd class="kbd">Click</kbd>
              color
            </div>
          </li>
        </ul>
      </div>

      <input type="radio" name="shortcuts-tabs" class="tab focus:outline-none" aria-label="Editor" />
      <div class="tab-content">
        <ul>
          <li>
            <p>Run code!</p>

            <div>
              <kbd class="kbd">{mod_key}</kbd>
              <kbd class="kbd">b</kbd>
            </div>
          </li>

          <li>
            <div class="space-y-2">
              <p>De-select layers</p>
              <p>Stop running code</p>
            </div>

            <kbd class="kbd">Escape</kbd>
          </li>
        </ul>
      </div>
    </div>

    <form method="dialog" class="flex justify-end">
      <button class="btn">Close</button>
    </form>
  </div>

  <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
</dialog>

<style lang="postcss">
  ul {
    @apply space-y-4;
  }

  ul li {
    @apply flex items-center justify-between;
  }

  .tab-content {
    @apply py-3 col-end-12;
  }
</style>