/**
 * @typedef {object} Config
 * @property {number} pixel_size
 * @property {number} canvas_size
 * @property {Record<string, string>} default_colors
 */

/** @type {Config} */
const INITIAL_STATE = {
  pixel_size: 20,
  canvas_size: 500,
  default_colors: {
    white: '#FFFFFF',
    black: '#000000',
    gray: '#808080',
    brown: '#A0522D',
    sky: '#7FDBFF',
    blue: '#0074D9',
    navy: '#001F3F',
    teal: '#39CCCC',
    green: '#2ECC40',
    lime: '#01FF70',
    yellow: '#FFDC00',
    orange: '#FF851B',
    red: '#FF4136',
    fuchsia: '#F012BE',
    purple: '#B10DC9'
    // maroon: '#85144B'
  }
}

/** @type {Config} */
export let config = { ...INITIAL_STATE }
