export const SUPPORTED_EVENTS = {
  click: 'artboard',
  dblclick: 'artboard',
  mousedown: 'artboard',
  mouseup: 'artboard',
  mousemove: 'artboard',
  mouseover: 'artboard',
  mouseleave: 'artboard',
  keydown: 'window',
  keyup: 'window',
  keypress: 'window'
}
