/** @param {import('./Runtime').Runtime} runtime */
export function Console(runtime) {
  const createMessage = (values) => {
    let message = []

    for (let value of [values].flat()) {
      if (typeof value === 'function') {
        const { name } = (value.prototype || value).constructor

        if (name) {
          message.push(`${name} {}`)
        } else {
          message.push(String(value))
        }
      } else if (typeof value === 'undefined') {
        message.push(String(value))
      } else if (typeof value === 'string') {
        message.push(value)
      } else {
        message.push(JSON.stringify(value, null, 2))
      }
    }

    return message.join(' ')
  }

  const log =
    (fn) =>
    (...values) => {
      const message = createMessage(values)

      runtime.terminal?.append(fn.call(this, message))

      return true
    }

  /*
    Logging functions
  */

  this.log = log((message) => {
    try {
      if (typeof JSON.parse(message) === 'string') {
        return `<div>${message}</div>`
      } else {
        return `<pre>${message}</pre>` // highlightJson
      }
    } catch {
      return `<div>${message}</div>`
    }
  })

  this.success = log(
    (message) =>
      `<div class="bg-success/20 dark:bg-success-content/20"><span class="text-success-content dark:text-success">${message}</span></div>`
  )

  this.info = log(
    (message) =>
      `<div class="bg-info/20 dark:bg-info-content/20"><span class="text-info-content dark:text-info">${message}</span></div>`
  )
  this.debug = this.info

  this.warn = log(
    (warning) =>
      `<div class="bg-warning/20 dark:bg-warning-content/20"><span class="text-warning-content dark:text-warning">${warning}</span></div>`
  )

  this.error = log(
    (error) =>
      `<div class="bg-error/20 dark:bg-error-content/20"><span class="text-error-content dark:text-error">${error}</span></div>`
  )

  this.clear = () => runtime.terminal?.clear()

  // this.type = (value, { speed = 11 /* ms */ } = {}) => {
  //   const message = createMessage(value)

  //   let index = 0

  //   const type = () => {
  //     if (message[index]) {
  //       runtime.terminal.append(message[index++])

  //       if (runtime.running) setTimeout(() => type(), speed)
  //     }
  //   }

  //   type(0)
  // }

  return this
}
