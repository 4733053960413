<script>
  import { Navbar } from '$lib/components'
</script>

<div class="flex flex-col min-h-screen px-3">
  <Navbar />

  <slot />
</div>

<div class="text-center py-3 text-base-content/50">
  <small>&copy; Pogzul { new Date().getFullYear() }</small>
</div>



