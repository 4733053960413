<script>
  import { page, useForm } from '@inertiajs/svelte'

  /** @type {Pick<Project, 'id' | 'name'> | undefined} */
  export let project = undefined

  /** @param {Pick<Project, 'id' | 'name'>} project_to_delete */
  export const show = (project_to_delete) => {
    project = project_to_delete

    modal.showModal()
  }

  /** @type {HTMLDialogElement} */
  let modal

  let is_deleting = false

  const form = useForm()

  function delete_project(evt) {
    is_deleting = true
    evt.preventDefault()

    $form.delete(this.action, {
      onSuccess: () => {
        is_deleting = false
        modal.close()
      }
    })
  }
</script>

<dialog bind:this={modal} class="modal">
  {#if project}
    <div class="modal-box space-y-3">
      <h3 class="font-bold text-xl">Delete {project.name}</h3>

      <p>Are you sure?</p>

      <p>This will delete the project for you and anyone else.</p>

      <form action="/projects/{project.id}" method="post" on:submit={delete_project}>
        <input type="hidden" name="_method" value="delete" />
        <input type="hidden" name="authenticity_token" value={$page.props.csrf_token} />

        <button
          class="btn btn-error btn-outline w-full"
          disabled={is_deleting}
        >
          {#if is_deleting}<span class="loading loading-dots"></span>{/if}

          Yes, delete this project
        </button>
      </form>

      <form method="dialog" class="flex justify-end">
        <button class="btn">Close</button>
      </form>
    </div>

    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  {/if}
</dialog>