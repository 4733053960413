<script>
  import { ProjectsList, meta_tags } from '$lib/components'

  export let projects = []

  meta_tags({ title: 'Projects' })
</script>

<div
  class="inset-0 fixed w-[100vw] h-[100vh] opacity-20 dark:opacity-[.15] pointer-events-none z-[-1]"
  style="background: var(--checkered-background)"
></div>

<div class="flex justify-center items-center flex-1">
  <div class="max-w-lg w-full space-y-3 bg-base-200/50 p-6">
    <ProjectsList {projects} />
  </div>
</div>

