import Tool from './Tool.js'

import {
  Shape,
  config,
  colors_are_equal,
  draw,
  pixel_grid
} from '@pogzul/engine'
import { state } from '../stores'

const RESOLUTION = config.canvas_size / config.pixel_size

const FILL_DELAY_MS = 7

class FillTool extends Tool {
  /** @type {'Fill'} */
  name = 'Fill'

  shortcut = 'f'

  /** @param {import('./ToolEvent').default} evt */
  async onMouseUp(evt) {
    await this.#draw(evt)

    super.onMouseUp(evt)
  }

  /** @param {import('./ToolEvent').default} evt */
  async #draw({ active_color, scene, x, y, active_layer_ids }) {
    const grid = pixel_grid(scene)

    const target_color = grid[x]?.[y]

    const fill = async (x, y) => {
      if (this.pixels.get(x, y) !== undefined) return

      this.pixels.set(x, y, active_color)

      let promises = []

      if (
        y < RESOLUTION - 1 &&
        colors_are_equal(grid[x]?.[y + 1], target_color)
      ) {
        await new Promise((resolve) => setTimeout(resolve, FILL_DELAY_MS))
        promises.push(fill(x, y + 1))
      }

      if (y > 0 && colors_are_equal(grid[x]?.[y - 1], target_color)) {
        await new Promise((resolve) => setTimeout(resolve, FILL_DELAY_MS))
        promises.push(fill(x, y - 1))
      }

      if (
        x < RESOLUTION - 1 &&
        colors_are_equal(grid[x + 1]?.[y], target_color)
      ) {
        await new Promise((resolve) => setTimeout(resolve, FILL_DELAY_MS))
        promises.push(fill(x + 1, y))
      }

      if (x > 0 && colors_are_equal(grid[x - 1]?.[y], target_color)) {
        await new Promise((resolve) => setTimeout(resolve, FILL_DELAY_MS))
        promises.push(fill(x - 1, y))
      }

      await Promise.all(promises)
    }

    await fill(x, y)

    const result = draw({
      scene,
      pixels: this.pixels,
      layer_ids: active_layer_ids
    })

    if (result) {
      state.update((old_state) => ({
        ...old_state,
        active_layer_ids: typeof result === 'number' ? [] : [result.id]
      }))
    }
  }
}

export default FillTool
