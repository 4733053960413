import localforage from 'localforage'

const browser = typeof window !== 'undefined'

export const storage = localforage.createInstance({
  name: '@pogzul'
})

/**
 * @template T
 * @param {string} key
 * @param {{ value: T }} store
 * @param {{ sync?: boolean, default: any|function(T):void }} [opts]
 */
export const persist_store = (
  key,
  store,
  opts = { default: undefined, sync: false }
) => {
  if (browser) {
    /** @type {BroadcastChannel} */
    let channel

    /** @type {boolean} */
    let is_remote_change = false

    storage.getItem(key).then((val) => {
      store.value =
        typeof opts.default === 'function'
          ? opts.default(val)
          : val || opts.default
    })

    if (opts.sync) {
      channel = new BroadcastChannel(key)

      channel.onmessage = async (evt) => {
        is_remote_change = true
        store.value = evt.data
        await storage.setItem(key, evt.data)
        is_remote_change = false
      }
    }

    let initialized = false
    $effect(() => {
      const new_val = JSON.parse(JSON.stringify(store.value || ''))

      if (!initialized) {
        initialized = true
        return
      }

      if (opts.sync && !is_remote_change) channel.postMessage(new_val)

      storage.setItem(key, new_val)
    })
  }
}
