export const random_uint_32 = () => Math.floor(Math.random() * 0xffffffff + 1)

export const pick = <T, K extends keyof T>(
  obj: T,
  ...properties: K[]
): Pick<T, K> =>
  properties.reduce(
    (result, prop) => ({
      ...result,
      ...(obj[prop] === undefined ? {} : { [prop]: obj[prop] })
    }),
    {} as Pick<T, K>
  )
