/**
 * @param {import('./Console.js').Console} console - The console to use for
 *   logging
 * @param {String} script - The script to evaluate
 * @param {Object} scope - An object of params to include in the evaluation
 *   scope
 * @param {Object} context - The context (i.e., 'this') to evaluate with
 */

export async function evaluate(console) {
  try {
    const AsyncFunction = Object.getPrototypeOf(
      async function () {}
    ).constructor

    const fn = new AsyncFunction(
      'console',
      ...Object.keys(arguments[2]),
      `'use strict'; ${arguments[1]};`
    )

    return await fn.call(arguments[3], console, ...Object.values(arguments[2]))
  } catch (exception) {
    if (exception.name === 'StopExecutionError') return

    return console.error(
      `${exception.name} (line ${exception.line - 1}): ${exception.message}`
    )
  }
}
