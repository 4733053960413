import ASCII_CHARS from './ascii_charset.txt'
import { random_uint_32 } from '@pogzul/engine'

export class Font {
  /** @type {number} */
  id = random_uint_32()

  /** @type {number} */
  glyph_height

  /** @type {import('../webgl/Texture').Texture['src']} */
  atlas

  /** @type {Record<typeof ASCII_CHARS, (typeof this)['config']['chars'][0]>} */
  characters

  /** @type {import('./types').BMFontConfig} */
  config

  /** @param {Pick<Font, 'config' | 'atlas'>} params */
  constructor({ config, atlas }) {
    if (typeof window === 'undefined') return

    this.config = config
    this.atlas = atlas

    this.characters = config.chars.reduce(
      (obj, char_config) => ({
        ...obj,
        [char_config.char]: char_config
      }),
      {}
    )

    this.glyph_height = config.chars.reduce((max, char) => {
      const glyph_height = char.height + char.yoffset

      if (glyph_height > max) return glyph_height

      return max
    }, 0)
  }

  get name() {
    return this.config.info.face
  }

  get size() {
    return this.config.info.size
  }
}

export default Font
