import { parse_color } from '@pogzul/engine'
import { Color } from '../../../functions'

export const PIXEL_DELETE_KEY = null

/**
 * A 2D object of { x: { y: color }} values
 *
 * @typedef {| Partial<Record<number, Record<number, Color | string>>>
 *   | Map<number, Map<number, Color | string>>
 *   | Pixels} PixelsObject
 */

/**
 * A 2D object of { x: { y: color }} values
 *
 * @typedef {Map<number, Map<number, Uint8Array>>} PixelMap
 */

export class Pixels {
  /** @type {PixelMap} */
  #map

  /** @param {PixelsObject} pixels */
  constructor(pixels = {}) {
    this.#map = parsePixels(pixels)
  }

  /**
   * @param {number} x
   * @param {number} y
   */
  get(x, y) {
    return this.#map.get(x)?.get?.(y)
  }

  /**
   * @param {number} x
   * @param {number} y
   * @param {Uint8Array | null} color – an [r, g, b] triple
   * @param {boolean} [ignore_deletion]
   */
  set(x, y, color, ignore_deletion = false) {
    if (!ignore_deletion && color === PIXEL_DELETE_KEY) {
      return this.delete(x, y)
    }

    let col = this.#map.get(x)

    if (col) {
      col.set(y, color)
    } else {
      this.#map.set(x, new Map([[y, color]]))
    }
  }

  /**
   * @param {number} x
   * @param {number} y
   */
  delete(x, y) {
    let col = this.#map.get(x)

    if (col) {
      col.delete(y)

      if (col.size === 0) this.#map.delete(x)
    }
  }

  get size() {
    return this.#map.size
  }

  [Symbol.iterator]() {
    return this.#map[Symbol.iterator]()
  }

  toJSON() {
    /**
     * @typedef {string} color - A stringified RGB triple: '[12,0,255]'
     *
     * @typedef {number} index
     * @type {Object<color, index>}
     */
    const colors = {}

    let index = 0

    const pixels = Array.from(this.#map.entries()).map(([x, row]) => {
      let yColorPair = Array.from(row.entries()).map(([y, color]) => {
        const jsonColor = JSON.stringify(color ? Array.from(color) : color),
          _index = colors[jsonColor] || (colors[jsonColor] = ++index)

        return [y, _index]
      })

      return [x, yColorPair]
    })

    return { colors: invert_object(colors), pixels }
  }

  /** @returns {undefined} Array */
  // entries: {
  //   value() {
  //     return #map.entries()
  //   }
  // },

  /**
   * @returns {{ left: number; right: number; top: number; bottom: number }} -
   *   The boundary of the map
   */
  computeBounds() {
    let minX, minY, maxX, maxY

    if (this.#map.size === 0) return { left: 0, right: 0, top: 0, bottom: 0 }

    for (let [x, col] of this.#map) {
      if (isNaN(minX) || x < minX) minX = x
      if (isNaN(maxX) || x > maxX) maxX = x

      for (let [y] of col) {
        if (isNaN(minY) || y < minY) minY = y
        if (isNaN(maxY) || y > maxY) maxY = y
      }
    }

    return {
      left: minX || 0,
      right: (maxX || 0) + 1,
      top: minY || 0,
      bottom: (maxY || 0) + 1
    }
  }
}

/** @typedef {Record<number, string>} PixelColors */
/** @typedef {[string, PixelColors][]} RawPixelsRow */
/** @typedef {[string, number | string][]} RawPixelsColumn */

/**
 * @param {PixelsObject} obj
 * @returns {PixelMap}
 */
const parsePixels = (obj) => {
  let pixels = obj

  /** @type {PixelColors | undefined} */
  let colors = undefined

  if (obj.colors && obj.pixels) {
    colors = obj.colors
    pixels = obj.pixels
  }

  return new Map(
    parse_pixels_column(pixels).map(([x, row]) => [
      Number(x),
      new Map(parse_pixels_row(row, colors))
    ])
  )
}

/**
 * @param {Map<number, string> | Pixels | number[] | object} row
 * @param {PixelColors} [colors]
 */
const parse_pixels_row = (row, colors = undefined) =>
  parse_pixels_column(row).map(([y, color]) => {
    return [
      Number(y),
      new Uint8Array(colors ? JSON.parse(colors[color]) : parse_color(color))
    ]
  })

/**
 * @param {Map<number, string> | Pixels | number[] | object} item
 * @returns {RawPixelsColumn}
 */
const parse_pixels_column = (item) => {
  if (Array.isArray(item) || item instanceof Map || item instanceof Pixels) {
    return Array.from(item)
  }

  return Object.entries(item)
}

/**
 * @template T
 * @param {{ [key: string]: T }} o
 */
const invert_object = (o) =>
  Object.entries(o).reduce((obj, [k, v]) => ((obj[v] = k), obj), {})

export default Pixels
