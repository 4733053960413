<script>
  import { meta_tags } from '$lib/components'

  import { Link } from '@inertiajs/svelte'
  export let posts

  meta_tags({ title: 'Blog' })
</script>

<main class="mx-auto max-w-2xl w-full prose py-12">
  <h1>The Plog</h1>

  {#each posts as post}
    <article>
      <Link href={post.path} class="relative block group">
        <img src={post.image} alt={post.title} class="group-hover:opacity-75" />

        <div class="absolute bottom-0 inset-x-0 bg-gradient-to-b from-black/0 via-black-80 to-black/90 pt-6 pl-6">
          <h2 class="text-white text-3xl">{post.title}</h2>
        </div>
      </Link>
    </article>
  {/each}
</main>