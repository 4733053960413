export class Texture {
  /** @type {WebGLRenderingContext} */
  gl

  /** @type {WebGLTexture | null} */
  gl_texture

  /** @type {Exclude<TexImageSource, VideoFrame>} */
  src

  /** @type {number} */
  width

  /** @type {number} */
  height

  /**
   * @param {object} params
   * @param {WebGLRenderingContext} params.gl
   * @param {Texture['src']} [params.src]
   * @param {'linear' | 'nearest'} [params.filter]
   */
  constructor({ gl, src, filter = 'nearest' }) {
    this.gl = gl

    this.src = src ?? white_pixel()

    this.width = this.src.width
    this.height = this.src.height

    this.gl_texture = gl.createTexture()

    this.bind()

    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      this.src
    )

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)

    const texture_filter = {
      linear: gl.LINEAR,
      nearest: gl.NEAREST
    }[filter]

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, texture_filter)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, texture_filter)
  }

  /** @param {Texture['src']} src */
  update(src) {
    this.bind()

    this.width = src.width
    this.height = src.height

    this.gl.texImage2D(
      this.gl.TEXTURE_2D,
      0,
      this.gl.RGBA,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      src
    )
  }

  bind() {
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.gl_texture)
  }
}

const white_pixel = () =>
  new ImageData(new Uint8ClampedArray([255, 255, 255, 255]), 1, 1)

export default Texture
