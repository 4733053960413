<script>
  import SignUpForm from './SignUpForm.svelte'
  import SignInForm from './SignInForm.svelte'

  /** @type {HTMLDialogElement} */
  let modal

  let is_signing_up = true
</script>

<button class="btn btn-sm" on:click={() => modal.showModal()}>
  Save
</button>

<dialog id="share_modal" bind:this={modal} class="modal">
  <div class="modal-box space-y-3">
    <h3 class="font-bold text-xl">Save your projects</h3>

    <input type="checkbox" class="hidden" bind:checked={is_signing_up} id="is_signing_up" />

    {#if is_signing_up}
      <SignUpForm on:close={() => modal.close()} />
    {:else}
      <SignInForm on:close={() => modal.close()} />
    {/if}

    <form method="dialog" class="flex justify-end">
      <button class="btn">Close</button>
    </form>
  </div>

  <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
</dialog>