import { get } from 'svelte/store'
import { artboard_state } from '@pogzul/artboard'
import { pick } from '@pogzul/engine'
import { page } from '@inertiajs/svelte'
import Cookie from 'js-cookie'

const browser = typeof window !== 'undefined'

const cookies = Cookie.withConverter({
  write: function (value, name) {
    return value
  }
})

export const default_preferences = {
  theme: 'light',
  ide_visible: true,

  /**
   * @type {Partial<
   *   Pick<
   *     import('@pogzul/artboard').ArtboardState,
   *     'active_tool' | 'active_scene_ids'
   *   > & { active_color: number[] }
   * >}
   */
  artboard_state: {}
}

const PERMANENT_COOKIE_EXPIRES = 10 * 365

class Preferences {
  value = $state(default_preferences)
}

// * @type {import('svelte/store').Writable<typeof default_preferences>}
export const preferences = new Preferences() // writable(default_preferences)

/** @param {Partial<typeof default_preferences>} json */
export const set_preferences = (json) => {
  const data = { ...default_preferences, ...json }

  preferences.value = { ...default_preferences, ...data }

  /** @type {Partial<import('@pogzul/artboard').ArtboardState>} */
  let state = pick(data.artboard_state, 'active_tool', 'active_scene_ids')

  if (data.artboard_state.active_color) {
    state.active_color = new Uint8Array(data.artboard_state.active_color)
  }
  artboard_state.update((old_state) => ({ ...old_state, ...state }))

  if (browser) {
    let preferences_initialized = false
    $effect(() => {
      const new_val = preferences.value

      if (!preferences_initialized) {
        preferences_initialized = true
        return
      }

      const { preferences_cookie_key } = get(page).props.config

      const { artboard_state, ...prefs } = new_val

      cookies.set(preferences_cookie_key, JSON.stringify(prefs), {
        expires: PERMANENT_COOKIE_EXPIRES
      })
    })
  }
}

/** @type {string} */
let old_state

if (browser) {
  let artboard_state_initialized = false
  artboard_state.subscribe((new_state) => {
    if (!artboard_state_initialized) return (artboard_state_initialized = true)

    const get_state = (state) => ({
      ...pick(state, 'active_tool', 'active_scene_ids'),
      active_color: Array.from(state.active_color)
    })

    const state = get_state(new_state)

    if (JSON.stringify(state) !== JSON.stringify(old_state)) {
      const { artboard_state_cookie_key } = get(page).props.config

      cookies.set(artboard_state_cookie_key, JSON.stringify(state), {
        expires: PERMANENT_COOKIE_EXPIRES,
        path: get(page).url
      })

      old_state = JSON.stringify(state)
    }
  })
}
