<script>
  import { onMount } from 'svelte'
  import Honeybadger from '@honeybadger-io/js'
  import posthog from 'posthog-js'

  import { set_preferences } from '$lib/stores'
  import { FlashMessages, MetaTags } from '$lib/components'

  export let preferences
  set_preferences(preferences)

  export let config

  export let current_user

  onMount(() => {
    Honeybadger.configure({
      apiKey: config.honeybadger.api_key,
      environment: config.env
    })

    posthog.init(config.posthog.token, {
      api_host: config.posthog.api_host,
      persistence: 'memory',
      bootstrap: current_user ? { distinctID: current_user.id } : {}
    })

    if (current_user)
      posthog.identify(current_user.id, { email: current_user.email })
  })
</script>

<MetaTags />

<FlashMessages />

<slot />


