import MoveTool from './MoveTool.js'
import BrushTool from './BrushTool.js'
import RectangleTool from './RectangleTool.js'
import LineTool from './LineTool.js'
import FillTool from './FillTool.js'
// import Eyedropper from './EyedropperTool.js'
import TextTool from './TextTool.js'
export * from './ToolEvent.js'

export const tools = {
  move: new MoveTool(),
  // eyedropper: new Eyedropper(),
  brush: new BrushTool(),
  ...(import.meta.env.MODE === 'development' ? { text: new TextTool() } : {}),
  rectangle: new RectangleTool(),
  line: new LineTool(),
  fill: new FillTool()
}

export default tools
