/** @param {import('@pogzul/engine').Scene} scene */
export const create_layers_proxy = (scene) => {
  return new Proxy(scene.entities, {
    get(obj, key, receiver) {
      switch (key) {
        case 'length':
          return Object.keys(obj).length

        // Override for...of
        case Symbol.iterator:
          return () => Object.values(obj)[Symbol.iterator]()

        case 'each':
        case 'forEach':
          /** @param {...any} args */
          return (...args) => Object.values(obj).forEach(...args)

        // case 'toJSON':
        //   return () => {
        //     function Layers() {}
        //     Object.defineProperty(obj, 'name', {
        //       value: function () {
        //         return `[myArrayProxy: ]`
        //       },
        //       enumerable: false
        //     })

        //     return new Layers()
        //   }
      }

      const layers_array = Object.values(obj)

      /** Access layer by array index */
      if (/^-?\d+$/.test(String(key))) {
        const layer = layers_array[Number(key)]
        if (layer) return layer
      }

      /**
       * Access layers by name
       *
       * Could be a conflict with an integer name...
       */
      const layer = layers_array.find((layer) => layer.name === key)
      if (layer) return layer

      return Reflect.get(obj, key, receiver)
    },

    set(obj, key, value, receiver) {
      return Reflect.set(obj, key, value, receiver)
    },

    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true,
        writable: true
      }
    },

    ownKeys(obj) {
      return Object.values(obj)
        .filter(Boolean)
        .map((layer) => layer.name)
        .filter(Boolean)
    }
  })
}
