export class Callback {
  #last_execution = 0

  /**
   * @param {function(...any):any} func
   * @param {number} interval_in_secs
   * @param {{ condition?: function(...any):boolean }} options
   */
  constructor(
    func,
    interval_in_secs = 0 /* 0 = run every frame */,
    options = {}
  ) {
    this.function = func
    this.interval_in_secs = interval_in_secs
    this.condition = options.condition || null
  }
  /** @param {any} args */
  call(...args) {
    if (this.condition && !this.condition.call(null, ...args)) return

    if (this.interval_in_secs) {
      let elapsed = Date.now() - this.#last_execution

      if (elapsed > this.interval_in_secs * 1000) {
        this.#last_execution = Date.now()

        return this.function.call(null, ...args)
      }
    } else {
      return this.function.call(null, ...args)
    }
  }
}
