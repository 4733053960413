import { config, add_shapes } from '@pogzul/engine'
import { Color, Velocity, Point } from '../types'

/**
 * @param {...(Color | Point | Velocity | string)} traits
 * @this {import('@pogzul/engine').Runtime}
 */
export function draw(...traits) {
  const pos = traits.find(is_point) || new Point(0, 0),
    color = traits.find(is_color) || new Color('black')

  const [layer] = add_shapes({
    scene: this.active_scene,
    shapes: [
      {
        pixels: {
          [pos.x / config.pixel_size]: { [pos.y / config.pixel_size]: color }
        }
      }
    ]
  })

  const vel = traits.find(is_velocity)
  if (vel) layer.velocity = vel

  const tags = traits.filter((item) => typeof item === 'string')
  if (tags.length) layer.tag(...tags)

  return layer
}

/**
 * @param {any} item
 * @returns {item is Point}
 */
const is_point = (item) => item instanceof Point

/**
 * @param {any} item
 * @returns {item is Velocity}
 */
const is_velocity = (item) => item instanceof Velocity

/**
 * @param {any} item
 * @returns {item is Color}
 */
const is_color = (item) => item instanceof Color
