<script lang="ts">
  import { artboard_state } from '../../stores'
  import { tools } from '../../tools'

  import { fonts, Text } from '@pogzul/engine'

  let { scene } = $props<{ scene: import('@pogzul/engine').Scene }>()

  const tool = $derived(tools[$artboard_state.active_tool])

  const get_text_layer = (hovering_layer_ids, active_layer_ids) => {
    const hovering_layer = scene.entities[hovering_layer_ids[0]]
    if (hovering_layer instanceof Text) return hovering_layer

    const active_layer = scene.entities[active_layer_ids[0]]
    if (active_layer_ids.length === 1 && active_layer instanceof Text) return active_layer
  }

  const layer = $derived(get_text_layer($artboard_state.hovering_layer_ids, $artboard_state.active_layer_ids))
</script>

{#if tool.name === 'Text' && layer}
  {@const font = fonts[layer.font]}

  {#key layer.id}
    <div
      id="text-edit-{layer.id}"
      role="textbox"
      tabindex="-1"
      contenteditable="true"
      class="font-pixel whitespace-pre-wrap absolute bg-transparent resize-none focus:outline-none caret-base-content/40 overflow-hidden !break-all text-transparent"
      style="
        left: {layer.x}px;
        top: {layer.y}px;
        width: {layer.width}px;
        font-size: {layer.size}px;
        line-height: {layer.size * font.glyph_height / font.size}px;
      "
      bind:innerText={layer.value}
      on:focus={() => {
        $artboard_state.active_layer_ids = [layer.id]
      }}
    ></div>
  {/key}
{/if}

<style lang="postcss">
  ::selection {
    @apply text-transparent bg-info/30;
  }
</style>
