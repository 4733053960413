import * as engine from '../functions'
import { create_layers_proxy } from '../functions/internal'
import { StopExecutionError } from './errors'

/**
 * @param {Object} opts
 * @param {import('./Runtime').Runtime} opts.runtime
 * @this {Record<string, any>}
 */
export function Scope({ runtime }) {
  /* Remove global keys */
  for (let key in window) {
    if (key.startsWith('__')) continue // dev keys...

    this[key] = undefined
  }

  /* Assign the game engine */
  for (let key in engine) {
    this[key] = engine[key].bind(runtime)
  }

  this.scenes = runtime.scenes
  this.scene = runtime.active_scene
  this.layers = create_layers_proxy(this.scene)

  this.exit = () => {
    runtime.stop()
    throw new StopExecutionError()
  }

  Object.assign(this, runtime.console)

  this.dt = runtime.dt

  this.window = runtime.window
  this.global = this.window
  this.globalThis = undefined

  this.fetch = window.fetch
}
