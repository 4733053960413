<script lang="ts">
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'
  import Canvas from './Canvas'
  import EventListeners from './EventListeners'
  import Tools from './Tools'
  import Scenes from './Scenes'
  import { Layers, LayerOptions } from './Layers'
  import { artboard_state, clipboard } from '../stores'

  import { config, active } from '@pogzul/engine'

  type Props = {
    project: import('@pogzul/app').ProjectStore
    editable: boolean
    persist_store: any
    present_users: PresentUsersStore,
    render: () => number
  }

  let {
    project,
    editable = true,
    persist_store = null,
    present_users = writable([]),
    render
  } = $props<Props>()

  onMount(() => project.subscribe(render))

  if (persist_store) persist_store('clipboard', clipboard, { sync: true })

  let canvas_el: HTMLCanvasElement

  // The element used for interactions
  let canvas_target_el: HTMLElement

  if (!editable) $artboard_state.active_tool = 'move'

  const active_scene_id = $derived($project.scenes[$artboard_state.active_scene_ids[0]] ? $artboard_state.active_scene_ids[0] : $project.scene_ids[0])

  $effect(() => active.scene = $project.scenes[active_scene_id])
</script>

<div
  class="artboard grid gap-3 h-full p-3"
  style="
    grid-template-columns: 1fr {config.canvas_size}px 1fr;
    grid-template-rows: 1fr {config.canvas_size}px 1fr;
  "
>
  <div class="row-start-2 col-start-1 flex items-start space-x-3 h-full z-10" style="max-height: { config.canvas_size }px;">
    <Layers bind:scene={$project.scenes[active_scene_id]} {editable} />

    {#if editable}
      <Tools />
    {/if}
  </div>

  <div class="col-start-2 z-10 flex justify-between items-end">
    <slot name="actions" />
  </div>

  <div class="row-start-2 col-start-2">
    <Canvas
      bind:canvas_el
      bind:canvas_target_el
      bind:render
      {present_users}
      {editable}
    />
  </div>

  <div class="col-start-2 row-start-3 flex items-end justify-start z-10">
    <Scenes bind:project {editable} />
  </div>

  {#if editable}
    <div class="row-start-2 col-start-3 flex justify-end z-10" style="max-height: { config.canvas_size }px;">
      <LayerOptions
        bind:scene={$project.scenes[active_scene_id]}
        {render}
      />
    </div>
  {/if}
</div>

{#if editable}
  <EventListeners
    bind:scene={$project.scenes[active_scene_id]}
    {render}
    {canvas_el}
    {canvas_target_el}
  />
{/if}

<style lang="postcss">
  .artboard > * {
    pointer-events: none;
  }

  .artboard > * > :global(*) {
    pointer-events: auto;
  }
</style>
