import { notify } from '$lib/components'

/** @param {string} text */
export const copy_to_clipboard = async (text) => {
  notify({ type: 'success', message: 'Copied to clipboard!' })

  if (!('clipboard' in navigator)) {
    return copy_to_clipboard_fallback(text)
  }

  return await navigator.clipboard.writeText(text)
}

/** @param {string} text */
const copy_to_clipboard_fallback = (text) => {
  const input = document.createElement('textarea')
  input.innerHTML = text
  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
}
