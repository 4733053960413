import { createInertiaApp } from '@inertiajs/svelte'
import { mount } from 'svelte'

import BaseLayout from '$views/layouts/BaseLayout.svelte'
import Layout from '$views/layouts/Layout.svelte'

const pages = import.meta.glob('$views/**/*.svelte', { eager: true })

if (import.meta.env.DEV) {
  import('@spotlightjs/spotlight').then((Spotlight) =>
    Spotlight.init({ injectImmediately: true })
  )
}

createInertiaApp({
  resolve: (name) => {
    const page = pages[`../views/${name}.svelte`]

    return {
      default: page.default,
      layout: page.layout || [BaseLayout, Layout]
    }
  },
  // page: undefined,
  setup({ el, App }) {
    delete el.dataset.page

    mount(App, { target: el })
  }
})
